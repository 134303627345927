import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as CheckGreen } from "../../assets/Survey/checkGreen.svg";
import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import { MainContext } from "../../contexts/MainContext";
import { OptionContext } from "../../contexts/OptionContext";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import useTransport from "../../hooks/useTransport";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();
const defaultDestAct = { name: "", value: "", poiId: 0 };

const Survey_2_4_3 = () => {
  const nameRef = useRef<HTMLDivElement>(null);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [, dispatchMainContext] = useContext(MainContext);
  const [{ destActOptions }] = useContext(OptionContext);
  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [destAct, setDestAct] = useState(defaultDestAct);
  const transport = useTransport(surveyId);

  useEffect(() => {
    const index = destActOptions.findIndex(
      (act) => act.poiId === surveyContext.destAct
    );
    if (index > -1) {
      setDestAct(destActOptions[index]);
    }
  }, [surveyContext, destActOptions]);
  const isDispatchable = useCallback(
    () => destAct.poiId > 0 && transport.isDispatchable(),
    [destAct, transport.isDispatchable]
  );
  const dispatch = useCallback(() => {
    dispatchSurveyContext({
      type: "UPDATE_DESTINATION",
      value: {
        destName: "",
        destDetermined: false,
        destType: "activity",
        destCity: "",
        destFood: 0,
        destAct: destAct.poiId,
      },
    });
    saveSurvey(surveyId, {
      ...surveyContext,
      destName: "",
      destDetermined: false,
      destType: "activity",
      destCity: "",
      destFood: 0,
      destAct: destAct.poiId,
    });
    transport.dispatch();
  }, [destAct, transport.dispatch, surveyId, surveyContext]);
  useEffect(() => {
    amplitude.getInstance().logEvent("2-4-3 act opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("2-4-3 act activated", properties);
    }
  }, [isDispatchable]);
  const getDestActOptions = useCallback(() => {
    if (transport.isDriving) {
      return destActOptions
        .filter((option) => (surveyContext.covidFree ? option.covidFree : true))
        .filter(
          (option) => transport.transTimes.indexOf(option.driveDistLimit) > -1
        );
    } else if (transport.isDriving === false) {
      return destActOptions
        .filter((option) => (surveyContext.covidFree ? option.covidFree : true))
        .filter(
          (option) => transport.transTimes.indexOf(option.transitDistLimit) > -1
        );
    } else {
      return [];
    }
  }, [
    destActOptions,
    surveyContext.covidFree,
    transport.isDriving,
    transport.transTimes,
  ]);

  useEffect(() => {
    if (
      destActOptions.length > 0 &&
      transport.isDriving !== undefined &&
      destAct.poiId > 0
    ) {
      const options = getDestActOptions();
      const index = options.findIndex((act) => act.poiId === destAct.poiId);
      if (index > -1) {
        setDestAct(getDestActOptions()[index]);
      } else {
        setDestAct(defaultDestAct);
      }
    }
  }, [destAct, transport.isDriving, destActOptions, getDestActOptions]);

  useEffect(() => {
    if (
      getDestActOptions().length === 0 &&
      destActOptions.length > 0 &&
      transport.isDriving !== undefined &&
      transport.transTimes.length > 0
    ) {
      setTimeout(() => {
        dispatchMainContext({
          type: "UPDATE_DIALOG",
          value: {
            type: "DialogError",
            content: `조건에 맞는 추천을 해드리기 어려워요.\n교통수단과 이동시간을 변경해주세요.`,
          },
        });
      }, 2000);
    }
  }, [getDestActOptions, transport, destActOptions]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-2-5`);
  };

  return (
    <div className="w-full survey-min-height">
      <div className="w-full px-6 sm:px-9">
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-2-3`} />
        <div>
          <SurveyStep step={4} />
          <Title title="할 것으로 고르기" />
        </div>
        <div className="mt-1 sm:mt-1.5 body-regular-14">
          선택한 교통수단/이동시간에 맞는 할 것을 볼 수 있어요
        </div>
        {transport.renderComponent}
      </div>
      <div className="w-full px-4 sm:px-6 mt-5 sm:mt-12 grid grid-cols-3 gap-2">
        {getDestActOptions().map((act) => (
          <button
            key={act.poiId}
            className={`dest-food-parent w-full rounded sm:rounded-md bg-cover bg-center bg-no-repeat overflow-hidden ${
              destAct.poiId === act.poiId ? "shadow-lg" : "opacity-70"
            }`}
            onClick={() => setDestAct(act)}
            style={{ backgroundImage: `url(${act.imageUrl})` }}
          >
            <div
              className={`absolute h-full w-full top-0 left-0 rounded sm:rounded-md ${
                destAct.poiId === act.poiId
                  ? "border-2 border-yoda-primary"
                  : "border border-yoda-gray-2 opacity-95"
              } z-40`}
            ></div>
            <div
              className="absolute bottom-0 left-0 w-full h-12 sm:h-18 px-1 sm:px-1.5 text-white card-regular-14 text-left dest-food-name flex items-end pb-1.5 sm:pb-2.5 z-30"
              style={{
                backgroundImage:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 54.09%)",
              }}
            >
              {act.name}
            </div>
            {destAct.poiId === act.poiId && (
              <CheckGreen
                className="z-30 absolute top-1 right-1 sm:top-1.5 sm:right-1.5 w-6 h-6 sm:w-9 sm:h-9"
                style={{
                  filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2))",
                }}
              />
            )}
            {destAct.poiId === act.poiId && (
              <div
                className="absolute h-full w-full top-0 left-0 rounded sm:rounded-md z-20"
                style={{ backgroundColor: "rgba(16, 16, 16, 0.6)" }}
              ></div>
            )}
          </button>
        ))}
      </div>
      <div className="w-full px-6 sm:px-9 mt-6 sm:mt-9">
        <div className="text-yoda-green-dark body-medium-15 w-full text-right flex justify-end">
          <div>선택한 지역</div>
          <div className="w-7 ml-1.5 sm:ml-2.5">&nbsp;</div>
        </div>
        <div className="h-9 sm:h-10">
          {destAct.poiId > 0 ? (
            <div className="mt-1 sm:mt-1.5 w-full flex justify-end items-center">
              <div ref={nameRef} className="text-yoda-black-1 heading-bold-24">
                {destAct.name}
              </div>
              <button
                className="w-6 sm:w-9 ml-1.5 sm:ml-2.5"
                onClick={() => setDestAct(defaultDestAct)}
              >
                <ClearIcon className="w-full h-full" />
              </button>
            </div>
          ) : (
            <div className="mt-1 sm:mt-1.5 w-full flex justify-end items-center">
              <div className="text-yoda-gray-3 btn-bold-16">선택해주세요</div>
              <div className="h-7 w-7 ml-1.5 sm:ml-2.5">&nbsp;</div>
            </div>
          )}
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-2-3`}
      />
    </div>
  );
};

export default Survey_2_4_3;
