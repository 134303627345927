import { SurveysType, getSurveys } from "../../api/Survey";
import getStringDates, { checkUpdatedDate } from "../../util/formatFromMonth";
import { useCallback, useContext, useEffect, useState } from "react";

import { LocalSurveyType } from "../Trip/types";
import { MainContext } from "../../contexts/MainContext";
import Trips from "./Trips";
import { getToday } from "../../util";
import saveLocalSurveys from "../../util/saveLocalSurveys";

const today = getToday();

const MyTrip = () => {
  const [{ user }] = useContext(MainContext);
  const [showUpcoming, setShowUpcoming] = useState(true);
  const [loadingApi, setLoadingApi] = useState(true);
  const [previousTrips, setPreviousTrips] = useState<SurveysType[]>([]);
  const [upcomingTrips, setUpcomingTrips] = useState<SurveysType[]>([]);
  const [recentOrder, setRecentOrder] = useState<boolean>(true);
  const [surveys, setSurveys] = useState<SurveysType[] | undefined>(undefined);
  const [deleteMode, setDeleteMode] = useState(false);

  const callGetSurveys = async () => {
    try {
      const { data: allSurveys } = await getSurveys();
      setSurveys(allSurveys);
    } catch (e) {
    } finally {
      setLoadingApi(false);
    }
  };
  useEffect(() => {
    if (user) callGetSurveys();
  }, [user]);

  useEffect(() => {
    const getSurveyDate = (
      updatedAt: string,
      fromMonth: number | null,
      to: string | null
    ) => {
      return to && to.length > 0 ? to : checkUpdatedDate(updatedAt, fromMonth!);
    };
    if (surveys) {
      const previous = surveys.filter((survey) => {
        const surveyDate = getSurveyDate(
          survey.updatedAt,
          survey.fromMonth,
          survey.to
        );
        return survey.status === 4 && surveyDate < today.dayString;
      });
      setPreviousTrips(previous);

      const upcoming = surveys.filter((survey) => {
        const surveyDate = getSurveyDate(
          survey.updatedAt,
          survey.fromMonth,
          survey.to
        );
        return survey.status < 4 || surveyDate >= today.dayString;
      });
      setUpcomingTrips(upcoming);
    }
  }, [surveys]);

  const getSortedTrips = useCallback(() => {
    let survey = showUpcoming ? upcomingTrips : previousTrips;
    survey.sort((sv1, sv2) => {
      if (recentOrder) {
        if (sv1.updatedAt < sv2.updatedAt) return 1;
        if (sv1.updatedAt > sv2.updatedAt) return -1;
      } else {
        const sv1Date = getStringDates(sv1);
        const sv2Date = getStringDates(sv2);

        if (sv1Date > sv2Date) return 1;
        if (sv1Date < sv2Date) return -1;
      }
      return sv2.id - sv1.id;
    });
    return survey;
  }, [previousTrips, upcomingTrips, showUpcoming, recentOrder]);

  useEffect(() => {
    if (surveys) {
      const localSurveys: { survey: LocalSurveyType } | null = JSON.parse(
        localStorage.getItem("survey_trip")!
      );

      if (localSurveys !== null) {
        const newSurveys = surveys
          .filter(
            (survey) =>
              localSurveys.survey[survey.id] === undefined &&
              survey.status === 4
          )
          .map((survey) => String(survey.id));
        saveLocalSurveys(newSurveys);
      }
    }
  }, [surveys]);
  useEffect(() => {
    setDeleteMode(false);
  }, [recentOrder, showUpcoming]);

  return (
    <div className="w-full overall-min-height px-4 sm:px-6">
      <div className="z-50 bg-white sticky top-14 sm:top-20 w-full h-full">
        <div className="card-regular-14 flex items-start pt-3 sm:pt-5 w-full h-full">
          <button
            className={`rounded-5xl sm:rounded-7xl px-5 py-2 sm:px-7 sm:py-3 ${
              showUpcoming ? "text-white bg-yoda-primary" : "bg-yoda-gray-2"
            }`}
            onClick={() => setShowUpcoming(true)}
          >
            다가오는 일정
          </button>
          <button
            className={`rounded-5xl sm:rounded-7xl px-5 py-2 sm:px-7 sm:py-3 ${
              showUpcoming ? "bg-yoda-gray-2" : "text-white bg-yoda-primary"
            } ml-2 sm:ml-3`}
            onClick={() => setShowUpcoming(false)}
          >
            지난 일정
          </button>
        </div>
        <div className=" card-regular-14 grid grid-cols-2 gap-0.5 sm:gap-1 mt-4 sm:mt-6 bg-yoda-gray-2 mytrip-sort-parent">
          <button
            className={`w-full h-8 sm:h-12 flex items-center justify-center text-yoda-black-1 ${
              recentOrder ? "bg-white" : ""
            }`}
            onClick={() => setRecentOrder(true)}
          >
            최근 주문일순
          </button>
          <button
            className={`w-full h-8 sm:h-12 flex items-center justify-center text-yoda-black-1 ${
              !recentOrder ? "bg-white" : ""
            }`}
            onClick={() => setRecentOrder(false)}
          >
            가까운 여행일순
          </button>
        </div>
        {(showUpcoming && upcomingTrips.length > 0) ||
        (!showUpcoming && previousTrips.length > 0) ? (
          <button
            className="w-full text-right pr-2 sm:pr-3 btn-bold-14 text-yoda-black-2 my-3.5 sm:my-5 whitespace-pre"
            onClick={() => setDeleteMode((prev) => !prev)}
          >
            {deleteMode ? "완료" : "삭제하기"}
          </button>
        ) : (
          <div className="h-11 sm:h-16">&nbsp;</div>
        )}
      </div>
      <div>
        {loadingApi ? (
          <div></div>
        ) : (
          <Trips
            trips={getSortedTrips()}
            callGetSurveys={callGetSurveys}
            deleteMode={deleteMode}
          />
        )}
      </div>
    </div>
  );
};

export default MyTrip;
