import React from "react";

const Picture = ({
  Low,
  Medium,
  High,
  className,
  style,
}: {
  Low: string;
  Medium: string;
  High: string;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <picture style={style}>
    <source srcSet={`${Low} 1x`} media="(max-width: 360px)" />
    <img
      srcSet={`${Medium} 2x, ${High} 3x`}
      alt=""
      className={className}
      style={style}
    />
  </picture>
);

export default Picture;
