import {
  DestFoodActType,
  DestMapCityType,
  DestTextType,
  ParentInterestType,
} from "../util/surveyOptions";
import { FavoriteType, HateCategoryType } from "../api/Survey";
import React, { Dispatch, createContext, useReducer } from "react";

export type OptionState = {
  favoriteFoodOptions: FavoriteType[];
  favoriteDrinkOptions: FavoriteType[];
  hateFoodOptions: HateCategoryType[];
  interestOptions: ParentInterestType[];
  destCityOptions: DestTextType[];
  destMapCityOptions: DestMapCityType[];
  destFoodOptions: DestFoodActType[];
  destActOptions: DestFoodActType[];
};

export type OptionAction =
  | {
      type: "UPDATE_INTEREST_OPTIONS";
      value: ParentInterestType[];
    }
  | {
      type: "UPDATE_DEST_OPTIONS";
      value: {
        destCityOptions: DestTextType[];
        destMapCityOptions: DestMapCityType[];
        destFoodOptions: DestFoodActType[];
        destActOptions: DestFoodActType[];
      };
    }
  | {
      type: "UPDATE_FOOD_PREFERENCE_OPTIONS";
      value: {
        favoriteFoodOptions: FavoriteType[];
        favoriteDrinkOptions: FavoriteType[];
        hateFoodOptions: HateCategoryType[];
      };
    };

type OptionReducer = React.Reducer<OptionState, OptionAction>;

const initialState: React.ReducerState<OptionReducer> = {
  favoriteFoodOptions: [],
  favoriteDrinkOptions: [],
  hateFoodOptions: [],
  interestOptions: [],
  destCityOptions: [],
  destMapCityOptions: [],
  destFoodOptions: [],
  destActOptions: [],
};

const reducer: OptionReducer = (state = initialState, action: OptionAction) => {
  switch (action.type) {
    case "UPDATE_INTEREST_OPTIONS":
      return { ...state, interestOptions: action.value };
    case "UPDATE_DEST_OPTIONS":
      return { ...state, ...action.value };
    case "UPDATE_FOOD_PREFERENCE_OPTIONS":
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const OptionContext = createContext<[OptionState, Dispatch<OptionAction>]>([
  initialState,
  () => initialState,
]);

const OptionProvider: React.FC<{}> = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <OptionContext.Provider value={value}>{children}</OptionContext.Provider>
  );
};

export { OptionContext, OptionProvider, initialState };
