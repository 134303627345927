import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { getSurveys } from "../api/Survey";
import { MainContext } from "../contexts/MainContext";
import { getToday } from "../util";
import getStringDates from "../util/formatFromMonth";

const useImminentTrip = () => {
  const history = useHistory();
  const [{ user }] = useContext(MainContext);
  const today = getToday();

  const onClickRecentTrip = async () => {
    try {
      if (user !== undefined) {
        const { data: surveys } = await getSurveys();

        const validSurvey = surveys
          .filter(
            (survey) =>
              survey.status === 4 &&
              ((survey.to !== null && survey.to > today.dayString) ||
                (survey.fromMonth && survey.fromMonth >= today.thisMonth))
          )
          .map((survey) => ({ id: survey.id, date: getStringDates(survey) }))
          .sort((sv1, sv2) => {
            if (sv1.date > sv2.date) {
              return 1;
            }
            if (sv1.date < sv2.date) {
              return -1;
            }
            return sv2.id - sv1.id;
          })[0];
        if (validSurvey) {
          history.push(`/trip/${validSurvey.id}`);
        } else {
          history.push("/empty-trip");
        }
      } else {
        history.push("/login");
      }
    } catch (e) {}
  };

  return onClickRecentTrip;
};

export default useImminentTrip;
