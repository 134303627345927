import { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import useTransport from "../../hooks/useTransport";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_2_4_4 = () => {
  const [surveyContext] = useContext(SurveyContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const transport = useTransport(surveyId, true);

  useEffect(() => {
    amplitude.getInstance().logEvent("2-4-4 yodapick opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (transport.isDispatchable()) {
      amplitude.getInstance().logEvent("2-4-4 yodapick activated", properties);
    }
  }, [transport.isDispatchable]);

  const onClickNext = () => {
    transport.dispatch();
    if (
      surveyContext.length === 1 ||
      (surveyContext.from.length > 0 &&
        surveyContext.to.length > 0 &&
        surveyContext.from === surveyContext.to)
    ) {
      history.push(`/survey/${surveyId}/step-3-2`);
    } else {
      history.push(`/survey/${surveyId}/step-3-1-1`);
    }
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-2-3`} />
      <div>
        <SurveyStep step={4} />
        <Title title={`선호하는 교통수단과\n이동시간을 알려주세요`} />
      </div>
      {transport.renderComponent}
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={transport.isDispatchable()}
        prevPath={`/survey/${surveyId}/step-2-3`}
      />
    </div>
  );
};

export default Survey_2_4_4;
