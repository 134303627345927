import { useEffect, useRef, useState } from "react";

import { ReactComponent as DotBar0 } from "../../assets/Guide/dotBar/progress dots-0.svg";
import { ReactComponent as DotBar1 } from "../../assets/Guide/dotBar/progress dots-1.svg";
import { ReactComponent as DotBar2 } from "../../assets/Guide/dotBar/progress dots-2.svg";
import { ReactComponent as DotBar3 } from "../../assets/Guide/dotBar/progress dots-3.svg";
import Image1 from "../../assets/Guide/image1.png";
import Image2 from "../../assets/Guide/image2.png";
import Image3 from "../../assets/Guide/image3.png";
import Image4 from "../../assets/Guide/image4.png";
import Image5 from "../../assets/Guide/image5.png";
import LeftArrow from "./LeftArrow";
import { Link } from "react-router-dom";
import RightArrow from "./RightArrow";
import Slider from "react-slick";
import progress1Selected from "../../assets/Guide/slideNumber/progress-1-selected.png";
import progress2Selected from "../../assets/Guide/slideNumber/progress-2-selected.png";
import progress3Selected from "../../assets/Guide/slideNumber/progress-3-selected.png";
import progress4Selected from "../../assets/Guide/slideNumber/progress-4-selected.png";
import useAddSurvey from "../../hooks/useAddSurvey";
import useHideScrollTop from "../../hooks/useHideScrollTop";

const Guide = () => {
  const slick = useRef(null);
  const surveyBtn = useRef(null);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const subTitleClass =
    "font-bold text-2xl sm:text-3xxl tracking-tight leading-normal text-center text-yoda-main ";

  const onClickAddSurvey = useAddSurvey();

  useHideScrollTop();

  useEffect(() => {
    const container = document.getElementById("padding-bottom");
    if (container) {
      container.classList.remove("overall-padding-bottom");
    }
    return () => {
      if (container) {
        container.classList.add("overall-padding-bottom");
      }
    };
  }, []);

  return (
    <div className="w-full bg-white sm:shadow-lg custom-min-height">
      <div className="w-full min-h-132 sm:min-h-184">
        <div className="w-full px-4">
          <div className="text-right pt-4 pr-2">
            <div className="h-7">
              {selectedIndex > 0 && (
                <button
                  className="text-sm sm:text-xl leading-none text-yoda-gray-5 font-bold"
                  onClick={onClickAddSurvey}
                >
                  건너뛰고 시작
                </button>
              )}
            </div>
          </div>
          <div>
            <Slider
              dots={false}
              infinite={false}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              className="w-full"
              ref={slick}
              arrows={false}
              afterChange={(index) => setSelectedIndex(index)}
            >
              <div className="">
                <div className="flex justify-center w-full pt-10 sm:pt-14 mb-6 relative overflow-hidden">
                  <div className="border-b-2 border-yoda-primary pb-1 sm:h-9">
                    <div className="heading-bold-18 h-6 sm:h-10">이용 방법</div>
                  </div>
                </div>
                <div className={subTitleClass}>
                  여다와 함께 나만의<br></br>여행일정 받는 방법
                </div>
                <img className="mt-6" src={Image1} alt="1-slide" />
              </div>
              <div>
                <div className="w-full flex justify-center py-2 sm:py-6">
                  <img
                    className="w-6 sm:w-9 h-6 sm:h-9"
                    src={progress1Selected}
                    alt="progress1Selected"
                  />
                </div>
                <div className={subTitleClass}>
                  8개의 질문을 통해<br></br>나의 여행 취향과 이번 여행에
                  <br></br>
                  바라는 점을 알려주세요.
                </div>
                <img className="mt-1" src={Image2} alt="2-slide" />
              </div>
              <div>
                <div className="w-full flex justify-center py-2 sm:py-6">
                  <img
                    className="w-6 sm:w-9 h-6 sm:h-9"
                    src={progress2Selected}
                    alt="progress2Selected"
                  />
                </div>
                <div className={subTitleClass}>
                  간편 회원가입을 하고<br></br>전화번호를 인증하면<br></br>주문
                  완료!
                </div>
                <img className="mt-1" src={Image3} alt="3-slide" />
              </div>
              <div>
                <div className="w-full flex justify-center py-2 sm:py-8">
                  <img
                    className="w-6 sm:w-9 h-6 sm:h-9"
                    src={progress3Selected}
                    alt="progress3Selected"
                  />
                </div>
                <div className={`${subTitleClass} mt-6`}>
                  나만의 여행일정이 1분 내로<br></br>카톡과 이메일로 전달됩니다.
                </div>
                <img className="mt-8" src={Image4} alt="4-slide" />
              </div>
              <div className="">
                <div className="w-full flex justify-center py-2 sm:py-8">
                  <img
                    className="w-6 sm:w-9 h-6 sm:h-9"
                    src={progress4Selected}
                    alt="progress4Selected"
                  />
                </div>
                <div>
                  <div className={`${subTitleClass} mt-6`}>
                    나만의 여행일정과 함께<br></br>바로 여행 출발!
                  </div>
                  <img className="mt-8" src={Image5} alt="5-slide" />
                </div>
                <Link to="/faq">
                  <div className="pt-9 text-sm sm:text-xl tracking-tighter leading-7 text-yoda-primary underline text-center">
                    더 궁금한 게 있으신가요?
                  </div>
                </Link>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="flex justify-between h-12 sm:h-18">
        {selectedIndex === 0 ? (
          <div className="flex-1">&nbsp;</div>
        ) : (
          <LeftArrow
            className="flex-1 z-20 h-full w-full"
            onClick={() => {
              // @ts-ignore
              slick.current!.slickPrev();
            }}
          />
        )}
        <div className="center-bar flex-1 flex justify-center items-center w-full h-full">
          {selectedIndex < 4 ? (
            <div className="h-2.5 sm:h-4 w-full ">
              {selectedIndex === 0 && <DotBar0 />}
              {selectedIndex === 1 && <DotBar1 />}
              {selectedIndex === 2 && <DotBar2 />}
              {selectedIndex === 3 && <DotBar3 />}
            </div>
          ) : (
            <div className="w-full h-full flex items-center">
              <button
                className="w-52 sm:w-72 h-full text-base sm:text-2xl leading-normal rounded sm:rounded-md bg-yoda-primary text-white tracking-tight font-bold"
                onClick={onClickAddSurvey}
                ref={surveyBtn}
              >
                여행일정 만들기 시작
              </button>
            </div>
          )}
        </div>
        {selectedIndex === 4 ? (
          <div className="flex-1">&nbsp;</div>
        ) : (
          <RightArrow
            className="flex-1 z-30 w-full h-full relative"
            onClick={() => {
              // @ts-ignore
              slick.current!.slickNext();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Guide;
