import {
  SurveyContext,
  initialState as initialSurveyState,
} from "../../contexts/SurveyContext";
import { routingToSurvey, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as Circle } from "../../assets/Survey/info-circle.svg";
import { MainContext } from "../../contexts/MainContext";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import usePayable from "../../hooks/usePayable";

const SurveyLoad = () => {
  const history = useHistory();
  const isPayable = usePayable();
  const { surveyId } = useParams<{ surveyId: string }>();
  const [{ user }] = useContext(MainContext);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);

  const onClickLoad = useCallback(() => {
    if (isPayable) {
      history.push(`/survey/${surveyId}/step-6-1`);
    } else {
      const route = routingToSurvey(surveyContext);

      if (route === "preview" || route === "step-1-1") {
        history.push(`/survey/${route}`);
      } else {
        history.push(`/survey/${surveyId}/${route}`);
      }
    }
  }, [surveyId, surveyContext, isPayable]);

  const onClickNew = useCallback(async () => {
    if (user === undefined) {
      localStorage.removeItem("survey");
    }
    dispatchSurveyContext({ type: "UPDATE_SURVEY", value: initialSurveyState });

    history.push("/survey/preview");
  }, [user, surveyId]);

  useEffect(() => {
    amplitude.getInstance().logEvent("load opened");
  }, []);

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath="/" />
      <Title title={`아직 완성하지 않은\n여행일정 주문서가 있어요`} />
      <div className="mt-1 sm:mt-1.5 flex items-center text-yoda-green-dark">
        <Circle className="w-5 sm:w-7 mr-1 sm:mr-1.5" />
        이전에 작성중이던 주문서는 사라집니다.
      </div>
      <div className="w-full">
        <div className="grid grid-cols-2 gap-2 sm:gap-3 h-12 sm:h-18 mt-6 sm:mt-9">
          <button
            className="w-full bg-yoda-gray-2 rounded sm:rounded-md text-yoda-gray-4 btn-bold-16"
            onClick={onClickNew}
          >
            새로 할게요
          </button>
          <button
            className="w-full bg-yoda-primary rounded sm:rounded-md text-white btn-bold-16"
            onClick={onClickLoad}
          >
            이어서 할게요
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyLoad;
