import React, { Dispatch, createContext, useReducer } from "react";
import { WeeklyPOIs, WeeklyTrips } from "../components/Weekly/type";

export type WeeklyState = {
  pois: WeeklyPOIs | undefined;
  trips: WeeklyTrips | undefined;
  index: number;
};

export type WeeklyAction =
  | {
      type: "UPDATE_POIS";
      value: WeeklyPOIs;
    }
  | { type: "UPDATE_TRIPS"; value: WeeklyTrips }
  | { type: "UPDATE_INDEX"; value: number };

type WeeklyReducer = React.Reducer<WeeklyState, WeeklyAction>;

const initialState: React.ReducerState<WeeklyReducer> = {
  pois: undefined,
  trips: undefined,
  index: 0,
};

const reducer: WeeklyReducer = (state = initialState, action: WeeklyAction) => {
  switch (action.type) {
    case "UPDATE_POIS":
      return { ...state, pois: action.value };
    case "UPDATE_TRIPS":
      return { ...state, trips: action.value };
    case "UPDATE_INDEX":
      return { ...state, index: action.value };
    default:
      return state;
  }
};

const WeeklyContext = createContext<[WeeklyState, Dispatch<WeeklyAction>]>([
  initialState,
  () => initialState,
]);

const WeeklyProvider: React.FC<{}> = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <WeeklyContext.Provider value={value}>{children}</WeeklyContext.Provider>
  );
};

export { WeeklyContext, WeeklyProvider };
