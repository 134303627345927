import { History, Location } from "history";
import { MainAction, MainContext } from "../../contexts/MainContext";
import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { getToday, getTwoDigits } from "../../util";

import AppSample from "../Native/AppSample";
import { ReactComponent as AppStoreLogo } from "../../assets/Main/appStoreLogo.svg";
import AppTrip from "../Native/AppTrip";
import Axios from "axios";
import Board from "../Weekly/Board";
import BottomBar from "../BottomBar";
import { ReactComponent as DesktopLogo } from "../../assets/desktopLogo.svg";
import DialogContainer from "../DialogContainer";
import Download from "../Popups/Download";
import EmptyTrip from "../Trip/EmptyTrip";
import Faq from "../Main/Faq";
import { ReactComponent as GooglePlayLogo } from "../../assets/Main/googlePlayLogo.svg";
import Guide from "../Guide/Guide";
import { LocalSurveyType } from "../Trip/types";
import Login from "../Login";
import MBTIBackground from "../../assets/MBTI/background.png";
import Main from "../Main/Main";
import MainBackground from "../../assets/General/mainBackground.png";
import MainBackgroundHigh from "../../assets/General/mainBackground@3x.png";
import MainBackgroundMedium from "../../assets/General/mainBackground@2x.png";
import MyPage from "../MyPage";
import MyTrip from "../MyTrip";
import NavBar from "../Navbar";
import { OptionProvider } from "../../contexts/OptionContext";
import Picture from "../General/Picture";
import Poi from "../Weekly/Poi";
import PoiPhoto from "../Weekly/PoiPhoto";
import Questions from "../MBTI/Questions";
import Result from "../MBTI/Result";
import SampleTrip from "../Trip/Sample";
import SignupSuccessPage from "../Signup/SignupSuccess";
import Specialty from "../Main/Specialty";
import Survey from "../Survey";
import { SurveyProvider } from "../../contexts/SurveyContext";
import TopScroll from "../../assets/Main/topScroll.png";
import Trip from "../Trip";
import UseAxios from "../../hooks/UseAxios";
import { WeeklyProvider } from "../../contexts/WeeklyContext";
import WeeklyTrip from "../Weekly/Trip";
import amplitude from "amplitude-js";
import { getMe } from "../../api/Accounts";
import getStringDates from "../../util/formatFromMonth";
import { getSurveys } from "../../api/Survey";
import saveLocalSurveys from "../../util/saveLocalSurveys";
import useTracking from "../../hooks/useTracking";

const today = getToday();

const checkLimitedDay = (survey: LocalSurveyType) => {
  const newDate = new Date(String(survey.saveDate));

  newDate.setDate(newDate.getDate() + 7);

  const limitedDay = `${newDate.getFullYear()}-${getTwoDigits(
    newDate.getMonth() + 1
  )}-${getTwoDigits(newDate.getDate())}`;

  return limitedDay <= today.dayString || survey.saveDate > survey.updatedAt;
};

const SignoutHandler = (
  dispatch: React.Dispatch<MainAction>,
  location: Location<unknown>,
  history: History<unknown>
) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    window.ReactNativeWebView?.postMessage(accessToken + "_LOGOUT");
  }
  localStorage.removeItem("survey_trip");
  localStorage.removeItem("accessToken");
  dispatch({
    type: "SIGNOUT",
  });
  const surveyReg = /survey\/\d+/;
  if (
    location.pathname.includes("/mypage") ||
    surveyReg.test(location.pathname)
  ) {
    history.push("/login");
  }
};

const scrollTop = () => {
  window.scrollTo(0, 0);
};

const ChatWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const [{ user }, dispatch] = useContext(MainContext);
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    scrollTop();
  }, [location]);
  useEffect(() => {
    if (
      prevLocation !== location.pathname &&
      user !== undefined &&
      (!user?.mobile || !user?.termAgreed)
    ) {
      if (location.pathname === "/") {
        SignoutHandler(dispatch, location, history);
      } else if (
        location.pathname.includes("/trip/") ||
        location.pathname.includes("/survey/") ||
        location.pathname.includes("/mbti")
      ) {
      } else if (!location.pathname.includes("/login")) {
        setPrevLocation(location.pathname);
        history.push(`/login?redirect_url=${location.pathname}`);
      }
    }
  }, [user, location, prevLocation]);
  useEffect(() => {
    const callGetMe = async () => {
      try {
        dispatch({ type: "UPDATE_GET_ME_DONE", value: false });
        UseAxios();
        const { data } = await getMe();

        if (data && data.accessToken) {
          localStorage.setItem("accessToken", data.accessToken);
          dispatch({ type: "UPDATE_USER", value: data });

          Axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.accessToken}`;
        } else {
          SignoutHandler(dispatch, location, history);
        }
      } catch (e) {
        SignoutHandler(dispatch, location, history);
      } finally {
        dispatch({ type: "UPDATE_GET_ME_DONE", value: true });
      }
    };
    callGetMe();
  }, []);
  useTracking();

  return <div className="h-full">{children}</div>;
};

const showDownloadPopup = () => {
  let check = false;
  const userAgent = navigator.userAgent;
  (function (a) {
    if (
      // @ts-ignore
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // @ts-ignore
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(userAgent || navigator.vendor);
  return check && userAgent !== "YodaReactNative";
};

function App() {
  const [showDownload, setShowDownload] = useState(false);
  const [{ user }] = useContext(MainContext);

  useEffect(() => {
    if (showDownloadPopup()) {
      setShowDownload(true);
    }
  }, []);

  useEffect(() => {
    amplitude
      .getInstance()
      .init(process.env.REACT_APP_AMPLITUDE_API_KEY!, undefined, {
        includeUtm: true,
        includeReferrer: true,
      });
  }, []);
  useEffect(() => {
    if (user && user.mobile && user.termAgreed) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        window.ReactNativeWebView?.postMessage(accessToken);
      }
    }
  }, [user]);

  useEffect(() => {
    const getCallSurveys = async () => {
      try {
        const { data: surveys } = await getSurveys();

        const localVisitedSurveys: string[] | null = JSON.parse(
          localStorage.getItem("visited")!
        );
        let prioritySurveys: string[] = [];

        if (localVisitedSurveys !== null) {
          const validSurveys = surveys
            .filter((survey) => survey.status === 4)
            .map((survey) => String(survey.id));

          localVisitedSurveys.forEach((surveyId) => {
            if (validSurveys.includes(surveyId)) {
              prioritySurveys.push(surveyId);
            }
          });
        }

        surveys
          .filter(
            (survey) =>
              survey.status === 4 &&
              (localVisitedSurveys === null ||
                !prioritySurveys.includes(String(survey.id))) &&
              ((survey.to && survey.to >= today.dayString) ||
                (survey.fromMonth && survey.fromMonth >= today.thisMonth))
          )
          .sort((sv1, sv2) => {
            const sv1Date = getStringDates(sv1);
            const sv2Date = getStringDates(sv2);

            if (sv1Date > sv2Date) return 1;
            if (sv1Date < sv2Date) return -1;
            return 0;
          })
          .forEach((survey) => {
            if (!prioritySurveys.includes(String(survey.id))) {
              prioritySurveys.push(String(survey.id));
            }
          });

        surveys.forEach((survey) => {
          if (
            !prioritySurveys.includes(String(survey.id)) &&
            survey.status === 4
          ) {
            prioritySurveys.push(String(survey.id));
          }
        });

        const localSurveys = JSON.parse(localStorage.getItem("survey_trip")!);
        const allSurveys =
          localSurveys === null
            ? prioritySurveys
            : prioritySurveys.filter(
                (surveyId) =>
                  localSurveys.survey[surveyId] &&
                  checkLimitedDay(localSurveys.survey[surveyId])
              );

        saveLocalSurveys(allSurveys.slice(0, 20));
      } catch (e) {}
    };

    if (user && user.mobile && user.termAgreed) {
      getCallSurveys();
    }
  }, [user]);

  return (
    <Router>
      <SurveyProvider>
        <Route path="/mbti">
          <div
            className="fixed h-full w-full z-30 bg-white bg-cover"
            style={{ backgroundImage: `url(${MBTIBackground})` }}
          >
            <Route exact path="/mbti">
              <Questions />
            </Route>
            <Route path="/mbti/result/:mbti">
              <Result />
            </Route>
          </div>
        </Route>
        <Route path="/app">
          <div className="fixed left-0 top-0 h-screen w-screen z-30 bg-white font-NotoSansKR">
            <Route path="/app/trip/:id">
              <AppTrip />
            </Route>
            <Route path="/app/sample-trip/:id">
              <AppSample />
            </Route>
            <Route path="/app/weekly-trip/:id">
              <AppSample weekly={true} />
            </Route>
          </div>
        </Route>
        <Route path="/">
          <ChatWrapper>
            <div className="h-full w-full relative font-NotoSansKR">
              <div id="first-h-full" className="w-full relative">
                <div
                  id="padding-bottom"
                  className="sm:left-44 max-w-full relative z-10 bg-white overall-padding-bottom"
                  style={{
                    width: "33.75rem",
                  }}
                >
                  <Switch>
                    <Route exact path="/">
                      <Main />
                    </Route>
                    <OptionProvider>
                      <WeeklyProvider>
                        <Route path="/weekly">
                          <Route exact path="/weekly">
                            <Board />
                          </Route>
                          <Route path="/weekly/trip/:id">
                            <WeeklyTrip />
                          </Route>
                          <Route path="/weekly/poi/:id">
                            <Poi />
                          </Route>
                          <Route path="/weekly/photo">
                            <PoiPhoto />
                          </Route>
                        </Route>
                      </WeeklyProvider>
                      <Route path="/survey">
                        <Survey />
                      </Route>
                      <NavBar />
                      <Route path="/signup/success">
                        <SignupSuccessPage />
                      </Route>
                      <div
                        id="padding-top"
                        className="w-full z-0 relative pt-14 sm:pt-20"
                      >
                        <Route path="/faq">
                          <Faq />
                        </Route>
                        <Route path="/specialty">
                          <Specialty />
                        </Route>
                        <Route path="/guide">
                          <Guide />
                        </Route>
                        <Route path="/login">
                          <Login />
                        </Route>
                        <Route path="/mypage">
                          <MyPage />
                        </Route>
                        <Route path="/mytrip">
                          <MyTrip />
                        </Route>
                        <Route path="/empty-trip">
                          <EmptyTrip />
                        </Route>
                      </div>
                      <Route path="/trip/:id">
                        <div className="h-full w-full">
                          <Trip />
                        </div>
                      </Route>
                      <Route path="/sample-trip/:id">
                        <div className="h-full w-full">
                          <SampleTrip />
                        </div>
                      </Route>
                      <Route path="/weekly-trip/:id">
                        <div className="h-full w-full">
                          <SampleTrip weekly={true} />
                        </div>
                      </Route>
                    </OptionProvider>
                  </Switch>
                  <BottomBar />
                  <button
                    id="scroll-top"
                    className="hidden sm:block fixed bottom-24 z-20"
                    style={{ left: "40rem" }}
                    onClick={scrollTop}
                  >
                    <img src={TopScroll} className="w-16 h-16" />
                  </button>
                  <button
                    id="scroll-top-mobile"
                    className="sm:hidden fixed bottom-20 z-20 right-3"
                    onClick={scrollTop}
                  >
                    <img src={TopScroll} className="w-12 h-12" />
                  </button>
                </div>
                <div
                  className="hidden md:block fixed h-full top-0 z-10"
                  style={{ left: "54rem" }}
                >
                  <div
                    className="left-0 relative flex flex-col items-end whitespace-nowrap text-xl font-medium text-right text-yoda-main tracking-tight"
                    style={{ top: "13.5rem" }}
                  >
                    <div>여행을 열다</div>
                    <div className="mt-1">
                      <DesktopLogo className="w-24" />
                    </div>
                    <div className="flex mt-12">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.yodareactnative"
                        target="_blank"
                      >
                        <GooglePlayLogo className="h-12" />
                      </a>
                      <a
                        href="https://apps.apple.com/app/id1563847214"
                        target="_blank"
                      >
                        <AppStoreLogo className="h-12 ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="fixed w-full h-full inset-0 bg-white sm:bg-yoda-skyblue">
                  <Picture
                    className="hidden sm:block fixed right-0 bottom-0 min-w-full h-auto"
                    style={{ width: "1920px" }}
                    Low={MainBackground}
                    Medium={MainBackgroundMedium}
                    High={MainBackgroundHigh}
                  />
                </div>
              </div>
              <DialogContainer />
              <Download show={showDownload} />
            </div>
          </ChatWrapper>
        </Route>
      </SurveyProvider>
    </Router>
  );
}

export default App;
