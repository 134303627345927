// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, { Marker } from "!mapbox-gl";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import { DestMapCityType } from "../../util/surveyOptions";
import { MainContext } from "../../contexts/MainContext";
import { OptionContext } from "../../contexts/OptionContext";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import useTransport from "../../hooks/useTransport";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();
const Survey_2_4_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [, dispatchMainContext] = useContext(MainContext);
  const [{ destMapCityOptions }] = useContext(OptionContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [destCity, setDestCity] = useState("");
  const [map, setMap] = useState<undefined | mapboxgl.Map>();
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);
  const transport = useTransport(surveyId);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;
    const center: [number, number] = surveyContext.departCoords
      ? [surveyContext.departCoords.lng, surveyContext.departCoords.lat]
      : [127.5, 36.5];
    var _map = new mapboxgl.Map({
      container: "map-2-4-1",
      style: "mapbox://styles/brian-storicity/ckmkbecgh3ho117lkiquz9g99",
      center,
      zoom: 6,
      maxZoom: 9,
      minZoom: 6,
      maxBounds: [
        [124, 33],
        [132, 39],
      ],
    });
    setMap(_map);
  }, []);
  useEffect(() => {
    setDestCity(surveyContext.destCity);
  }, [surveyContext.destCity]);
  const isDispatchable = useCallback(
    () => destCity.length > 0 && transport.isDispatchable(),
    [destCity, transport.isDispatchable]
  );

  const dispatch = useCallback(() => {
    dispatchSurveyContext({
      type: "UPDATE_DESTINATION",
      value: {
        destName: "",
        destDetermined: false,
        destType: "map",
        destCity,
        destFood: 0,
        destAct: 0,
      },
    });
    saveSurvey(surveyId, {
      ...surveyContext,
      destName: "",
      destDetermined: false,
      destType: "map",
      destCity,
      destFood: 0,
      destAct: 0,
    });
    transport.dispatch();
  }, [destCity, transport.dispatch, surveyId, surveyContext]);
  useEffect(() => {
    amplitude.getInstance().logEvent("2-4-1 map opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("2-4-1 map activated", properties);
    }
  }, [isDispatchable]);
  const getDestMapCityOptions = useCallback(
    () =>
      destMapCityOptions.filter((option) =>
        surveyContext.covidFree ? option.covidFree : true
      ),
    [destMapCityOptions, surveyContext.covidFree]
  );
  useEffect(() => {
    if (map) {
      let cityOptions: DestMapCityType[] = [];
      if (transport.isDriving) {
        cityOptions = getDestMapCityOptions().filter(
          (option) => transport.transTimes.indexOf(option.driveDistLimit) > -1
        );
      } else if (transport.isDriving === false) {
        cityOptions = getDestMapCityOptions().filter(
          (option) => transport.transTimes.indexOf(option.transitDistLimit) > -1
        );
      }
      if (cityOptions.length > 0 && transport.isDriving !== undefined) {
        const index = cityOptions.findIndex((city) => city.name === destCity);
        if (index > -1) {
          setDestCity(cityOptions[index].name);
        } else {
          setDestCity("");
        }
      }
      const _markers = cityOptions.map((city) => {
        const content = document.createElement("button");
        content.className = `absolute h-10 w-10 text-sm text-white flex justify-center items-center rounded-full ${
          city.name === destCity
            ? "bg-yoda-primary"
            : "bg-gray-500 bg-opacity-50"
        }`;
        content.onclick = () => setDestCity(city.name);
        content.innerText = city.name;
        return new Marker({ element: content }).setLngLat([
          city.longitude,
          city.latitude,
        ]);
      });
      setMarkers(_markers);
    }
  }, [
    map,
    getDestMapCityOptions,
    destCity,
    transport.isDriving,
    transport.transTimes,
  ]);
  useEffect(() => {
    if (map) {
      // @ts-ignore
      markers.forEach((marker) => marker.addTo(map));
    }
    return () => markers.forEach((marker) => marker.remove());
  }, [markers, map]);

  useEffect(() => {
    if (
      getDestMapCityOptions().length === 0 &&
      destMapCityOptions.length > 0 &&
      transport.isDriving !== undefined &&
      transport.transTimes.length > 0
    ) {
      dispatchMainContext({
        type: "UPDATE_DIALOG",
        value: {
          type: "DialogError",
          content: `조건에 맞는 추천을 해드리기 어려워요.\n교통수단과 이동시간을 변경해주세요.`,
        },
      });
    }
  }, [getDestMapCityOptions, transport, destMapCityOptions]);

  useEffect(() => {
    if (destCity) {
      window.scrollTo(0, 9999);
    }
  }, [destCity]);

  const onClickNext = () => {
    dispatch();
    if (destCity === "제주") {
      history.push(`/survey/${surveyId}/step-jeju`);
    } else {
      history.push(`/survey/${surveyId}/step-2-5`);
    }
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-2-3`} />
      <div>
        <SurveyStep step={4} />
        <Title title="지도에서 고르기" />
      </div>
      <div className="mt-1 sm:mt-1.5 body-regular-14">
        선택한 교통수단/이동시간에 맞는 여행지를 볼 수 있어요
      </div>
      {transport.renderComponent}
      <div className="mt-5 sm:mt-8 h-72 sm:h-176 w-full">
        <div id="map-2-4-1" className="w-full h-full"></div>
      </div>
      {destCity && (
        <div className="mt-6 sm:mt-9">
          <div className="text-yoda-green-dark body-medium-15 w-full text-right flex justify-end">
            <div>선택한 지역</div>
            <div className="w-7 ml-1.5 sm:ml-2.5">&nbsp;</div>
          </div>
          <div className="mt-1 sm:mt-1.5 w-full flex justify-end items-center">
            <div className="text-yoda-black-1 heading-bold-24">{destCity}</div>
            <button
              className="w-6 sm:w-9 ml-1.5 sm:ml-2.5"
              onClick={() => setDestCity("")}
            >
              <ClearIcon className="w-full h-full" />
            </button>
          </div>
        </div>
      )}
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-2-3`}
      />
    </div>
  );
};

export default Survey_2_4_1;
