import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Activity from "../../assets/Survey/destination/activity.png";
import ActivityHigh from "../../assets/Survey/destination/activity@3x.png";
import ActivityMedium from "../../assets/Survey/destination/activity@2x.png";
import { ReactComponent as CheckGreen } from "../../assets/Survey/checkGreen.svg";
import { ReactComponent as CheckWhite } from "../../assets/Survey/checkWhite.svg";
import Food from "../../assets/Survey/destination/food.png";
import FoodHigh from "../../assets/Survey/destination/food@3x.png";
import FoodMedium from "../../assets/Survey/destination/food@2x.png";
import Map from "../../assets/Survey/destination/map.png";
import MapHigh from "../../assets/Survey/destination/map@3x.png";
import MapMedium from "../../assets/Survey/destination/map@2x.png";
import { ReactComponent as Notice } from "../../assets/Survey/notice.svg";
import Picture from "../General/Picture";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import { ReactComponent as Yoda } from "../../assets/Survey/destination/yoda.svg";
import _ from "lodash";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import { v4 as uuidv4 } from "uuid";

const destTypeOptions = [
  {
    name: `지도에서\n고르기`,
    value: "map",
    image: Map,
    imageM: MapMedium,
    imageH: MapHigh,
  },
  {
    name: `음식으로\n고르기`,
    value: "food",
    image: Food,
    imageM: FoodMedium,
    imageH: FoodHigh,
  },
  {
    name: `할 것으로\n고르기`,
    value: "activity",
    image: Activity,
    imageM: ActivityMedium,
    imageH: ActivityHigh,
  },
];
const uuid = uuidv4();
const Survey_2_3 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [destDetermined, setDestDetermined] = useState<boolean>(false);
  const [destType, setDestType] = useState<
    "map" | "food" | "activity" | "anything" | undefined
  >();

  useEffect(() => {
    setDestDetermined(surveyContext.destDetermined);
    if (surveyContext.destFromWeeklyContents !== true) {
      setDestType(surveyContext.destType);
    }
  }, [surveyContext]);

  const isDispatchable = useCallback(
    () => destType !== undefined || destDetermined,
    [destDetermined, destType]
  );
  const dispatch = useCallback(() => {
    const value = {
      destName: destDetermined
        ? surveyContext.destName.indexOf(" (") > 0
          ? surveyContext.destName.substring(
              0,
              surveyContext.destName.indexOf(" (")
            )
          : surveyContext.destName
        : "",
      destDetermined,
      destType: destDetermined ? undefined : destType,
      destCity:
        !destDetermined && destType !== undefined && destType !== "anything"
          ? surveyContext.destCity
          : "",
      destAct:
        !destDetermined && destType !== undefined && destType !== "anything"
          ? surveyContext.destAct
          : 0,
      destFood:
        !destDetermined && destType !== undefined && destType !== "anything"
          ? surveyContext.destFood
          : 0,
    };
    dispatchSurveyContext({ type: "UPDATE_DESTINATION", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [destDetermined, destType, surveyId, surveyContext]);

  const moveNext = useCallback(() => {
    if (destType === "map") {
      history.push(`/survey/${surveyId}/step-2-4-1`);
    } else if (destType === "food") {
      history.push(`/survey/${surveyId}/step-2-4-2`);
    } else if (destType === "activity") {
      history.push(`/survey/${surveyId}/step-2-4-3`);
    } else if (destType === "anything") {
      history.push(`/survey/${surveyId}/step-2-4-4`);
    } else {
      history.push(`/survey/${surveyId}/step-2-4-5`);
    }
  }, [destType, surveyId, surveyContext]);

  useEffect(() => {
    amplitude.getInstance().logEvent("2-3 to opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("2-3 to activated", properties);
    }
  }, [isDispatchable]);
  useEffect(() => {
    if (destType !== undefined) {
      setDestDetermined(false);
    }
  }, [destType]);
  useEffect(() => {
    if (destDetermined) {
      setDestType(undefined);
    }
  }, [destDetermined]);

  const onClickNext = () => {
    dispatch();
    moveNext();
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-2-1`} />
      <div>
        <SurveyStep step={4} />
        <Title title="여행지를 정해볼까요?" />
      </div>
      <div className="w-full flex items-center mt-1.5 sm:mt-2.5">
        <Notice className="notice-icon" />
        <div className="ml-1.5 sm:ml-2.5 body-regular-14 text-yoda-green-dark">
          현재 서울을 제외한 전국 가능
        </div>
      </div>
      <div className="mt-6 sm:mt-9 w-full grid grid-cols-4 gap-x-2 sm:gap-x-3">
        {destTypeOptions.map((dest, index) => (
          <button
            key={index}
            className={`w-full relative rounded sm:rounded-md overflow-hidden ${
              destType === dest.value ? "shadow-lg" : ""
            }`}
            onClick={() =>
              setDestType(dest.value as "map" | "food" | "activity")
            }
          >
            {destType === dest.value && (
              <CheckGreen
                className="z-10 absolute top-1 right-1 sm:top-1.5 sm:right-1.5 w-5 h-5 sm:w-7 sm:h-7"
                style={{
                  filter: "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2))",
                }}
              />
            )}
            <div
              className={`absolute h-full w-full top-0 left-0 rounded sm:rounded-md ${
                destType === dest.value
                  ? "border-2 border-yoda-primary"
                  : "border border-yoda-gray-2 opacity-80"
              } z-10`}
            ></div>
            <div
              className={`flex flex-col items-center rounded sm:rounded-md ${
                destType === dest.value
                  ? "text-yoda-black-1"
                  : "text-yoda-black-3"
              }`}
            >
              <div className="w-full p-1 sm:p-1.5 bg-yoda-gray-0">
                <Picture
                  className={`w-full h-auto ${
                    destType === dest.value ? "" : "opacity-80"
                  }`}
                  Low={dest.image}
                  Medium={dest.imageM}
                  High={dest.imageH}
                />
              </div>
              <div className="border-t border-yoda-gray-2 w-full"></div>
              <div className="text-center card-regular-14 py-1.5 sm:py-2.5 whitespace-pre-line w-full rounded-b sm:rounded-b-md">
                {dest.name}
              </div>
            </div>
          </button>
        ))}
        <button
          key="anything"
          className="w-full relative"
          onClick={() => setDestType("anything")}
        >
          {destType === "anything" && (
            <CheckGreen className="z-10 absolute top-1 right-1 sm:top-1.5 sm:right-1.5 w-5 h-5 sm:w-7 sm:h-7" />
          )}
          <div
            className={`flex flex-col items-center rounded sm:rounded-md ${
              destType === "anything"
                ? "border-yoda-primary text-yoda-black-1 border-2 shadow-md"
                : "border-yoda-gray-2 text-yoda-black-3 border"
            }`}
          >
            <div
              className={`w-full p-1 sm:p-1.5 bg-yoda-gray-0 rounded-t sm:rounded-t-md ${
                destType === "anything"
                  ? ""
                  : "border-yoda-gray-0 border-l border-t border-r"
              }`}
            >
              <Yoda
                className={`w-full h-auto ${
                  destType === "anything" ? "" : "opacity-80"
                }`}
              />
            </div>
            <div className="border-t border-yoda-gray-2 w-full"></div>
            <div
              className={`text-center card-regular-14 py-1.5 sm:py-2.5 whitespace-pre-line w-full rounded-b sm:rounded-b-md ${
                destType === "anything"
                  ? ""
                  : "border-white border-l border-b border-r"
              }`}
            >
              {`여다가\n정해주세요!`}
            </div>
          </div>
        </button>
      </div>
      <div className="mt-6 sm:mt-9 flex items-center w-full">
        <div className="flex-1 border-t border-yoda-gray-2"></div>
        <div className="mx-2 sm:mx-3 body-regular-12 text-yoda-gray-4">
          또는
        </div>
        <div className="flex-1 border-t border-yoda-gray-2"></div>
      </div>
      <button
        className={`mt-5 sm:mt-7 h-12 sm:h-18 w-full rounded sm:rounded-md body-regular-16 relative ${
          destDetermined
            ? "bg-yoda-primary text-white shadow-md"
            : "bg-yoda-gray-2 text-yoda-black-3"
        }`}
        onClick={() => setDestDetermined((prev) => !prev)}
      >
        {destDetermined && (
          <CheckWhite className="absolute h-6 w-6 sm:h-9 sm:w-9 top-3 right-3 sm:top-4.5 sm:right-4.5" />
        )}
        이미 정한 여행지가 있어요
      </button>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-2-1`}
      />
    </div>
  );
};

export default Survey_2_3;
