import { useEffect, useState } from "react";

import AppIcon from "../../assets/Main/appIcon.png";
import useOnclickOutside from "react-cool-onclickoutside";

const downloadPopupBlockTime = "popup-download-block-time";

const Download = ({ show }: { show: boolean }) => {
  const [valid, setValid] = useState(false);
  const showDownloadPopup = () => {
    let showDownloadPopup = true;
    let downloadPopupBlockedAt = localStorage.getItem(downloadPopupBlockTime);
    if (downloadPopupBlockedAt) {
      const today = new Date();
      const startOfToday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      );
      if (parseInt(downloadPopupBlockedAt) >= startOfToday.getTime()) {
        showDownloadPopup = false;
      }
    }

    setValid(showDownloadPopup);
  };
  useEffect(() => {
    showDownloadPopup();
  }, []);
  const ref = useOnclickOutside((e) => {
    if (e.type !== "mousedown") {
      return;
    }
    setValid(false);
  });

  if (!valid || !show) return null;
  return (
    <div className="z-10 w-full h-full fixed top-0 left-0 right-0 bg-black bg-opacity-70">
      <div
        ref={ref}
        className="w-full sticky bottom-0 top-full sm:inset-y-auto rounded-t-md sm:rounded-md bg-white pt-10"
      >
        <div className="px-16">
          <div className="w-full flex items-center mb-4">
            <img src={AppIcon} className="rounded w-16 h-16" />
            <div className="text-lg font-bold leading-normal text-yoda-black-2 tracking-tight ml-6">
              맞춤형 여행 일정<br></br>여다앱에서 만나보세요
            </div>
          </div>
          <a
            className="flex justify-center items-center bg-yoda-black-2 rounded-md text-white font-bold text-base w-full h-12 mb-4"
            href="https://yodareactnative.page.link/UkMX"
            target="_blank"
          >
            여다 앱 설치하기
          </a>
        </div>
        <div className="flex justify-center items-center">
          <button
            className="text-sm text-yoda-gray-4 underline inline-block font-normal"
            onClick={() => {
              setValid(false);
            }}
            name="default-close"
          >
            괜찮아요. 모바일 웹으로 볼게요
          </button>
        </div>
        <div className="w-full grid grid-cols-2 gap-0.5 text-yoda-black-1 text-sm font-normal mt-4">
          <button
            onClick={() => {
              setValid(false);
              localStorage.setItem(
                downloadPopupBlockTime,
                Date.now().toString()
              );
            }}
            className="block w-full h-16 bg-yoda-gray-1"
            name="left"
          >
            하루동안 보지않기
          </button>
          <button
            onClick={() => {
              setValid(false);
            }}
            className="block w-full h-16 bg-yoda-gray-1"
            name="right"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Download;
