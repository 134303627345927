import { SurveysType } from "./../api/Survey";
import { getTwoDigits } from ".";

const checkUpdatedDate = (updatedAt: string, fromMonth: number) => {
  const now = new Date();
  const month = getTwoDigits(fromMonth);
  const fromLastDay = new Date(now.getFullYear(), fromMonth, 0).getDate();
  const fromDate = `${now.getFullYear()}-${month}-${fromLastDay}`;

  if (fromDate < updatedAt) {
    const nextYear = parseInt(updatedAt.substring(0, 4)) + 1;
    return `${nextYear}-${month}-${fromLastDay}`;
  }
  return fromDate;
};

const getStringDates = (survey: SurveysType): string => {
  if (
    survey === undefined ||
    (survey.from === "" &&
      survey.to === "" &&
      survey.fromMonth === 0 &&
      survey.length === 0)
  ) {
    return "";
  }

  if (
    survey.from !== "" &&
    survey.from !== null &&
    survey.to !== "" &&
    survey.to !== null
  ) {
    return survey.from;
  }

  return checkUpdatedDate(survey.updatedAt, survey.fromMonth!);
};

export default getStringDates;
export { checkUpdatedDate };
