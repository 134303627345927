import { useState, useCallback, useEffect } from "react";
import { ReactComponent as Up } from "../../assets/General/up.svg";
import { ReactComponent as Down } from "../../assets/General/down.svg";
import AnimateHeight from "react-animate-height";

const Question = ({
  index,
  title,
  describe,
}: {
  index: number;
  title: string;
  describe: string;
}) => {
  const [height, setHeight] = useState<string | number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const handleClickQuestion = useCallback(() => {
    setOpen(!open);
  }, [open]);
  useEffect(() => {
    if (open) {
      setHeight("auto");
    } else {
      setHeight(0);
    }
  }, [open]);

  return (
    <div className="w-full border-yoda-yellow-gray border mb-0.5">
      <button className="w-full" onClick={handleClickQuestion}>
        <div className="px-3 h-10 sm:h-14 body-medium-12 bg-yoda-yellow-gray flex items-center justify-start">
          <div className="mr-2.5">Q{index + 1}.</div>
          <div>{title}</div>
          <div className="flex-1"></div>
          {open ? (
            <Up className="inline-block h-6 absolute right-6" />
          ) : (
            <Down className="inline-block h-6 absolute right-6" />
          )}
        </div>
      </button>
      <AnimateHeight
        id={`FAQ-${index}`}
        duration={500}
        height={height}
        className="px-5 sm:px-6 bg-white "
      >
        <div className="py-3 sm:py-5 body-regular-12">{describe}</div>
      </AnimateHeight>
    </div>
  );
};

export default Question;
