import { ReactComponent as Home } from "../../../assets/Survey/home.svg";
import { ReactComponent as LeftArrow } from "../../../assets/Survey/left.svg";
import { Link } from "react-router-dom";

const SurveyTopBar = ({
  prevPath,
  hideHome,
}: {
  prevPath: string;
  hideHome?: boolean;
}) => (
  <div className="w-full h-12 sm:h-20 relative flex items-center justify-between survey-nav-bar">
    <Link className="w-6 sm:w-9" to={prevPath}>
      <LeftArrow className="w-full h-full" />
    </Link>
    {!hideHome && (
      <Link className="w-6 sm:w-9" to="/">
        <Home className="w-full h-full" />
      </Link>
    )}
  </div>
);

export default SurveyTopBar;
