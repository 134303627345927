import { getHateCategoryName, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { OptionContext } from "../../contexts/OptionContext";
import Picture from "../General/Picture";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";

const inactiveClassName =
  "border sm:border-1 border-yoda-black-3 text-yoda-black-3";
const activeGreenClassName =
  "border sm:border-1 border-yoda-primary bg-yoda-primary text-white";
const activeRedClassname =
  "border sm:border-1 border-yoda-error-red bg-yoda-error-red text-white";

const FoodButton = ({
  imageUrlHigh,
  imageUrlLow,
  imageUrlMedium,
  name,
  onClick,
  className,
  filter,
}: {
  imageUrlHigh?: string | null;
  imageUrlLow?: string | null;
  imageUrlMedium?: string | null;
  name: string;
  onClick: () => void;
  className: string;
  filter: string;
}) => (
  <button
    className={`py-2 sm:py-3 px-3 sm:px-5 rounded-full mr-1.5 sm:mr-2 mb-3 sm:mb-5 ${className}`}
    onClick={onClick}
  >
    <div className="w-full h-full flex item-center justify-center">
      {imageUrlHigh && imageUrlMedium && imageUrlLow && (
        <Picture
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            filter,
          }}
          className="w-4 sm:w-7 mr-1 sm:mr-2"
          High={imageUrlHigh}
          Medium={imageUrlMedium}
          Low={imageUrlLow}
        />
      )}
      <div className="body-medium-15">{name}</div>
    </div>
  </button>
);

const Survey_4_2 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [optionContext] = useContext(OptionContext);
  const [favoriteFoodTypes, setFavoriteFoodTypes] = useState<
    string[] | undefined
  >(undefined);
  const [favoriteDrinkTypes, setFavoriteDrinkTypes] = useState<
    string[] | undefined
  >(undefined);

  const history = useHistory();
  const { surveyId } = useParams<{ surveyId: string }>();

  useEffect(() => {
    if (surveyContext.favoriteFoodTypes && surveyContext.favoriteDrinkTypes) {
      setFavoriteFoodTypes(surveyContext.favoriteFoodTypes);
      setFavoriteDrinkTypes(surveyContext.favoriteDrinkTypes);
    }
  }, [surveyContext]);

  const isDispatchable = useCallback(
    () => favoriteFoodTypes !== undefined && favoriteDrinkTypes !== undefined,
    [favoriteFoodTypes, favoriteDrinkTypes]
  );

  const setTypes = (prev: string[] | undefined, value: string) => {
    if (prev === undefined) return [value];
    const index = prev.findIndex((t) => t === value);

    if (index > -1) {
      if (prev.length > 1) {
        return prev.slice(0, index).concat(prev.slice(index + 1));
      }
      return;
    }
    return prev.concat(value);
  };

  const dispatch = useCallback(() => {
    if (favoriteDrinkTypes && favoriteFoodTypes) {
      const value = { favoriteDrinkTypes, favoriteFoodTypes };
      dispatchSurveyContext({
        type: "UPDATE_FOOD_PREFERENCE",
        value,
      });
      saveSurvey(surveyId, { ...surveyContext, ...value });
    }
  }, [favoriteDrinkTypes, favoriteFoodTypes]);

  const onClickNext = () => {
    dispatch();
    if (surveyContext.destType === "anything") {
      history.push(`/survey/${surveyId}/step-3-4`);
    } else {
      history.push(`/survey/${surveyId}/step-5-1`);
    }
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <div>
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-4-1`} />
      </div>
      <div>
        <SurveyStep step={surveyContext.destType === "anything" ? 6 : 7} />
        <Title title="음식 취향은 어떻게 되시나요?" />
        <div className="body-regular-14 text-yoda-black-2 mt-1 sm:mt-2">
          해당하는 것을 모두 골라주세요
        </div>
      </div>
      <div className="pt-6 sm:pt-9">
        <div id="favoriteFoodQuestion" className="pb-10 sm:pb-15">
          <div className="mb-4 sm:mb-6 body-medium-15 text-yoda-black-2">
            Q1.좋아하는 음식이 있나요?
          </div>
          <div className="flex flex-wrap">
            {optionContext.favoriteFoodOptions.map((food) => (
              <FoodButton
                imageUrlHigh={food.imageUrlHigh}
                imageUrlMedium={food.imageUrlMedium}
                imageUrlLow={food.imageUrlLow}
                name={food.name}
                className={
                  favoriteFoodTypes?.includes(food.value)
                    ? activeGreenClassName
                    : inactiveClassName
                }
                onClick={() =>
                  setFavoriteFoodTypes((prev) => setTypes(prev, food.value))
                }
                filter={
                  favoriteFoodTypes?.includes(food.value)
                    ? "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3))"
                    : ""
                }
              />
            ))}
          </div>
        </div>
        <div id="favoriteDrinkQuestion" className="pb-10 sm:pb-15">
          <div className="mb-4 sm:mb-6 body-medium-15 text-yoda-black-2">
            Q2.어떤 술을 좋아하세요?
          </div>
          <div className="flex flex-wrap">
            {optionContext.favoriteDrinkOptions.map((drink) => (
              <FoodButton
                imageUrlHigh={drink.imageUrlHigh}
                imageUrlMedium={drink.imageUrlMedium}
                imageUrlLow={drink.imageUrlLow}
                name={drink.name}
                className={
                  favoriteDrinkTypes?.includes(drink.value)
                    ? activeGreenClassName
                    : inactiveClassName
                }
                onClick={() =>
                  setFavoriteDrinkTypes((prev) => setTypes(prev, drink.value))
                }
                filter={
                  favoriteDrinkTypes?.includes(drink.value)
                    ? "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3))"
                    : ""
                }
              />
            ))}
            <FoodButton
              name="술은 안마셔요"
              onClick={() =>
                setFavoriteDrinkTypes((prev) => {
                  if (Array.isArray(prev) && prev.length === 0) {
                    return;
                  }
                  return [];
                })
              }
              className={
                favoriteDrinkTypes?.length === 0
                  ? activeGreenClassName
                  : inactiveClassName
              }
              filter=""
            />
          </div>
        </div>
      </div>
      <SurveyBottomBar
        prevPath={`/survey/${surveyId}/step-4-1`}
        isDispatchable={isDispatchable()}
        onClickNext={onClickNext}
      />
    </div>
  );
};
export default Survey_4_2;
