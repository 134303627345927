import MyPagePage from "./MyPage";
import InfoPage from "./Info";
import { Route, Switch } from "react-router-dom";

const MyPage = () => (
  <Switch>
    <Route path="/mypage/info">
      <InfoPage />
    </Route>
    <Route exact path="/mypage">
      <MyPagePage />
    </Route>
  </Switch>
);

export default MyPage;
