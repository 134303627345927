import {
  SurveyContext,
  initialState as initialSurveyState,
} from "../contexts/SurveyContext";
import { useCallback, useContext, useState } from "react";

import { MainContext } from "../contexts/MainContext";
import PaymentComplete from "../assets/Survey/payment/paymentComplete@3x.png";
import ReactGA from "react-ga";
import amplitude from "amplitude-js";
import { callPurchaseTracking } from "../util/tracking";
import { createPurchaseConfirm } from "../api/Survey";
import { useHistory } from "react-router-dom";
import usePayable from "./usePayable";

const usePurchaseConfirm = (surveyId: string) => {
  const history = useHistory();
  const [, dispatch] = useContext(MainContext);
  const [, dispatchSurveyContext] = useContext(SurveyContext);

  const [purchaseConfirming, setPurchaseConfirming] = useState(false);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const isPurchasable =
    usePayable() && !purchaseConfirming && !purchaseCompleted;

  const showDialog = () =>
    dispatch({
      type: "UPDATE_DIALOG",
      value: {
        type: "DialogConfirmBlack",
        content: (
          <div>
            <div className="heading-bold-22 text-center">
              여행일정 주문 완료!
            </div>
            <div className="body-regular-14 text-center mt-1 sm:mt-1.5">
              3분 안에 보내 드릴게요.
            </div>
            <img
              className="h-40 sm:h-60 my-3.5 sm:my-5"
              src={PaymentComplete}
              alt="paymentComplete"
            />
          </div>
        ),
        onClickYes: () => history.push("/mytrip"),
        onClickOuter: () => history.push("/mytrip"),
      },
    });

  const completePayment = useCallback(
    async (destAct: number) => {
      try {
        setPurchaseConfirming(true);
        await createPurchaseConfirm(surveyId, null, 0, {
          type: "beta_free_period",
        });
        amplitude.getInstance().logEvent("complete payment");
        callPurchaseTracking(0);
        window.ReactNativeWebView?.postMessage("PURCHASE");
        showDialog();
        dispatchSurveyContext({
          type: "UPDATE_SURVEY",
          value: initialSurveyState,
        });
        localStorage.removeItem("survey");
        setPurchaseCompleted(true);
        if (destAct === 11788) {
          ReactGA.event({
            category: "주간여다POI",
            action: "weekly_poi_visit",
            label: "보령머드 일정표 주문",
          });
        }
      } catch (e) {
      } finally {
        setPurchaseConfirming(false);
      }
    },
    [surveyId]
  );

  return { isPurchasable, completePayment };
};

export default usePurchaseConfirm;
