import { JejuCities, SubDest } from "../../util/surveyOptions";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import IllustHalla from "../../assets/Survey/jeju/Illust_Mt.Halla.png";
import IllustHallaHigh from "../../assets/Survey/jeju/Illust_Mt.Halla@3x.png";
import IllustHallaMedium from "../../assets/Survey/jeju/Illust_Mt.Halla@2x.png";
import Jeju from "../../assets/Survey/jeju/Jeju_whole.png";
import JejuHigh from "../../assets/Survey/jeju/Jeju_whole@3x.png";
import JejuMedium from "../../assets/Survey/jeju/Jeju_whole@2x.png";
import Picture from "../General/Picture";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_2_4_2 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [jejuCities, setJejuCities] = useState<SubDest[]>([]);

  useEffect(() => {
    setJejuCities(surveyContext.subDests);
  }, [surveyContext.subDests]);
  const isDispatchable = useCallback(() => jejuCities.length > 0, [jejuCities]);
  const dispatch = useCallback(() => {
    const value = {
      subDests: jejuCities,
    };
    dispatchSurveyContext({
      type: "UPDATE_SUB_DESTS",
      value,
    });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [surveyId, surveyContext, jejuCities]);
  useEffect(() => {
    amplitude.getInstance().logEvent("jeju city opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("jeju city activated", properties);
    }
  }, [isDispatchable]);

  const prevPath = useCallback(
    () => (surveyContext.destDetermined ? "2-4-5" : "2-4-1"),
    [surveyContext.destDetermined]
  );
  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-2-5`);
  };

  return (
    <div className="w-full survey-min-height">
      <div className="w-full px-6 sm:px-9">
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-${prevPath()}`} />
        <div>
          <SurveyStep step={4} />
          <Title title="제주도 어디로 가시나요?" />
        </div>
        <div className="mt-1 sm:mt-1.5 body-regular-14">
          가고 싶으신 곳을 모두 골라주세요
        </div>
        <div className="w-full h-60 sm:h-90 relative">
          <Picture
            className="w-full h-full"
            Low={Jeju}
            Medium={JejuMedium}
            High={JejuHigh}
          />
          {JejuCities.map((city) => (
            <button
              className={`${city.className} ${
                jejuCities.some((j) => j.name === city.name)
                  ? "bg-yoda-primary text-white"
                  : "bg-white text-yoda-gray-5"
              } rounded shadow body-medium-12 absolute z-50`}
              onClick={() => {
                setJejuCities((prevCities) => {
                  const index = prevCities.findIndex(
                    (t) => city.name === t.name
                  );
                  if (index > -1) {
                    return prevCities
                      .slice(0, index)
                      .concat(prevCities.slice(index + 1));
                  }
                  return prevCities.concat({
                    name: city.name,
                    coords: { latitude: city.lat, longitude: city.lng },
                  });
                });
              }}
            >
              <div>{city.name}</div>
            </button>
          ))}
          {JejuCities.map((city) => (
            <Picture
              className={`w-full h-full absolute top-0 left-0 z-10 ${
                jejuCities.some((j) => j.name === city.name) ? "" : "hidden"
              }`}
              Low={city.low}
              Medium={city.medium}
              High={city.high}
            />
          ))}
          <Picture
            className="absolute top-0 left-0 w-full h-full z-40"
            Low={IllustHalla}
            Medium={IllustHallaMedium}
            High={IllustHallaHigh}
          />
        </div>
        <div className="text-yoda-green-dark body-medium-15 w-full text-right flex justify-end">
          <div>선택한 지역</div>
          <div className="w-7 ml-1.5 sm:ml-2.5">&nbsp;</div>
        </div>
        <div className="h-9 sm:h-10">
          {jejuCities.length > 0 ? (
            <div className="mt-1 sm:mt-1.5 w-full flex justify-end items-center">
              <div className="text-yoda-black-1 heading-bold-24">
                {jejuCities.map((j) => j.name).join(", ")}
              </div>
              <button
                className="w-6 sm:w-9 ml-1.5 sm:ml-2.5"
                onClick={() => setJejuCities([])}
              >
                <ClearIcon className="w-full h-full" />
              </button>
            </div>
          ) : (
            <div className="mt-1 sm:mt-1.5 w-full flex justify-end items-center">
              <div className="text-yoda-gray-3 btn-bold-16">선택해주세요</div>
              <div className="h-7 w-7 ml-1.5 sm:ml-2.5">&nbsp;</div>
            </div>
          )}
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-${prevPath()}`}
      />
    </div>
  );
};

export default Survey_2_4_2;
