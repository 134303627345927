import { useCallback, useContext } from "react";

import Home from "../../assets/BottomBar/home.png";
import HomeSelected from "../../assets/BottomBar/homeSelected.png";
import Icon from "./Icon";
import { MainContext } from "../../contexts/MainContext";
import My from "../../assets/BottomBar/my.png";
import MySelected from "../../assets/BottomBar/mySelected.png";
import Schedule from "../../assets/BottomBar/schedule.png";
import ScheduleSelected from "../../assets/BottomBar/scheduleSelected.png";
import Survey from "../../assets/BottomBar/survey.png";
import SurveySelected from "../../assets/BottomBar/surveySelected.png";
import Weekly from "../../assets/BottomBar/weekly.png";
import WeeklySelected from "../../assets/BottomBar/weeklySelected.png";
import useAddSurvey from "../../hooks/useAddSurvey";
import useImminentTrip from "../../hooks/useImminentTrip";
import { useLocation } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const BottomBar = () => {
  const query = useQuery();
  const redirectUrl = query.get("redirect_url");
  const location = useLocation();
  const [{ user }] = useContext(MainContext);

  const onClickAddSurvey = useAddSurvey();
  const onClickRecentTrip = useImminentTrip();

  const isSelected = useCallback(
    (path: string) => {
      return location.pathname.includes(path);
    },
    [location]
  );

  if (
    location.pathname.includes("/survey") ||
    location.pathname.includes("/weekly/trip") ||
    redirectUrl?.includes("/step-6-1") ||
    location.pathname.includes("weekly/poi") ||
    location.pathname.includes("weekly/photo") ||
    location.pathname.includes("app")
  )
    return null;
  // h-7 sm:h-10 mt-4
  // mt-0.5 sm:mt-1 mb-2 h-4
  // mobile: 7+4+0.5+2+4 = 17.5 = 4.375rem
  // desktop: 10+4+1+2+4 = 21 = 5.25rem
  else if (
    location.pathname !== "/specialty" &&
    location.pathname !== "/guide"
  ) {
    return (
      <div
        className="fixed bottom-0 left-0 sm:left-44 flex justify-center bg-white max-w-full z-10"
        style={{ width: "33.75rem" }}
      >
        <div className="flex w-full justify-around sm:justify-between max-w-3xl">
          <Icon
            icon={
              location.pathname === "/" || isSelected("/faq")
                ? HomeSelected
                : Home
            }
            link="/"
            name="홈"
            current={location.pathname === "/" || isSelected("/faq")}
          />
          <Icon
            icon={isSelected("/weekly") ? WeeklySelected : Weekly}
            link="/weekly"
            name="주간 여다"
            current={isSelected("/weekly")}
          />
          <Icon
            icon={isSelected("/survey/") ? SurveySelected : Survey}
            onClick={onClickAddSurvey}
            name="새로 만들기"
            current={isSelected("/survey/")}
          />
          <Icon
            onClick={onClickRecentTrip}
            icon={
              isSelected("/trip/") || isSelected("/empty-trip")
                ? ScheduleSelected
                : Schedule
            }
            name="다가오는 여행"
            current={isSelected("/trip/") || isSelected("/empty-trip")}
          />
          <Icon
            link={user ? "/mypage" : "/login"}
            icon={
              isSelected("/mypage") ||
              isSelected("/login") ||
              isSelected("/mytrip")
                ? MySelected
                : My
            }
            name="마이페이지"
            current={
              isSelected("/mypage") ||
              isSelected("/login") ||
              isSelected("/mytrip")
            }
          />
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default BottomBar;
