import { Link } from "react-router-dom";

const Icon = ({
  link,
  onClick,
  icon,
  name,
  current,
}: {
  link?: string;
  onClick?: () => void;
  icon: string;
  name: string;
  current: boolean;
}) => {
  if (link !== undefined)
    return (
      <Link to={link} className="flex-1 relative">
        <div
          className="border-t-2 w-full absolute inset-0"
          style={{ borderColor: current ? "#88B14B" : "#e6e6e6" }}
        ></div>
        <div>
          <img src={icon} className="h-7 sm:h-10 mx-auto mt-3" />
          <div
            className={`text-1.5xs sm:text-sm text-center leading-5 ${
              current ? "text-yoda-main-temp" : "text-yoda-gray-3"
            } mt-0.5 sm:mt-1 mb-2 h-4`}
          >
            {name}
          </div>
        </div>
      </Link>
    );
  if (onClick !== undefined) {
    return (
      <button onClick={onClick} className="flex-1 relative">
        <div
          className="border-t-2 w-full absolute inset-0"
          style={{ borderColor: current ? "#88B14B" : "#e6e6e6" }}
        ></div>
        <div>
          <img src={icon} className="h-7 sm:h-10 mx-auto mt-3" />
          <div
            className={`text-1.5xs sm:text-sm text-center leading-5 ${
              current ? "text-yoda-main-temp" : "text-yoda-gray-3"
            } mt-0.5 sm:mt-1 mb-2 h-4`}
          >
            {name}
          </div>
        </div>
      </button>
    );
  }
  return null;
};

export default Icon;
