import "./survey.css";

import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as CheckActive } from "../../assets/Survey/preview/active.svg";
import { ReactComponent as CheckInactive } from "../../assets/Survey/preview/inactive.svg";
import { ReactComponent as Circle } from "../../assets/Survey/info-circle.svg";
import { MainContext } from "../../contexts/MainContext";
import { ReactComponent as QuestionMark } from "../../assets/Survey/preview/question.svg";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import Tooltip from "../../assets/Survey/preview/tooltip.png";
import amplitude from "amplitude-js";
import { callPageTracking } from "../../util/tracking";
import { getSurveys } from "../../api/Survey";

const SurveyPreview = () => {
  const history = useHistory();
  const [{ user }] = useContext(MainContext);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [covidFree, setCovidFree] = useState<boolean | undefined>(false);
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    amplitude.getInstance().logEvent("preview opened");
  }, []);

  useEffect(() => {
    setCovidFree(surveyContext.covidFree);
  }, [surveyContext, user]);

  const onClickStart = useCallback(async () => {
    const value = { covidFree: covidFree === undefined ? false : covidFree };
    dispatchSurveyContext({ type: "UPDATE_COVID_FREE", value });

    const callGetSurveys = async () => {
      try {
        const { data: surveys } = await getSurveys();
        const recentSurveys = surveys
          .filter((survey) => survey.status > 2)
          .sort((sv1, sv2) => {
            if (sv1.updatedAt < sv2.updatedAt) return 1;
            if (sv1.updatedAt > sv2.updatedAt) return -1;
            return sv2.id - sv1.id;
          });
        if (recentSurveys.length > 0) {
          return `/survey/recent?survey_id=${recentSurveys[0].id}`;
        } else {
          return "/survey/step-1-1";
        }
      } catch (e) {
        return "/survey/step-1-1";
      }
    };

    if (
      user &&
      user.mobile &&
      user.accessToken &&
      surveyContext.dateFixed === undefined
    ) {
      const path = await callGetSurveys();
      history.push(path);
    } else {
      history.push("/survey/step-1-1");
    }
  }, [user, covidFree]);
  useEffect(() => {
    callPageTracking();
  }, []);

  return (
    <div className="w-full relative bg-white">
      <div className="px-6 sm:px-9 w-full h-full">
        <div className="w-full h-screen">
          <div className="survey-preview-min-height relative">
            <div className="w-full bg-white relative">
              <SurveyTopBar prevPath="/" />
              <Title
                title={`8가지 질문으로 딱 맞는\n여행일정을 만들어 드릴게요`}
              />
              <div className="mt-1 sm:mt-1.5 flex body-regular-14 items-center text-yoda-green-dark">
                <Circle className="w-5 sm:w-7 mr-1 sm:mr-1.5" />
                현재 서울을 제외한 전국 가능
              </div>
            </div>
            <div className="mt-6 sm:mt-9 bg-yoda-gray-0 rounded sm:rounded-md covid-safe-check-parent w-full mr-10 body-regular-14 flex items-center relative px-3 sm:px-4">
              <button onClick={() => setCovidFree((prev) => !prev)}>
                {covidFree ? (
                  <CheckActive className="question" />
                ) : (
                  <CheckInactive className="question" />
                )}
              </button>
              <div className="underline ml-4">안심여행일정</div>
              <div className="whitespace-pre">으로 추천 받기</div>
              <div className="flex-1"></div>
              <button onClick={() => setShowTooltip((prev) => !prev)}>
                <QuestionMark className="ml-1 question" />
              </button>
              {showTooltip && (
                <button
                  className="absolute tooltip-parent text-left"
                  onClick={() => setShowTooltip(false)}
                >
                  <img
                    className="absolute top-0 left-0 w-full h-full object-fill"
                    src={Tooltip}
                  />
                  <div className="z-10 relative">
                    <div className="body-bold-12-wide text-yoda-black-1">
                      안심여행일정이란?
                    </div>
                    <div className="body-regular-12 text-yoda-black-1">
                      지자체 지정 안심식당, 안심방역숙소와
                      <br />
                      독채펜션, 거리두기가 가능한 관광지들로
                      <br />
                      구성된 일정입니다.
                    </div>
                  </div>
                </button>
              )}
            </div>
          </div>
          <div className="sticky bottom-8 sm:bottom-12 h-14 sm:h-20 w-full">
            <button
              className="h-full w-full bg-yoda-black-1 text-white rounded sm:rounded-md btn-bold-16 font-medium"
              onClick={onClickStart}
            >
              시작하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyPreview;
