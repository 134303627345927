import React, { Dispatch, createContext, useReducer } from "react";

export type Option = {
  valid: boolean;
  value: string;
  admCd?: string;
  rnMgtSn?: string;
  udrtYn?: string;
  buldMnnm?: string;
  buldSlno?: string;
  onClick?: () => void;
};

export type SearchState = {
  option: string;
  options: Option[];
  placeholder: string;
  onChange?: (input: string) => Promise<void> | void;
  navTitle: string;
  errorMsg: string;
};

export type SearchAction =
  | {
      type: "UPDATE_SEARCH_FORM";
      value: {
        options: Option[];
        placeholder: string;
        navTitle: string;
        onChange: (input: string) => void;
        errorMsg: string;
      };
    }
  | { type: "UPDATE_OPTION"; value: string }
  | { type: "UPDATE_SEARCH_OPTIONS"; value: Option[] };

type SearchReducer = React.Reducer<SearchState, SearchAction>;

const initialState: React.ReducerState<SearchReducer> = {
  option: "",
  options: [],
  placeholder: "",
  onChange: () => {},
  navTitle: "",
  errorMsg: "",
};

const reducer: SearchReducer = (state = initialState, action: SearchAction) => {
  switch (action.type) {
    case "UPDATE_SEARCH_FORM":
      return { ...state, ...action.value };
    case "UPDATE_OPTION":
      return { ...state, ...{ option: action.value } };
    case "UPDATE_SEARCH_OPTIONS":
      return { ...state, ...{ options: action.value } };
  }
};

const SearchContext = createContext<[SearchState, Dispatch<SearchAction>]>([
  initialState,
  () => initialState,
]);

const SearchProvider: React.FC<{}> = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchContext, SearchProvider, initialState };
