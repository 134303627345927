import {
  SurveyContext,
  initialState as initialSurveyState,
} from "../../contexts/SurveyContext";
import { SurveysType, deleteSurvey } from "../../api/Survey";
import Trip, { getPeriod } from "./Trip";
import { useContext, useEffect, useState } from "react";

import ClipboardJS from "clipboard";
import EmptyTrip from "./EmptyTrip";
import { ReactComponent as KakaoSquare } from "../../assets/MyPage/kakaoSquare.svg";
import { ReactComponent as LinkSquare } from "../../assets/MyPage/linkSquare.svg";
import { LocalSurveyType } from "../Trip/types";
import { MainContext } from "../../contexts/MainContext";
import useOnclickOutside from "react-cool-onclickoutside";

const Trips = ({
  trips,
  callGetSurveys,
  deleteMode,
}: {
  trips: SurveysType[];
  callGetSurveys: () => Promise<void>;
  deleteMode: boolean;
}) => {
  const [shareSurvey, setShareSurvey] = useState<SurveysType | undefined>();
  const [{ user }, dispatchMainContext] = useContext(MainContext);
  const [, dispatchSurveyContext] = useContext(SurveyContext);

  useEffect(() => {
    const clipboard = new ClipboardJS(".btn");
    // @ts-ignore
    clipboard.on("success", function (e) {
      dispatchMainContext({
        type: "UPDATE_DIALOG",
        value: {
          type: "DialogConfirm",
          content: (
            <div className="flex flex-col items-center rounded-t-md px-8 pt-8 pb-10 bg-white">
              <div className="w-full text-center font-bold">
                샘플 일정표 링크가 복사되었습니다
              </div>
            </div>
          ),
        },
      });
      e.clearSelection();
    });
    return () => clipboard.destroy();
  }, []);
  const onClickKakaoShare = (trip: SurveysType) => {
    // @ts-ignore
    if (!Kakao.isInitialized()) {
      // @ts-ignore
      Kakao.init(process.env.REACT_APP_KAKAO_CLIENT_ID);
    }
    if (user) {
      // @ts-ignore
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: `${user.name}님을 위한 ${getPeriod(trip)} ${
            trip.location
          } 여행 디자인`,
          // @ts-ignore
          imageUrl: trip.mainImageUrl,
          link: {
            mobileWebUrl: `https://old.yodatrip.com/trip/${trip.id}`,
          },
        },
        buttonTitle: "여다에서 보기",
      });
    }
  };
  const closeModals = () => {
    setShareSurvey(undefined);
    dispatchMainContext({
      type: "UPDATE_DIALOG",
      value: {
        type: undefined,
        content: undefined,
      },
    });
  };
  const ref = useOnclickOutside((e) => {
    if (e.type !== "mousedown") {
      return;
    }
    closeModals();
  });
  useEffect(() => {
    if (shareSurvey) {
      dispatchMainContext({
        type: "UPDATE_DIALOG",
        value: {
          type: "Dialog",
          content: (
            <div
              className="absolute bottom-0 left-0 sm:left-44 bg-white max-w-full z-10 rounded-t-3xl p-6 sm:p-9"
              style={{ width: "33.75rem" }}
              ref={ref}
            >
              <div className="btn-bold-16 text-yoda-black-2">공유하기</div>
              <div className="mt-2 sm:mt-3 body-regular-14">
                개인정보 보호를 위해 주문자님의 전화번호를 입력 후<br />
                일정표 열람이 가능합니다
              </div>
              <button
                className="mt-6 sm:mt-9 flex items-center"
                onClick={() => onClickKakaoShare(shareSurvey)}
              >
                <KakaoSquare className="w-8 h-8 sm:w-12 sm:h-12" />
                <div className="body-regular-16 ml-5 sm:ml-7">카카오톡</div>
              </button>
              <button
                className="mt-4 sm:mt-6 flex items-center btn"
                data-clipboard-text={`https://old.yodatrip.com/sample-trip/${shareSurvey.id}`}
              >
                <LinkSquare className="w-8 h-8 sm:w-12 sm:h-12" />
                <div className="body-regular-16 ml-5 sm:ml-7">
                  링크 복사하기
                </div>
              </button>
            </div>
          ),
        },
      });
    }
  }, [shareSurvey]);

  const deleteLocalSurvey = (id: number) => {
    const localSurveys: { survey: LocalSurveyType } | null = JSON.parse(
      localStorage.getItem("survey_trip")!
    );

    if (localSurveys !== null && localSurveys.survey[id]) {
      delete localSurveys.survey[id];
      localStorage.setItem("survey_trip", JSON.stringify(localSurveys));
    }
  };

  const onClickDelete = async (id: number) => {
    try {
      await deleteSurvey(id);
      deleteLocalSurvey(id);
      callGetSurveys();
      closeModals();
      dispatchSurveyContext({
        type: "UPDATE_SURVEY",
        value: initialSurveyState,
      });
    } catch (e) {}
  };
  const showDeleteDialog = (id: number) =>
    dispatchMainContext({
      type: "UPDATE_DIALOG",
      value: {
        type: "Dialog",
        content: (
          <div className="h-full flex justify-center items-center z-10 relative">
            <div
              ref={ref}
              className="h-28 sm:h-40 bg-white px-4 sm:px-6 py-3 sm:py-5 rounded sm:rounded-md w-64 sm:w-96 flex flex-col justify-between"
            >
              <div className="w-full body-regular-14">
                한번 삭제한 일정은 다시 복구할 수 없습니다. 정말로
                삭제하시겠습니까?
              </div>
              <div className="flex justify-end">
                <button onClick={() => onClickDelete(id)}>
                  <div className="text-yoda-error-red btn-bold-14 mr-7 sm:mr-10">
                    삭제
                  </div>
                </button>
                <button onClick={closeModals}>
                  <div className="text-yoda-gray-5 btn-bold-14">취소</div>
                </button>
              </div>
            </div>
          </div>
        ),
      },
    });

  if (trips.length === 0) {
    return <EmptyTrip />;
  }
  return (
    <div className="w-full mt-6 sm:mt-9 pl-2 sm:pl-3 pb-10 sm:pb-16">
      {trips.map((trip) => (
        <Trip
          trip={trip}
          key={trip.id}
          deleteMode={deleteMode}
          setShareSurvey={setShareSurvey}
          showDeleteDialog={showDeleteDialog}
          callGetSurveys={callGetSurveys}
        />
      ))}
    </div>
  );
};

export default Trips;
