import { SurveyContext, SurveyState } from "../contexts/SurveyContext";
import { getSurvey, getSurveys } from "../api/Survey";
import { useCallback, useContext } from "react";

import { MainContext } from "../contexts/MainContext";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

const useAddSurvey = () => {
  const history = useHistory();
  const [{ user }] = useContext(MainContext);
  const [, dispatchSurveyContext] = useContext(SurveyContext);

  const onClickAddSurvey = useCallback(async () => {
    ReactGA.event({
      category: "설문시작",
      action: "survey",
      label: "설문_시작",
    });
    try {
      if (user !== undefined) {
        const { data: surveys } = await getSurveys();
        const sv = surveys.find((survey) => survey.status < 3);
        if (sv) {
          const { data: survey } = await getSurvey(sv.id);
          dispatchSurveyContext({ type: "UPDATE_SURVEY", value: survey });
          history.push(`/survey/${sv.id}/load`);
        } else {
          history.push("/survey/preview");
        }
      } else {
        const sv = localStorage.getItem("survey");
        if (sv) {
          const survey: SurveyState = JSON.parse(sv);
          dispatchSurveyContext({ type: "UPDATE_SURVEY", value: survey });
          history.push("/survey/new/load");
        } else {
          history.push("/survey/preview");
        }
      }
    } catch (e) {
      alert("알 수 없는 에러가 발생하였습니다.");
    }
  }, [user, history]);

  return onClickAddSurvey;
};

export default useAddSurvey;
