import { getInterestDefaultOptions, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import InterestButton from "./Util/InterestButton";
import Loading from "../Loading/Loading";
import { OptionContext } from "../../contexts/OptionContext";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import useDestination from "../../hooks/useDestination";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();
const Survey_3_2 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [{ interestOptions, destActOptions }] = useContext(OptionContext);
  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [interestTypes, setInterestTypes] = useState<string[]>([]);
  const [defaultInterest, setDefaultInterest] = useState("");

  useEffect(() => {
    setInterestTypes(
      getInterestDefaultOptions(surveyContext.interestTypes, interestOptions)
    );
    if (surveyContext.destAct > 0) {
      const destAct = destActOptions.find(
        (act) => act.poiId === surveyContext.destAct
      );
      if (destAct) {
        setDefaultInterest(destAct.value);
      } else if (surveyContext.destFromWeeklyContents) {
        setDefaultInterest(surveyContext.interestPriority[0]);
      }
    }
  }, [surveyContext, interestOptions, destActOptions]);

  const getSelectedInterest = useCallback(() => {
    if (defaultInterest) {
      return [...interestTypes, defaultInterest];
    }

    return interestTypes;
  }, [defaultInterest, interestTypes]);

  const isDispatchable = useCallback(
    () => getSelectedInterest().length > 0,
    [getSelectedInterest]
  );

  const prevPath = useCallback(() => {
    if (
      surveyContext.length === 1 ||
      (surveyContext.from.length > 0 &&
        surveyContext.to.length > 0 &&
        surveyContext.from === surveyContext.to)
    ) {
      if (surveyContext.destType === "anything") {
        return "2-4-4";
      } else {
        return "2-5";
      }
    } else {
      if (surveyContext.accommTypes.length > 0) {
        return "3-1-1";
      } else if (surveyContext.accommAddr.length > 0) {
        return "3-1-2";
      }
    }
  }, [surveyContext]);
  const destination = useDestination("location") || surveyContext.destLocation;

  const dispatch = useCallback(() => {
    dispatchSurveyContext({
      type: "UPDATE_INTEREST_TYPES",
      value: {
        interestTypes: getSelectedInterest(),
      },
    });
    saveSurvey(surveyId, {
      ...surveyContext,
      interestTypes: getSelectedInterest(),
    });
    if (getSelectedInterest().length === 1) {
      const interestPriority = getSelectedInterest();
      const value = {
        interestPriority,
      };
      dispatchSurveyContext({
        type: "UPDATE_INTEREST_PRIORITY",
        value,
      });
      saveSurvey(surveyId, {
        ...surveyContext,
        ...value,
      });
    } else if (defaultInterest.length > 0) {
      const value = {
        interestPriority: [defaultInterest],
      };
      dispatchSurveyContext({
        type: "UPDATE_INTEREST_PRIORITY",
        value,
      });
      saveSurvey(surveyId, {
        ...surveyContext,
        ...value,
      });
    }

    if (destination === undefined) {
      alert("잘못된 접근입니다.");
      history.push("/survey/preview");
    }
  }, [getSelectedInterest, surveyId, surveyContext]);

  const onClickNext = () => {
    dispatch();
    if (getSelectedInterest().length === 1 || defaultInterest.length > 0) {
      history.push(`/survey/${surveyId}/step-4-1`);
    } else {
      history.push(`/survey/${surveyId}/step-3-3`);
    }
  };

  useEffect(() => {
    amplitude.getInstance().logEvent("3-2 interest opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("3-2 interest activated", properties);
    }
  }, [isDispatchable]);

  return (
    <div className="w-full survey-min-height">
      <div className="px-6 sm:px-9">
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-${prevPath()}`} />
      </div>
      <div className="px-6 sm:px-9">
        <SurveyStep step={5} />
        <Title
          title={`${
            surveyContext.destType === "anything"
              ? "관심있는 활동을\n모두"
              : destination !== undefined && destination !== ""
              ? `${destination}에서\n뭘 하고 싶으신가요?\n모두`
              : "관심있는 활동을\n모두"
          } 골라주세요`}
        />
      </div>
      <div className="bg-white pt-6 sm:pt-9 z-10">
        {interestOptions.length === 0 && (
          <div className="bg-white h-full w-full px-5 pt-6 pb-3 sm:pt-10 sm:pb-12 z-10">
            <div className="h-full w-full flex items-center justify-center"></div>
            <div className="pt-9">
              <Loading customStyle={true}>
                <div className="pt-4 sm:pt-6 w-full text-center text-yoda-gray-5 text-base sm:text-2xl tracking-tighter font-medium">
                  잠시만 기다려주세요. <br /> 액티비티를 불러오는 중입니다.
                </div>
              </Loading>
            </div>
          </div>
        )}
        {surveyContext.destType === "anything" ? (
          interestOptions.map((interest, i) => (
            <div key={i} className="w-full mb-7 sm:mb-11">
              <div className="btn-bold-14 text-yoda-black-1 bg-yoda-gray-2 h-7 sm:h-11 flex items-center justify-center mb-2 sm:mb-4">
                {interest.name}
              </div>
              <div className="grid grid-cols-3 px-4 sm:px-6 gap-2 sm:gap-3 ">
                {interest.children.map((ite) => (
                  <InterestButton
                    interest={ite}
                    selected={
                      interestTypes.includes(ite.value) ||
                      interest.value === defaultInterest
                    }
                    onClick={() =>
                      setInterestTypes((prevIntr) => {
                        const index = prevIntr.findIndex(
                          (t) => ite.value === t
                        );
                        if (index > -1) {
                          return prevIntr
                            .slice(0, index)
                            .concat(prevIntr.slice(index + 1));
                        }
                        return prevIntr.concat(ite.value);
                      })
                    }
                    poi={ite.value === defaultInterest}
                  />
                ))}
              </div>
            </div>
          ))
        ) : (
          <>
            {destination !== undefined &&
              destination !== "" &&
              interestOptions.length > 0 && (
                <div className="btn-bold-14 text-yoda-black-1 bg-yoda-gray-2 h-7 sm:h-11 flex items-center justify-center mb-2 sm:mb-4">
                  {`${destination}에서 할 수 있는 것들`}
                </div>
              )}
            <div className="grid grid-cols-3 px-4 sm:px-6 gap-2 sm:gap-3">
              {interestOptions
                .flatMap((it) => it.children)
                .map((interest) => (
                  <InterestButton
                    interest={interest}
                    selected={
                      interestTypes.includes(interest.value) ||
                      interest.value === defaultInterest
                    }
                    onClick={() =>
                      setInterestTypes((prevIntr) => {
                        const index = prevIntr.findIndex(
                          (t) => interest.value === t
                        );
                        if (index > -1) {
                          return prevIntr
                            .slice(0, index)
                            .concat(prevIntr.slice(index + 1));
                        }
                        return prevIntr.concat(interest.value);
                      })
                    }
                    poi={interest.value === defaultInterest}
                  />
                ))}
            </div>
          </>
        )}
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-${prevPath()}`}
      />
    </div>
  );
};

export default Survey_3_2;
