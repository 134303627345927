import { useCallback, useContext, useEffect, useState } from "react";

import { ReactComponent as Arrow } from "../../assets/MyPage/arrow.svg";
import ChannelService from "../../util/channelService";
import Item from "./Item";
import { MainContext } from "../../contexts/MainContext";
import { ReactComponent as ReviewIcon } from "../../assets/MyPage/review.svg";
import { ReactComponent as SupportIcon } from "../../assets/MyPage/support.svg";
import { ReactComponent as TripIcon } from "../../assets/MyPage/trip.svg";
import { getSurveys } from "../../api/Survey";
import { useHistory } from "react-router-dom";

const MyPage = () => {
  const [randomMemberId] = useState(Date.now() + Math.random());
  const [{ user, getMeDone }, dispatch] = useContext(MainContext);
  const history = useHistory();
  const [surveysCount, setSurveysCount] = useState(0);

  useEffect(() => {
    const callGetSurveys = async () => {
      try {
        const { data: surveys } = await getSurveys();
        setSurveysCount(surveys.length);
      } catch (e) {}
    };
    if (user) {
      callGetSurveys();
    }
  }, [user]);
  useEffect(() => {
    if (getMeDone && (!user?.mobile || !user?.termAgreed)) {
      history.push(`/login?redirect_url=/mypage`);
    }
  }, [getMeDone, user]);

  const signoutHandler = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      window.ReactNativeWebView?.postMessage(accessToken + "_LOGOUT");
    }
    localStorage.removeItem("survey_trip");
    localStorage.removeItem("accessToken");

    dispatch({ type: "SIGNOUT" });
    history.push("/login");
  };

  const onClickChannel = useCallback(() => {
    ChannelService.boot({
      pluginKey: "973edbff-b7cb-4b80-8121-97c23a197623", //please fill with your plugin key
      memberId: user?.userId ? user.userId : randomMemberId,
      ...(user && {
        profile: {
          name: user.name, //fill with user name
          mobileNumber: user.mobile, //fill with user phone number
        },
      }),
    });
    ChannelService.showMessenger();
  }, [user, randomMemberId]);

  const getDashedMobile = useCallback(() => {
    if (user === undefined || user.mobile === undefined) return "";
    return `${user.mobile.substring(0, 3)}-${user.mobile.slice(
      3,
      -4
    )}-${user.mobile.substring(user.mobile.length - 4, user.mobile.length)}`;
  }, [user]);

  if (user === undefined) {
    return <div></div>;
  }
  return (
    <div className="w-full sm:shadow-lg bg-white px-5 pt-10 overall-min-height">
      <div className="flex items-center">
        <div className="text-2xl text-black font-bold">
          {user.name}
          <span className="font-normal text-grey">님</span>
        </div>
        <div className="flex-grow"></div>
        <div
          className="cursor-pointer underline text-brownish-grey flex-shrink-0"
          onClick={signoutHandler}
        >
          로그아웃
        </div>
      </div>
      <div className="mt-2.5 text-black font-medium">
        {getDashedMobile()}
        <span className="text-warm-grey">{` 으로 로그인`}</span>
      </div>
      <div className="mt-10 w-full border-t border-white">
        <Item
          Image={TripIcon}
          link="/mytrip"
          name="나의 여행"
          description={surveysCount.toString()}
        />
        <Item
          Image={ReviewIcon}
          link="/mypage/info"
          name="회원 정보"
          description=""
        />
        <button className="w-full" onClick={onClickChannel}>
          <div className="h-12 w-full flex items-center border-b border-white">
            <div className="w-9">
              <SupportIcon className="h-6 w-6" />
            </div>
            <div className="font-medium text-black text-base">고객 센터</div>
            <div className="flex-grow"></div>
            <Arrow className="ml-2.5 h-5" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default MyPage;
