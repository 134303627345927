import { ReactElement } from "react";
import loading from "../../assets/loading.gif";

const Loading = ({
  children,
  customStyle,
}: {
  children: ReactElement;
  customStyle?: boolean;
}) => {
  return (
    <div
      className={`top-0 sm:left-44 max-w-full bg-white overall-padding-bottom h-full  ${
        !customStyle ? "fixed z-10" : null
      }`}
      style={!customStyle ? { zIndex: 99999, width: "33.75rem" } : undefined}
    >
      <div className="w-full h-full flex flex-col items-center justify-center mt-14">
        <div className="w-12 sm:w-16">
          <img className="w-full" src={loading} alt="loading" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Loading;
