import { Link, useHistory } from "react-router-dom";

const Left = ({ color }: { color: string }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 18.4786L2.88638 12L9 5.5214L7.56191 4L0 12L7.56191 20L9 18.4786Z"
      fill={color}
    />
  </svg>
);

const getInt = (num: number) => Math.ceil(num);

const Title = ({ title, scrollPos }: { title: string; scrollPos: number }) => {
  const history = useHistory();
  const getHex = () =>
    getInt((255 * (100 - scrollPos)) / 100)
      .toString(16)
      .padStart(2, "0");
  return (
    <div
      className={`w-full h-12 sm:h-20 survey-nav-bar top-0 left-0 max-w-full ${
        scrollPos === 100 ? "border-b" : ""
      } border-yoda-gray-1`}
      style={{
        backgroundColor: `rgba(255, 255, 255, ${getInt(scrollPos)}%)`,
        backgroundImage: `linear-gradient(to top, transparent 0%, rgba(0, 0, 0, ${
          (100 - getInt(scrollPos)) / 4
        }%) 100%)`,
        position: "fixed",
        width: "33.75rem",
      }}
    >
      <div className="w-full h-full px-6 sm:px-9 flex items-center">
        <button className="w-6 sm:w-9" onClick={() => history.goBack()}>
          <Left color={`#${getHex()}${getHex()}${getHex()}`} />
        </button>
        <div
          className="btn-bold-16 ml-1.5 sm:ml-2.5"
          style={{ color: `#${getHex()}${getHex()}${getHex()}` }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default Title;
