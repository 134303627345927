import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { SearchContext, SearchProvider } from "../../contexts/SearchContext";
import { SurveyContext, SurveyState } from "../../contexts/SurveyContext";
import {
  getDefaultInterestPriority,
  getInterestDefaultOptions,
  isArraysSame,
  routingToSurvey,
  saveSurvey,
} from "../../util";
import {
  getDestOptions,
  getFoodTypes,
  getInterestOptions,
  getSurvey,
} from "../../api/Survey";

import { LocalSurveyType } from "../Trip/types";
import { MainContext } from "../../contexts/MainContext";
import { OptionContext } from "../../contexts/OptionContext";
import RecentSurvey from "./RecentSurvey";
import SURVEY_1_1 from "./Survey_1_1";
import SURVEY_1_1_1 from "./Survey_1_1_1";
import SURVEY_1_1_2 from "./Survey_1_1_2";
import SURVEY_1_2 from "./Survey_1_2";
import SURVEY_2_1 from "./Survey_2_1";
import SURVEY_2_3 from "./Survey_2_3";
import SURVEY_2_4_1 from "./Survey_2_4_1";
import SURVEY_2_4_2 from "./Survey_2_4_2";
import SURVEY_2_4_3 from "./Survey_2_4_3";
import SURVEY_2_4_4 from "./Survey_2_4_4";
import SURVEY_2_4_5 from "./Survey_2_4_5";
import SURVEY_2_5 from "./Survey_2_5";
import SURVEY_3_1_1 from "./Survey_3_1_1";
import SURVEY_3_1_2 from "./Survey_3_1_2";
import SURVEY_3_2 from "./Survey_3_2";
import SURVEY_3_3 from "./Survey_3_3";
import SURVEY_3_4 from "./Survey_3_4";
import SURVEY_3_5 from "./Survey_3_5";
import SURVEY_4_1 from "./Survey_4_1";
import SURVEY_4_2 from "./Survey_4_2";
import SURVEY_5_1 from "./Survey_5_1";
import SURVEY_6_1 from "./Survey_6_1";
import SURVEY_7_1 from "./Survey_7_1";
import SURVEY_Jeju from "./Survey_jeju";
import { Search } from "../Inputs";
import SurveyLoad from "./SurveyLoad";
import SurveyPreview from "./SurveyPreview";
import useHideScrollTop from "../../hooks/useHideScrollTop";

const SurveyDispatchWrapper = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const { surveyId } = useParams<{ surveyId: string }>();
  const [{ user }] = useContext(MainContext);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [, dispatchOptions] = useContext(OptionContext);
  const [, dispatchSearchContext] = useContext(SearchContext);
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    const callGetSurvey = async () => {
      try {
        const localSurvey: SurveyState | null = JSON.parse(
          localStorage.getItem("survey")!
        );
        if (localSurvey !== null) {
          if (user !== undefined) {
            localStorage.removeItem("survey");
          }
          dispatchSurveyContext({ type: "UPDATE_SURVEY", value: localSurvey });
        }
      } catch (e) {
        alert("정상적이지 못한 접근입니다.");
        history.push("/survey/preview");
      }
    };
    if (surveyContext.destFromWeeklyContents !== true) {
      callGetSurvey();
    }
  }, [user, surveyContext.destFromWeeklyContents]);

  useEffect(() => {
    const callGetDestOptions = async () => {
      try {
        const { data: destOptions } = await getDestOptions(
          `from=${surveyContext.from}&to=${surveyContext.to}&from_month=${
            surveyContext.fromMonth
          }&length=${surveyContext.length}&depart_lat=${
            surveyContext.departCoords ? surveyContext.departCoords.lat : ""
          }&depart_lng=${
            surveyContext.departCoords ? surveyContext.departCoords.lng : ""
          }`
        );
        dispatchOptions({
          type: "UPDATE_DEST_OPTIONS",
          value: {
            destCityOptions: destOptions.texts,
            destMapCityOptions: destOptions.cities,
            destFoodOptions: destOptions.foods,
            destActOptions: destOptions.acts,
          },
        });
      } catch {}
    };
    callGetDestOptions();
  }, [
    surveyContext.from,
    surveyContext.to,
    surveyContext.fromMonth,
    surveyContext.length,
    surveyContext.departCoords,
  ]);
  useEffect(() => {
    const callGetInterestOptions = async (params: string) => {
      try {
        dispatchOptions({ type: "UPDATE_INTEREST_OPTIONS", value: [] });
        const { data } = await getInterestOptions(params);

        if (data) {
          dispatchOptions({ type: "UPDATE_INTEREST_OPTIONS", value: data });
          const interestTypes = getInterestDefaultOptions(
            surveyContext.interestTypes,
            data
          );
          if (!isArraysSame(interestTypes, surveyContext.interestTypes)) {
            dispatchSurveyContext({
              type: "UPDATE_INTEREST_TYPES",
              value: { interestTypes },
            });
            saveSurvey(surveyId, { ...surveyContext, interestTypes });
          }

          const interestPriority = getDefaultInterestPriority(
            surveyContext.interestTypes,
            surveyContext.interestPriority,
            data
          );
          const iP = interestPriority ? [interestPriority] : [];
          if (
            !isArraysSame(iP, surveyContext.interestPriority) &&
            !surveyContext.destFromWeeklyContents
          ) {
            dispatchSurveyContext({
              type: "UPDATE_INTEREST_PRIORITY",
              value: {
                interestPriority: iP,
              },
            });
            saveSurvey(surveyId, {
              ...surveyContext,
              interestPriority: iP,
            });
          }
        }
      } catch (e) {}
    };
    let params = "";
    if (surveyContext.destCity.length > 0) {
      params = `destCity=${surveyContext.destCity}`;
    } else if (surveyContext.destName.length > 0) {
      params = `destName=${surveyContext.destName}`;
    } else if (surveyContext.destType !== undefined) {
      if (surveyContext.destFood > 0) {
        params = `destType=${surveyContext.destType}&destFood=${surveyContext.destFood}`;
      } else if (surveyContext.destAct > 0) {
        params = `destType=${surveyContext.destType}&destAct=${surveyContext.destAct}`;
      }
    }

    const timeParam = `&from=${surveyContext.from}&to=${surveyContext.to}&fromMonth=${surveyContext.fromMonth}&length=${surveyContext.length}`;
    if (surveyContext.destType === "anything") {
      callGetInterestOptions(timeParam);
    } else if (params.length > 0) {
      params += timeParam;
      callGetInterestOptions(params);
    }
  }, [
    surveyContext.destName,
    surveyContext.destType,
    surveyContext.destCity,
    surveyContext.destFood,
    surveyContext.destAct,
    surveyContext.destFromWeeklyContents,
    surveyId,
    surveyContext.fromMonth,
    surveyContext.length,
    surveyContext.from,
    surveyContext.to,
  ]);

  useEffect(() => {
    const callGetPreferencesOptions = async () => {
      try {
        dispatchOptions({
          type: "UPDATE_FOOD_PREFERENCE_OPTIONS",
          value: {
            favoriteFoodOptions: [],
            favoriteDrinkOptions: [],
            hateFoodOptions: [],
          },
        });
        const { data: foodTypes } = await getFoodTypes();

        if (foodTypes) {
          dispatchOptions({
            type: "UPDATE_FOOD_PREFERENCE_OPTIONS",
            value: {
              favoriteFoodOptions: foodTypes[0].content,
              favoriteDrinkOptions: foodTypes[1].content,
              hateFoodOptions: foodTypes[2].content,
            },
          });
        }
      } catch (e) {}
    };
    callGetPreferencesOptions();
  }, []);

  useEffect(() => {
    const paddingTop = document.getElementById("padding-top");
    const paddingBottom = document.getElementById("padding-bottom");
    if (paddingTop && paddingBottom) {
      paddingTop.classList.remove("pt-14");
      paddingTop.classList.remove("sm:pt-20");
      paddingBottom.classList.remove("overall-padding-bottom");
    }
    return () => {
      if (paddingTop && paddingBottom) {
        paddingTop.classList.add("pt-14");
        paddingTop.classList.add("sm:pt-20");
        paddingBottom.classList.add("overall-padding-bottom");
      }
    };
  }, []);

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      if (
        (prevLocation.includes("-2-1") ||
          prevLocation.includes("-2-4-5") ||
          prevLocation.includes("-3-1-2")) &&
        !location.pathname.includes("search")
      ) {
        dispatchSearchContext({ type: "UPDATE_OPTION", value: "" });
      }
      setPrevLocation(location.pathname);
    }
  }, [location, prevLocation]);

  return <div>{children}</div>;
};

const Survey = () => {
  useHideScrollTop();
  return (
    <div className="sm:shadow-lg w-full">
      <SearchProvider>
        <Route path="/survey/:surveyId">
          <SurveyDispatchWrapper>
            <Switch>
              <Route path="/survey/:surveyId/load">
                <SurveyLoad />
              </Route>
              <Route path="/survey/preview">
                <SurveyPreview />
              </Route>
              <Route path="/survey/recent">
                <RecentSurvey />
              </Route>
              <Route path="/survey/search">
                <Search />
              </Route>
              <Route path="/survey/step-1-1">
                <SURVEY_1_1 />
              </Route>
              <Route path="/survey/:surveyId/step-1-1-1">
                <SURVEY_1_1_1 />
              </Route>
              <Route path="/survey/:surveyId/step-1-1-2">
                <SURVEY_1_1_2 />
              </Route>
              <Route path="/survey/:surveyId/step-1-2">
                <SURVEY_1_2 />
              </Route>
              <Route path="/survey/:surveyId/step-2-1">
                <SURVEY_2_1 />
              </Route>
              <Route path="/survey/:surveyId/step-2-3">
                <SURVEY_2_3 />
              </Route>
              <Route path="/survey/:surveyId/step-2-4-1">
                <SURVEY_2_4_1 />
              </Route>
              <Route path="/survey/:surveyId/step-2-4-2">
                <SURVEY_2_4_2 />
              </Route>
              <Route path="/survey/:surveyId/step-2-4-3">
                <SURVEY_2_4_3 />
              </Route>
              <Route path="/survey/:surveyId/step-2-4-4">
                <SURVEY_2_4_4 />
              </Route>
              <Route path="/survey/:surveyId/step-2-4-5">
                <SURVEY_2_4_5 />
              </Route>
              <Route path="/survey/:surveyId/step-2-5">
                <SURVEY_2_5 />
              </Route>
              <Route path="/survey/:surveyId/step-3-1-1">
                <SURVEY_3_1_1 />
              </Route>
              <Route path="/survey/:surveyId/step-3-1-2">
                <SURVEY_3_1_2 />
              </Route>
              <Route path="/survey/:surveyId/step-3-2">
                <SURVEY_3_2 />
              </Route>
              <Route path="/survey/:surveyId/step-3-3">
                <SURVEY_3_3 />
              </Route>
              <Route path="/survey/:surveyId/step-3-4">
                <SURVEY_3_4 />
              </Route>
              <Route path="/survey/:surveyId/step-3-5">
                <SURVEY_3_5 />
              </Route>
              <Route path="/survey/:surveyId/step-4-1">
                <SURVEY_4_1 />
              </Route>
              <Route path="/survey/:surveyId/step-4-2">
                <SURVEY_4_2 />
              </Route>
              <Route path="/survey/:surveyId/step-5-1">
                <SURVEY_5_1 />
              </Route>
              <Route path="/survey/:surveyId/step-6-1">
                <SURVEY_6_1 />
              </Route>
              <Route path="/survey/:surveyId/step-7-1">
                <SURVEY_7_1 />
              </Route>
              <Route path="/survey/:surveyId/step-jeju">
                <SURVEY_Jeju />
              </Route>
            </Switch>
          </SurveyDispatchWrapper>
        </Route>
      </SearchProvider>
    </div>
  );
};

export default Survey;
