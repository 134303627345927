export default [
  { location: "서울특별시 종로구", lat: 37.5944232, lng: 126.9718254 },
  { location: "서울특별시 중구", lat: 37.56066717, lng: 126.9956244 },
  { location: "서울특별시 용산구", lat: 37.53650659, lng: 126.9831413 },
  { location: "서울특별시 성동구", lat: 37.55255433, lng: 127.0454496 },
  { location: "서울특별시 광진구", lat: 37.54770647, lng: 127.0874537 },
  { location: "서울특별시 동대문구", lat: 37.58219317, lng: 127.0570334 },
  { location: "서울특별시 중랑구", lat: 37.59915159, lng: 127.0919476 },
  { location: "서울특별시 성북구", lat: 37.60001222, lng: 127.0233474 },
  { location: "서울특별시 강북구", lat: 37.6377415, lng: 127.0143465 },
  { location: "서울특별시 도봉구", lat: 37.66142402, lng: 127.036739 },
  { location: "서울특별시 노원구", lat: 37.64997726, lng: 127.0762823 },
  { location: "서울특별시 은평구", lat: 37.61519389, lng: 126.9257745 },
  { location: "서울특별시 서대문구", lat: 37.57555237, lng: 126.9392646 },
  { location: "서울특별시 마포구", lat: 37.55873348, lng: 126.9086427 },
  { location: "서울특별시 양천구", lat: 37.52184491, lng: 126.858453 },
  { location: "서울특별시 강서구", lat: 37.56156882, lng: 126.83062 },
  { location: "서울특별시 구로구", lat: 37.49875613, lng: 126.8546078 },
  { location: "서울특별시 금천구", lat: 37.46313058, lng: 126.9024672 },
  { location: "서울특별시 영등포구", lat: 37.51787265, lng: 126.9108001 },
  { location: "서울특별시 동작구", lat: 37.50187345, lng: 126.9534757 },
  { location: "서울특별시 관악구", lat: 37.46762772, lng: 126.9468224 },
  { location: "서울특별시 서초구", lat: 37.47411356, lng: 127.0310218 },
  { location: "서울특별시 강남구", lat: 37.49180424, lng: 127.0672524 },
  { location: "서울특별시 송파구", lat: 37.50582526, lng: 127.1182411 },
  { location: "서울특별시 강동구", lat: 37.55079129, lng: 127.1469421 },
  { location: "부산광역시 중구", lat: 35.10404603, lng: 129.0340176 },
  { location: "부산광역시 서구", lat: 35.09618859, lng: 129.0179155 },
  { location: "부산광역시 동구", lat: 35.12698294, lng: 129.046885 },
  { location: "부산광역시 영도구", lat: 35.07204998, lng: 129.0682154 },
  { location: "부산광역시 부산진구", lat: 35.16493135, lng: 129.0462944 },
  { location: "부산광역시 동래구", lat: 35.20648016, lng: 129.0845438 },
  { location: "부산광역시 남구", lat: 35.12063858, lng: 129.0981105 },
  { location: "부산광역시 북구", lat: 35.22776105, lng: 129.0281923 },
  { location: "부산광역시 강서구", lat: 35.14463652, lng: 128.9051896 },
  { location: "부산광역시 해운대구", lat: 35.18553924, lng: 129.1579778 },
  { location: "부산광역시 사하구", lat: 35.08442175, lng: 128.9770561 },
  { location: "부산광역시 금정구", lat: 35.25702045, lng: 129.0931377 },
  { location: "부산광역시 연제구", lat: 35.1844289, lng: 129.0848694 },
  { location: "부산광역시 수영구", lat: 35.16085656, lng: 129.1148442 },
  { location: "부산광역시 사상구", lat: 35.16080759, lng: 128.9928303 },
  { location: "부산광역시 기장군", lat: 35.29863988, lng: 129.2096775 },
  { location: "인천광역시 중구", lat: 37.49253787, lng: 126.552361 },
  { location: "인천광역시 동구", lat: 37.48179851, lng: 126.6357969 },
  { location: "인천광역시 남구", lat: 37.4516177, lng: 126.6680989 },
  { location: "인천광역시 연수구", lat: 37.41982655, lng: 126.6702104 },
  { location: "인천광역시 남동구", lat: 37.43189474, lng: 126.7306051 },
  { location: "인천광역시 부평구", lat: 37.49748823, lng: 126.724224 },
  { location: "인천광역시 계양구", lat: 37.55576599, lng: 126.7394947 },
  { location: "인천광역시 서구", lat: 37.55755195, lng: 126.6771226 },
  { location: "인천광역시 강화군", lat: 37.71005083, lng: 126.4405006 },
  { location: "인천광역시 옹진군", lat: 37.21892594, lng: 126.2863177 },
  { location: "대구광역시 중구", lat: 35.86697891, lng: 128.5958853 },
  { location: "대구광역시 동구", lat: 35.92928072, lng: 128.6836089 },
  { location: "대구광역시 서구", lat: 35.87447038, lng: 128.5510784 },
  { location: "대구광역시 남구", lat: 35.8418242, lng: 128.5870058 },
  { location: "대구광역시 북구", lat: 35.92751718, lng: 128.5803747 },
  { location: "대구광역시 수성구", lat: 35.8346006, lng: 128.6642615 },
  { location: "대구광역시 달서구", lat: 35.8272396, lng: 128.5284111 },
  { location: "대구광역시 달성군", lat: 35.7538305, lng: 128.4993634 },
  { location: "광주광역시 동구", lat: 35.11774294, lng: 126.9518036 },
  { location: "광주광역시 서구", lat: 35.13650411, lng: 126.8549134 },
  { location: "광주광역시 남구", lat: 35.09348268, lng: 126.8597448 },
  { location: "광주광역시 북구", lat: 35.19411649, lng: 126.9270367 },
  { location: "광주광역시 광산구", lat: 35.16343457, lng: 126.7637333 },
  { location: "대전광역시 동구", lat: 36.32611513, lng: 127.4620539 },
  { location: "대전광역시 중구", lat: 36.28566868, lng: 127.4111846 },
  { location: "대전광역시 서구", lat: 36.28722087, lng: 127.3528732 },
  { location: "대전광역시 유성구", lat: 36.37363349, lng: 127.3352357 },
  { location: "대전광역시 대덕구", lat: 36.41102125, lng: 127.4425046 },
  { location: "울산광역시 중구", lat: 35.57019269, lng: 129.3120329 },
  { location: "울산광역시 남구", lat: 35.51512434, lng: 129.3316235 },
  { location: "울산광역시 동구", lat: 35.52387611, lng: 129.4283783 },
  { location: "울산광역시 북구", lat: 35.61112231, lng: 129.3856209 },
  { location: "울산광역시 울주군", lat: 35.53978532, lng: 129.2024874 },
  { location: "세종특별자치시", lat: 36.4957284, lng: 127.2813376 },
  { location: "경기도 가평군", lat: 37.80741548, lng: 127.4449088 },
  { location: "경기도 고양시", lat: 37.66005092, lng: 126.8352438 },
  { location: "경기도 고양시 일산동구", lat: 37.67903377, lng: 126.7963919 },
  { location: "경기도 고양시 일산서구", lat: 37.67958236, lng: 126.7308079 },
  { location: "경기도 과천시", lat: 37.4306182, lng: 127.0031471 },
  { location: "경기도 광명시", lat: 37.44244657, lng: 126.8666822 },
  { location: "경기도 광주시", lat: 37.40612352, lng: 127.3027536 },
  { location: "경기도 구리시", lat: 37.59744838, lng: 127.1335911 },
  { location: "경기도 군포시", lat: 37.34385289, lng: 126.9259396 },
  { location: "경기도 김포시", lat: 37.67779279, lng: 126.6104639 },
  { location: "경기도 남양주시", lat: 37.67776494, lng: 127.237103 },
  { location: "경기도 동두천시", lat: 37.91506781, lng: 127.0753186 },
  { location: "경기도 부천시", lat: 37.50315555, lng: 126.786846 },
  { location: "경기도 성남시", lat: 37.40980358, lng: 127.1192113 },
  { location: "경기도 성남시 분당구", lat: 37.37333511, lng: 127.1110794 },
  { location: "경기도 수원시", lat: 37.27888846, lng: 127.0113255 },
  { location: "경기도 시흥시", lat: 37.38367814, lng: 126.7927784 },
  { location: "경기도 안산시", lat: 37.3187642, lng: 126.8387841 },
  { location: "경기도 안성시", lat: 37.02436819, lng: 127.2837762 },
  { location: "경기도 안양시", lat: 37.40497353, lng: 126.9319156 },
  { location: "경기도 양주시", lat: 37.81280729, lng: 126.9970058 },
  { location: "경기도 양평군", lat: 37.52240704, lng: 127.5728311 },
  { location: "경기도 여주시", lat: 37.29247931, lng: 127.6249991 },
  { location: "경기도 연천군", lat: 38.10106613, lng: 127.0363259 },
  { location: "경기도 오산시", lat: 37.15886328, lng: 127.0550443 },
  { location: "경기도 용인시", lat: 37.21661246, lng: 127.2339218 },
  { location: "경기도 의왕시", lat: 37.36188814, lng: 126.9909075 },
  { location: "경기도 의정부시", lat: 37.73159447, lng: 127.0665045 },
  { location: "경기도 이천시", lat: 37.20175806, lng: 127.4805276 },
  { location: "경기도 파주시", lat: 37.83047357, lng: 126.8177313 },
  { location: "경기도 평택시", lat: 37.01095166, lng: 126.9965743 },
  { location: "경기도 포천시", lat: 37.96074574, lng: 127.2544931 },
  { location: "경기도 하남시", lat: 37.52458481, lng: 127.2100797 },
  { location: "경기도 화성시", lat: 37.18153699, lng: 126.9099312 },
  { location: "강원도 원주시", lat: 37.32681286, lng: 127.9297832 },
  { location: "강원도 춘천시", lat: 37.88536674, lng: 127.7402003 },
  { location: "강원도 강릉시", lat: 37.72697058, lng: 128.8897267 },
  { location: "강원도 동해시", lat: 37.51297159, lng: 129.0763709 },
  { location: "강원도 속초시", lat: 38.19644323, lng: 128.5613216 },
  { location: "강원도 삼척시", lat: 37.30236306, lng: 129.1700683 },
  { location: "강원도 홍천군", lat: 37.74235391, lng: 128.0736001 },
  { location: "강원도 태백시", lat: 37.16286101, lng: 128.990121 },
  { location: "강원도 철원군", lat: 38.25695263, lng: 127.453884 },
  { location: "강원도 횡성군", lat: 37.51396327, lng: 128.1120585 },
  { location: "강원도 평창군", lat: 37.56194833, lng: 128.4703272 },
  { location: "강원도 영월군", lat: 37.18761223, lng: 128.5127659 },
  { location: "강원도 정선군", lat: 37.37694149, lng: 128.740031 },
  { location: "강원도 인제군", lat: 38.06697689, lng: 128.291348 },
  { location: "강원도 고성군", lat: 38.38319335, lng: 128.4203771 },
  { location: "강원도 양양군", lat: 37.99908336, lng: 128.6360406 },
  { location: "강원도 화천군", lat: 38.13872017, lng: 127.713944 },
  { location: "강원도 양구군", lat: 38.20034424, lng: 128.0014828 },
  { location: "충청북도 청주시", lat: 36.63381729, lng: 127.4772268 },
  { location: "충청북도 충주시", lat: 37.03325722, lng: 127.8919217 },
  { location: "충청북도 제천시", lat: 37.0858771, lng: 128.1499738 },
  { location: "충청북도 보은군", lat: 36.4795133, lng: 127.7439794 },
  { location: "충청북도 옥천군", lat: 36.31706866, lng: 127.6579692 },
  { location: "충청북도 영동군", lat: 36.15161505, lng: 127.8206902 },
  { location: "충청북도 증평군", lat: 36.78655741, lng: 127.6009995 },
  { location: "충청북도 진천군", lat: 36.86748007, lng: 127.4457932 },
  { location: "충청북도 괴산군", lat: 36.77529117, lng: 127.8270927 },
  { location: "충청북도 음성군", lat: 36.96972727, lng: 127.6168788 },
  { location: "충청북도 단양군", lat: 36.99189924, lng: 128.4280193 },
  { location: "충청남도 천안시", lat: 36.8062238, lng: 127.2144277 },
  { location: "충청남도 공주시", lat: 36.47930125, lng: 127.0901022 },
  { location: "충청남도 보령시", lat: 36.32605689, lng: 126.6095096 },
  { location: "충청남도 아산시", lat: 36.80037237, lng: 126.9909995 },
  { location: "충청남도 서산시", lat: 36.77264782, lng: 126.4763914 },
  { location: "충청남도 논산시", lat: 36.19287197, lng: 127.1570275 },
  { location: "충청남도 계룡시", lat: 36.28161488, lng: 127.2377967 },
  { location: "충청남도 당진시", lat: 36.89125134, lng: 126.6720356 },
  { location: "충청남도 금산군", lat: 36.11325488, lng: 127.4785411 },
  { location: "충청남도 부여군", lat: 36.23302859, lng: 126.8696954 },
  { location: "충청남도 서천군", lat: 36.10390233, lng: 126.7179332 },
  { location: "충청남도 청양군", lat: 36.44217236, lng: 126.8505268 },
  { location: "충청남도 홍성군", lat: 36.56531277, lng: 126.6356696 },
  { location: "충청남도 예산군", lat: 36.69054061, lng: 126.7944063 },
  { location: "충청남도 태안군", lat: 36.76489389, lng: 126.2579925 },
  { location: "경상북도 포항시", lat: 36.10320231, lng: 129.3220433 },
  { location: "경상북도 경주시", lat: 35.82168196, lng: 129.2566354 },
  { location: "경상북도 김천시", lat: 36.07527016, lng: 128.0820031 },
  { location: "경상북도 안동시", lat: 36.6003213, lng: 128.7795773 },
  { location: "경상북도 구미시", lat: 36.20172955, lng: 128.3595577 },
  { location: "경상북도 영주시", lat: 36.8255615, lng: 128.6096616 },
  { location: "경상북도 영천시", lat: 35.99883396, lng: 128.9504696 },
  { location: "경상북도 상주시", lat: 36.41862776, lng: 128.1168528 },
  { location: "경상북도 문경시", lat: 36.69957935, lng: 128.1435468 },
  { location: "경상북도 경산시", lat: 35.82801867, lng: 128.810035 },
  { location: "경상북도 군위군", lat: 36.15895393, lng: 128.6484247 },
  { location: "경상북도 의성군", lat: 36.36287548, lng: 128.6101963 },
  { location: "경상북도 청송군", lat: 36.34420076, lng: 129.0638673 },
  { location: "경상북도 영양군", lat: 36.69261873, lng: 129.1361724 },
  { location: "경상북도 영덕군", lat: 36.4619412, lng: 129.3377575 },
  { location: "경상북도 청도군", lat: 35.66112984, lng: 128.7908449 },
  { location: "경상북도 고령군", lat: 35.73037612, lng: 128.312213 },
  { location: "경상북도 성주군", lat: 35.90568246, lng: 128.2447451 },
  { location: "경상북도 칠곡군", lat: 36.01329059, lng: 128.4656244 },
  { location: "경상북도 예천군", lat: 36.64304246, lng: 128.4353305 },
  { location: "경상북도 봉화군", lat: 36.92559345, lng: 128.9023631 },
  { location: "경상북도 울진군", lat: 36.89859033, lng: 129.3450484 },
  { location: "경상북도 울릉군", lat: 37.50085148, lng: 130.8609599 },
  { location: "경상남도 창원시", lat: 35.21486491, lng: 128.6552831 },
  { location: "경상남도 김해시", lat: 35.26630992, lng: 128.8488595 },
  { location: "경상남도 진주시", lat: 35.2027859, lng: 128.1334053 },
  { location: "경상남도 양산시", lat: 35.3911186, lng: 129.0410536 },
  { location: "경상남도 거제시", lat: 34.86862342, lng: 128.6336688 },
  { location: "경상남도 통영시", lat: 34.8940415, lng: 128.3976144 },
  { location: "경상남도 사천시", lat: 35.04661963, lng: 128.0786931 },
  { location: "경상남도 밀양시", lat: 35.4988106, lng: 128.8039111 },
  { location: "경상남도 함안군", lat: 35.28935442, lng: 128.4316959 },
  { location: "경상남도 거창군", lat: 35.72331904, lng: 127.9140715 },
  { location: "경상남도 창녕군", lat: 35.51274129, lng: 128.5092563 },
  { location: "경상남도 고성군", lat: 35.01951641, lng: 128.2914515 },
  { location: "경상남도 하동군", lat: 35.14359782, lng: 127.7972841 },
  { location: "경상남도 합천군", lat: 35.57099187, lng: 128.1444744 },
  { location: "경상남도 남해군", lat: 34.79403972, lng: 127.9221391 },
  { location: "경상남도 함양군", lat: 35.55447802, lng: 127.7362629 },
  { location: "경상남도 산청군", lat: 35.35902934, lng: 127.8994277 },
  { location: "경상남도 의령군", lat: 35.38862708, lng: 128.2707736 },
  { location: "전라북도 전주시", lat: 35.82763442, lng: 127.1282996 },
  { location: "전라북도 익산시", lat: 36.00283501, lng: 126.9997781 },
  { location: "전라북도 군산시", lat: 35.94937433, lng: 126.7560351 },
  { location: "전라북도 정읍시", lat: 35.60526043, lng: 126.9022325 },
  { location: "전라북도 완주군", lat: 35.91646598, lng: 127.2151268 },
  { location: "전라북도 김제시", lat: 35.80201766, lng: 126.9071565 },
  { location: "전라북도 남원시", lat: 35.43277617, lng: 127.4146664 },
  { location: "전라북도 고창군", lat: 35.44528587, lng: 126.6178004 },
  { location: "전라북도 부안군", lat: 35.69161324, lng: 126.6810166 },
  { location: "전라북도 임실군", lat: 35.5937602, lng: 127.2508206 },
  { location: "전라북도 순창군", lat: 35.43009223, lng: 127.0969296 },
  { location: "전라북도 진안군", lat: 35.8458304, lng: 127.4396356 },
  { location: "전라북도 장수군", lat: 35.65056346, lng: 127.5474198 },
  { location: "전라북도 무주군", lat: 35.94036065, lng: 127.7396342 },
  { location: "전라남도 여수시", lat: 34.7745278, lng: 127.6587669 },
  { location: "전라남도 순천시", lat: 34.99194875, lng: 127.4026584 },
  { location: "전라남도 목포시", lat: 34.8124146, lng: 126.4066504 },
  { location: "전라남도 광양시", lat: 35.03957511, lng: 127.6679917 },
  { location: "전라남도 나주시", lat: 35.00765698, lng: 126.7355109 },
  { location: "전라남도 무안군", lat: 34.92747203, lng: 126.466958 },
  { location: "전라남도 해남군", lat: 34.54897574, lng: 126.5961383 },
  { location: "전라남도 고흥군", lat: 34.58420669, lng: 127.3281444 },
  { location: "전라남도 화순군", lat: 34.99351781, lng: 127.0280015 },
  { location: "전라남도 영암군", lat: 34.80714095, lng: 126.6615666 },
  { location: "전라남도 영광군", lat: 35.27409341, lng: 126.4761277 },
  { location: "전라남도 완도군", lat: 34.2028409, lng: 126.4937476 },
  { location: "전라남도 담양군", lat: 35.290906, lng: 126.844415 },
  { location: "전라남도 장성군", lat: 35.3398712, lng: 126.6150155 },
  { location: "전라남도 보성군", lat: 34.8033642, lng: 127.1010099 },
  { location: "전라남도 신안군", lat: 34.6268065, lng: 125.15666 },
  { location: "전라남도 장흥군", lat: 34.6453338, lng: 126.7901598 },
  { location: "전라남도 강진군", lat: 34.6096495, lng: 126.6284468 },
  { location: "전라남도 함평군", lat: 35.1043899, lng: 126.4462396 },
  { location: "전라남도 진도군", lat: 34.3561177, lng: 125.9688853 },
  { location: "전라남도 곡성군", lat: 35.2023419, lng: 127.1774734 },
  { location: "전라남도 구례군", lat: 35.2322313, lng: 127.355754 },
  { location: "제주특별자치도 제주시", lat: 33.5061867, lng: 126.4934276 },
  { location: "제주특별자치도 애월읍", lat: 33.4625991, lng: 126.3299226 },
  { location: "제주특별자치도 한림읍", lat: 33.4108502, lng: 126.2659976 },
  { location: "제주특별자치도 한경면", lat: 33.3502255, lng: 126.1839167 },
  { location: "제주특별자치도 대정읍", lat: 33.2266661, lng: 126.252471 },
  { location: "제주특별자치도 안덕면", lat: 33.2573794, lng: 126.3305924 },
  { location: "제주특별자치도 서귀포시", lat: 33.2540356, lng: 126.5603748 },
  { location: "제주특별자치도 남원읍", lat: 33.2799116, lng: 126.7205805 },
  { location: "제주특별자치도 표선면", lat: 33.3262164, lng: 126.8322722 },
  { location: "제주특별자치도 성산읍", lat: 33.4424227, lng: 126.9112283 },
  { location: "제주특별자치도 구좌읍", lat: 33.5254977, lng: 126.8549505 },
  { location: "제주특별자치도 조천읍", lat: 33.534892, lng: 126.6340676 },
];
