import { useCallback, useContext } from "react";

import { OptionContext } from "../contexts/OptionContext";
import { SurveyContext } from "../contexts/SurveyContext";

const useDestination = (val: "location" | "name" | "description") => {
  const [ctx] = useContext(SurveyContext);
  const [optionContext] = useContext(OptionContext);

  const destination = useCallback(() => {
    const destFood = optionContext.destFoodOptions.find(
      (op) => op.poiId === ctx.destFood
    );
    const destAct = optionContext.destActOptions.find(
      (op) => op.poiId === ctx.destAct
    );

    if (
      ctx.destName === "" &&
      ctx.destCity === "" &&
      ctx.destType === undefined &&
      (ctx.destFood === 0 || destFood === undefined) &&
      (ctx.destAct === 0 || destAct === undefined)
    ) {
      return undefined;
    }
    if (val === "location") {
      if (ctx.destName !== "") return ctx.destName;
      else if (ctx.destCity !== "") return ctx.destCity;
      else if (destFood) return destFood.location;
      else if (destAct) return destAct.location;
      else if (ctx.destType === "anything") return "여다가 정해주세요!";
    }

    if (val === "name") {
      if (ctx.destName !== "") return ctx.destName;
      else if (ctx.destCity !== "") return ctx.destCity;
      else if (destFood) return destFood.name;
      else if (destAct) return destAct.name;
      else if (ctx.destType === "anything") return "여다가 정해주세요!";
    }

    if (val === "description") {
      if (ctx.destName !== "") return ctx.destName;
      else if (ctx.destCity !== "") return ctx.destCity;
      else if (destFood) return destFood.description;
      else if (destAct) return destAct.description;
      else if (ctx.destType === "anything") return "여다가 정해주세요!";
    }
    return "";
  }, [ctx, optionContext, val]);

  return destination();
};

export default useDestination;
