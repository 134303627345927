import {
  InterestType,
  transTimeOptions,
  transportOptions,
} from "../../util/surveyOptions";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { OptionContext } from "../../contexts/OptionContext";
import Picture from "../General/Picture";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { getDestCandidates } from "../../api/Survey";
import { saveSurvey } from "../../util";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_3_4 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [{ interestOptions }] = useContext(OptionContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [candidates, setCandidates] = useState<
    {
      destLimit: string;
      destName: string;
      interestImages: {
        imageUrl: string;
        imageUrlHigh: string;
        imageUrlMedium: string;
      }[];
    }[]
  >([]);
  const [destName, setDestName] = useState("");

  useEffect(() => {
    setDestName(surveyContext.destName);
  }, [surveyContext.destName]);
  const isDispatchable = useCallback(() => destName.length > 0, [destName]);
  const dispatch = useCallback(() => {
    const value = {
      destName,
      destDetermined: false,
      destType: "anything" as const,
      destCity: "",
      destAct: 0,
      destFood: 0,
    };
    dispatchSurveyContext({ type: "UPDATE_DESTINATION", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [destName, surveyId, surveyContext]);

  useEffect(() => {
    const isDriving = _.isEqual(
      transportOptions[0].value,
      surveyContext.transportTypes
    );
    const callDestGetCandidates = async () => {
      try {
        const params =
          surveyContext.interestTypes
            .map((type) => `&interest_types[]=${type}`)
            .join("") +
          "&interest_priority=" +
          surveyContext.interestPriority +
          "&transport_type=" +
          (isDriving ? "driving" : "bus") +
          surveyContext.transportTimes
            .map((time) => `&transport_times[]=${time}`)
            .join("") +
          `&depart_lat=${
            surveyContext.departCoords ? surveyContext.departCoords.lat : ""
          }&depart_lng=${
            surveyContext.departCoords ? surveyContext.departCoords.lng : ""
          }&covid_free=${
            surveyContext.covidFree === undefined
              ? false
              : surveyContext.covidFree
          }`;
        const { data } = await getDestCandidates(params);
        const childrenOptions: InterestType[] = [];
        interestOptions.forEach((op) => childrenOptions.push(...op.children));
        const _candidates = data.candidates.map((candid) => {
          const interestImages: {
            imageUrl: string;
            imageUrlHigh: string;
            imageUrlMedium: string;
          }[] = candid.interestMatches
            .map((match) => {
              const interest = childrenOptions.find((op) => op.value === match);
              if (interest === undefined) {
                return undefined;
              }
              return {
                imageUrl: interest.imageUrl,
                imageUrlMedium: interest.imageUrlMedium,
                imageUrlHigh: interest.imageUrlHigh,
              };
            })
            .filter(
              (
                image
              ): image is {
                imageUrl: string;
                imageUrlHigh: string;
                imageUrlMedium: string;
              } => image !== undefined
            );

          const destLimit =
            transTimeOptions.find(
              (op) =>
                op.value ===
                (isDriving ? candid.driveDistLimit : candid.transitDistLimit)
            )?.name || "";

          return {
            destLimit,
            destName: candid.keywords,
            interestImages,
          };
        });
        setCandidates(_candidates);
      } catch (e) {}
    };
    callDestGetCandidates();
  }, [surveyContext, interestOptions]);

  useEffect(() => {
    amplitude.getInstance().logEvent("3-4 three cities opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude
        .getInstance()
        .logEvent("3-4 three cities activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-3-5`);
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-4-2`} />
      <div>
        <SurveyStep step={7} />
        <Title title={`아래의 지역을 추천합니다\n하나를 골라주세요`} />
      </div>
      <div className="mt-6 sm:mt-9">
        {candidates.map((candid) => (
          <div key={candid.destName} className="w-full pb-3 sm:pb-6">
            <button
              className={`w-full h-12 sm:h-18  rounded sm:rounded-md btn-bold-16 ${
                destName === candid.destName
                  ? "bg-yoda-primary text-white"
                  : "bg-yoda-gray-2 text-yoda-gray-4"
              }`}
              onClick={() => setDestName(candid.destName)}
            >
              {candid.destName}
            </button>
            <div className="ml-1 sm:ml-1">
              <div className="mt-2 sm:mt-3 body-regular-14 text-yoda-black-3">
                {`${
                  _.isEqual(
                    transportOptions[0].value,
                    surveyContext.transportTypes
                  )
                    ? "자동차"
                    : "대중교통"
                } ${candid.destLimit}`}
              </div>
              <div className="flex flex-wrap">
                {candid.interestImages.map((image) => (
                  <Picture
                    key={image.imageUrl}
                    Low={image.imageUrl}
                    Medium={image.imageUrlMedium}
                    High={image.imageUrlHigh}
                    className="w-6 sm:w-9 mr-2 sm:mr-2.5 mt-1.5 sm:mt-2.5"
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-4-2`}
      />
    </div>
  );
};

export default Survey_3_4;
