import React, { useCallback, useContext, useEffect, useState } from "react";
import { updateMe } from "../../api/Accounts";
import { MainContext } from "../../contexts/MainContext";
import { defaultOptionState, getDefaultOption, getTwoDigits } from "../../util";
import { Dropdown } from "../Inputs";

const genderOptions = [
  { name: "남자", value: 1 },
  { name: "여자", value: 2 },
];
const yearOptions = Array.from(Array(80).keys()).map((number) => {
  const year = 1930 + number;
  return {
    name: year + "년",
    value: year,
  };
});
const monthOptions = Array.from(Array(12).keys()).map((number) => {
  const month = 1 + number;
  return {
    name: month + "월",
    value: month,
  };
});
const dayOptions = Array.from(Array(31).keys()).map((number) => {
  const day = 1 + number;
  return {
    name: day + "일",
    value: day,
  };
});

const Info = () => {
  const [{ user }, dispatchMainContext] = useContext(MainContext);
  const [name, setName] = useState<string | undefined>();
  const [gender, setGender] = useState(defaultOptionState);
  const [year, setYear] = useState(defaultOptionState);
  const [month, setMonth] = useState(defaultOptionState);
  const [day, setDay] = useState(defaultOptionState);

  useEffect(() => {
    setName(user?.name);
    setGender(getDefaultOption(genderOptions, user?.gender ? user.gender : 0));
    setYear(
      getDefaultOption(
        yearOptions,
        user?.birthdate ? parseInt(user.birthdate.split("-")[0]) : 0
      )
    );
    setMonth(
      getDefaultOption(
        monthOptions,
        user?.birthdate ? parseInt(user.birthdate.split("-")[1]) : 0
      )
    );
    setDay(
      getDefaultOption(
        dayOptions,
        user?.birthdate ? parseInt(user.birthdate.split("-")[2]) : 0
      )
    );
  }, [user]);

  const validateInfo = useCallback(() => {
    return (
      name &&
      name.length > 0 &&
      gender.value > 0 &&
      year.value > 0 &&
      month.value > 0 &&
      day.value > 0
    );
  }, [name, gender, year, month, day]);

  const callUpdateMe = useCallback(async () => {
    if (!validateInfo()) {
      return;
    }
    try {
      const birthdate = `${year.value}-${getTwoDigits(
        month.value as number
      )}-${getTwoDigits(day.value as number)}`;
      await updateMe({
        birthdate,
        gender: gender.value as number,
        name: name || "",
      });
      dispatchMainContext({
        type: "UPDATE_USER",
        value: {
          birthdate,
          gender: gender.value as number,
          name,
        },
      });
      alert("성공적으로 회원정보를 저장했습니다");
    } catch (e) {}
  }, [validateInfo, year, month, day, gender, name]);

  const onClickDeactivate = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    dispatchMainContext({
      type: "UPDATE_DIALOG",
      value: {
        type: "DialogConfirm",
        content: (
          <div className="w-96 max-w-full rounded-t-md bg-white">
            <div className="w-full px-5 sm:px-12 pt-6 pb-8 text-center text-lg font-bold">
              고객센터로 문의주시면
              <br />
              탈퇴를 도와드리겠습니다.
            </div>
          </div>
        ),
      },
    });
  };

  return (
    <div className="w-full bg-white px-5 pt-10 pb-12">
      <div className="w-full text-center font-bold text-4xl">회원정보</div>
      <div className="mt-10">
        <div className="text-lg font-medium">이름</div>
        <input
          type="text"
          className="mt-5 px-5 w-full h-14 font-medium outline-none rounded border border-gray-200 focus:border-black focus:bg-gray-100"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
      </div>
      <div className="mt-10">
        <div className="text-lg font-medium">휴대전화</div>
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          className="mt-5 px-5 w-full h-14 outline-none rounded border border-gray-200 font-medium focus:border-black focus:bg-gray-100"
          value={user?.mobile || ""}
          disabled={true}
        ></input>
      </div>
      <div className="mt-10">
        <div className="text-lg font-medium">성별</div>
        <div className="mt-5">
          <div className="w-full">
            <Dropdown
              useOption={[gender, setGender]}
              options={genderOptions}
              placeholder={{ name: "성별", value: 0 }}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="text-lg font-medium">생년월일</div>
        <div className="mt-5">
          <div className="flex-1 pr-5">
            <Dropdown
              useOption={[year, setYear]}
              options={yearOptions}
              placeholder={{ name: "년", value: 0 }}
            />
          </div>
          <div className="flex-1 mt-2.5">
            <Dropdown
              useOption={[month, setMonth]}
              options={monthOptions}
              placeholder={{ name: "월", value: 0 }}
            />
          </div>
          <div className="flex-1 mt-2.5">
            <Dropdown
              useOption={[day, setDay]}
              options={dayOptions}
              placeholder={{ name: "일", value: 0 }}
            />
          </div>
        </div>
      </div>
      <div className="mt-14">
        <button
          className={`w-full h-16 rounded text-lg font-medium ${
            validateInfo()
              ? "bg-yoda-primary text-white"
              : "bg-gray-200 text-grey"
          }`}
          onClick={callUpdateMe}
        >
          저장
        </button>
      </div>
      <div className="mt-5 w-full flex pb-7">
        <div className="flex-grow"></div>
        <a href="/#" onClick={onClickDeactivate} className="text-greyish">
          서비스 탈퇴
        </a>
      </div>
    </div>
  );
};

export default Info;
