import "react-nice-dates/build/style.css";

import { SurveyContext, SurveyState } from "../../contexts/SurveyContext";
import { createSurvey, getSurvey, getSurveys } from "../../api/Survey";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { MainContext } from "../../contexts/MainContext";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_1_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [{ user }] = useContext(MainContext);

  const history = useHistory();
  const [isDateFixed, setIsDateFixed] = useState<boolean | undefined>();

  useEffect(() => {
    setIsDateFixed(surveyContext.dateFixed);
  }, [surveyContext.dateFixed]);

  const isDispatchable = useCallback(
    () => isDateFixed !== undefined,
    [isDateFixed]
  );

  useEffect(() => {
    if (surveyContext.covidFree === undefined) {
      history.replace("/survey/preview");
    }
  }, [surveyContext]);

  useEffect(() => {
    amplitude.getInstance().logEvent("1-1 when opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("1-1 when activated", properties);
    }
  }, [isDispatchable]);

  const getSurveyId = useCallback(async () => {
    try {
      if (user !== undefined) {
        const { data: surveys } = await getSurveys();
        const sv = surveys.find((survey) => survey.status < 3);
        if (sv) {
          const { data: survey } = await getSurvey(sv.id);
          return {
            surveyId: String(sv.id),
            survey: survey,
          };
        } else {
          const {
            data: { id },
          } = await createSurvey();
          return { surveyId: String(id), survey: surveyContext };
        }
      } else {
        const localSurvey = localStorage.getItem("survey");
        if (localSurvey) {
          const survey: SurveyState = JSON.parse(localSurvey);
          return { surveyId: "new", survey: survey };
        }
        return { surveyId: "new", survey: surveyContext };
      }
    } catch (e) {
      return { surveyId: undefined, survey: surveyContext };
    }
  }, [surveyContext, isDateFixed, user]);

  const dispatch = useCallback(
    ({ surveyId, survey }: { surveyId: string; survey: SurveyState }) => {
      dispatchSurveyContext({
        type: "UPDATE_SURVEY",
        value: survey,
      });
      dispatchSurveyContext({
        type: "UPDATE_SURVEY_ID",
        value: { surveyId: surveyId },
      });
      dispatchSurveyContext({
        type: "UPDATE_DATE_FIXED",
        value: isDateFixed!,
      });
      saveSurvey(surveyId, {
        ...survey,
        ...{ surveyId: surveyId, dateFixed: isDateFixed },
      });
    },
    [surveyContext, getSurveyId, isDateFixed]
  );

  const onClickNext = useCallback(async () => {
    let surveyId = surveyContext.surveyId;
    if (surveyId === undefined) {
      const surveyIdObj = await getSurveyId();
      if (surveyIdObj.surveyId !== undefined) {
        dispatch(surveyIdObj);
        surveyId = surveyIdObj.surveyId;
      }
    }
    if (isDateFixed) {
      history.push(`/survey/${surveyId}/step-1-1-1`);
    } else {
      history.push(`/survey/${surveyId}/step-1-1-2`);
    }
  }, [history, getSurveyId, dispatch, isDateFixed, surveyContext.surveyId]);

  return (
    <div className="w-full survey-min-height">
      <div className="px-6 sm:px-9">
        <SurveyTopBar prevPath="/survey/preview" />
        <div>
          <SurveyStep step={1} />
          <Title title="여행 날짜를 정하셨나요?" />
          <div className="mt-6 sm:mt-9 w-full h-12 sm:h-18 flex">
            <button
              className={`flex-1 h-full rounded sm:rounded-md mr-1 sm:mr-1.5 ${
                isDateFixed === true
                  ? "bg-yoda-primary text-white"
                  : "bg-yoda-gray-2 text-yoda-gray-4"
              } btn-bold-16`}
              onClick={() => {
                setIsDateFixed(true);
              }}
            >
              네
            </button>
            <button
              className={`flex-1 h-full rounded sm:rounded-md ml-1 sm:ml-1.5 ${
                isDateFixed === false
                  ? "bg-yoda-primary text-white"
                  : "bg-yoda-gray-2 text-yoda-gray-4"
              } btn-bold-16`}
              onClick={() => {
                setIsDateFixed(false);
              }}
            >
              아니요
            </button>
          </div>
        </div>
        <SurveyBottomBar
          onClickNext={onClickNext}
          isDispatchable={isDispatchable()}
          prevPath="/survey/preview"
        />
      </div>
    </div>
  );
};

export default Survey_1_1;
