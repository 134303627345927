const Title = ({ title }: { title: string }) => {
  return (
    <div className="w-full flex items-center">
      <div className="heading-bold-24 text-left break-words whitespace-pre-line leading-normal">
        {title}
      </div>
    </div>
  );
};

export default Title;
