import React, { SVGProps } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/MyPage/arrow.svg";

const Item = ({
  link,
  Image,
  name,
  description,
}: {
  link: string;
  Image: React.FC<SVGProps<SVGSVGElement>>;
  name: string;
  description: string;
}) => {
  return (
    <Link to={link}>
      <div className="h-12 flex items-center border-b border-white">
        <div className="w-9">
          <Image className="h-6 w-6" />
        </div>
        <div className="font-medium text-black text-base">{name}</div>
        <div className="flex-grow"></div>
        <div>{description}</div>
        <Arrow className="ml-2.5 h-5" />
      </div>
    </Link>
  );
};

export default Item;
