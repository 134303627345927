import "react-nice-dates/build/style.css";

import { Dropdown, Title } from "../Inputs";
import { defaultOptionState, getDefaultOption, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import { ReactComponent as Notice } from "../../assets/Survey/notice.svg";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import amplitude from "amplitude-js";
import { v4 as uuidv4 } from "uuid";

const monthOptions = [
  { name: "1월", value: 1 },
  { name: "2월", value: 2 },
  { name: "3월", value: 3 },
  { name: "4월", value: 4 },
  { name: "5월", value: 5 },
  { name: "6월", value: 6 },
  { name: "7월", value: 7 },
  { name: "8월", value: 8 },
  { name: "9월", value: 9 },
  { name: "10월", value: 10 },
  { name: "11월", value: 11 },
  { name: "12월", value: 12 },
];
const daysOptions = [
  { name: "당일치기", value: 1 },
  { name: "1박2일", value: 2 },
  { name: "2박3일", value: 3 },
];
const uuid = uuidv4();

const getCurrentSortedMonthOptions = (
  options: { name: string; value: number }[]
) => {
  const date = new Date();
  const previousMonth = date.getMonth() + 1;
  const currentIndex = options.findIndex((el) => el.value === previousMonth);

  return [...options.slice(currentIndex), ...options.slice(0, currentIndex)];
};

const Survey_1_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [month, setMonth] = useState(defaultOptionState);
  const [days, setDays] = useState(defaultOptionState);

  useEffect(() => {
    setMonth(getDefaultOption(monthOptions, surveyContext.fromMonth));
    setDays(getDefaultOption(daysOptions, surveyContext.length));
  }, [surveyContext.fromMonth, surveyContext.length]);

  const isDispatchable = useCallback(
    () => month.value !== 0 && days.value !== 0,
    [month, days]
  );

  const dispatch = useCallback(() => {
    const value = {
      from: "",
      to: "",
      fromMonth: month.value as number,
      length: days.value as number,
    };
    dispatchSurveyContext({ type: "UPDATE_DATE", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [month, days, surveyId, surveyContext]);
  useEffect(() => {
    amplitude.getInstance().logEvent("1-1-2 when dropdown opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude
        .getInstance()
        .logEvent("1-1-2 when dropdown activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-1-2`);
  };

  return (
    <div className="w-full survey-min-height">
      <div className="px-6 sm:px-9">
        <div>
          <SurveyTopBar prevPath="/survey/step-1-1" />
          <SurveyStep step={1} />
        </div>
        <Title title="언제쯤 가세요?" />
        <div className="w-full flex items-center mt-1.5 sm:mt-2.5">
          <Notice className="notice-icon" />
          <div className="ml-1.5 sm:ml-2.5 body-regular-14 text-yoda-green-dark">
            현재 2박 3일까지 선택 가능
          </div>
        </div>
        <div className="mt-6 sm:mt-9">
          <div className="flex items-center">
            <Dropdown
              useOption={[month, setMonth]}
              options={getCurrentSortedMonthOptions(monthOptions)}
              placeholder={{ name: "월", value: 0 }}
              className="flex-1"
            />
            <div className="ml-2 sm:ml-3"></div>
            <Dropdown
              useOption={[days, setDays]}
              options={daysOptions}
              placeholder={{ name: "기간", value: 0 }}
              className="flex-1"
            />
          </div>
          <div className="flex-1 flex items-end mt-6 sm:mt-9 text-yoda-primary">
            <div className="flex-grow"></div>
            {isDispatchable() && (
              <div className="btn-bold-21" style={{ lineHeight: 1 }}>
                {month.name}
              </div>
            )}
            {isDispatchable() && (
              <div
                className="btn-bold-16 ml-2 sm:ml-3"
                style={{ lineHeight: 1 }}
              >
                {days.name}
              </div>
            )}
            {isDispatchable() && (
              <button
                className="w-6 sm:w-9 ml-2.5 sm:ml-3.5"
                onClick={() => {
                  setMonth(defaultOptionState);
                  setDays(defaultOptionState);
                }}
              >
                <ClearIcon />
              </button>
            )}
          </div>
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath="/survey/step-1-1"
      />
    </div>
  );
};

export default Survey_1_1;
