import { getTwoDigits, saveSurvey } from "../../util";
import {
  mobileSigninConfirm,
  mobileSigninRequest,
  updateMe,
} from "../../api/Accounts";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as CheckIcon } from "../../assets/Login/check.svg";
import { ReactComponent as ErrorIcon } from "../../assets/Login/error.svg";
import { MainContext } from "../../contexts/MainContext";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { callSignupTracking } from "../../util/tracking";
import { createSurvey } from "../../api/Survey";
import useHideScrollTop from "../../hooks/useHideScrollTop";
import useInterval from "../../hooks/useInterval";
import usePayable from "../../hooks/usePayable";

const Survey_7_1 = () => {
  const codeRef = useRef<HTMLInputElement | null>(null);
  const { surveyId } = useParams<{ surveyId: string }>();
  const [{ user }, dispatch] = useContext(MainContext);
  const history = useHistory();
  const isPayable = usePayable();
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [showCode, setShowCode] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [showName, setShowName] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    const redirectToSurvey = async () => {
      if (!isPayable) {
        history.push("/");
      } else {
        const redirectUrl = `/survey/${surveyId}/step-6-1?paymentcomplete=true`;
        const localSurvey = localStorage.getItem("survey");
        if (redirectUrl.includes("/new/") && localSurvey) {
          const response = await createSurvey();
          await saveSurvey(
            response.data.id.toString(),
            JSON.parse(localSurvey)
          );
          const idRedirectUrl = redirectUrl.replace(
            "/new/",
            `/${response.data.id.toString()}/`
          );
          localStorage.removeItem("survey");
          history.push(
            showName
              ? `/signup/success?redirect_url=${idRedirectUrl}`
              : idRedirectUrl
          );
        } else if (!isNaN(parseInt(surveyId))) {
          history.push(
            showName
              ? `/signup/success?redirect_url=${redirectUrl}`
              : redirectUrl
          );
        }
      }
    };
    if (user && user.name && user.mobile && user.termAgreed) {
      redirectToSurvey();
    }
  }, [user, isPayable, surveyId]);
  useEffect(() => {
    amplitude.getInstance().logEvent("7-1 login opened");
  }, []);
  useHideScrollTop();

  const callMobileSigninRequest = useCallback(async () => {
    try {
      await mobileSigninRequest(mobile);
      setMobileError(false);
      setShowCode(true);
      setSeconds(180);
      setCode("");
      codeRef.current?.focus();
      dispatch({
        type: "UPDATE_DIALOG",
        value: {
          type: "DialogError",
          content: "인증번호가 발송되었습니다",
        },
      });
    } catch (e) {
      setMobileError(true);
    }
  }, [mobile]);
  useInterval(() => setSeconds(seconds - 1), seconds > 0 ? 1000 : null);

  const callMobileSigninConfirm = useCallback(async () => {
    try {
      const { data } = await mobileSigninConfirm(mobile, code);
      localStorage.setItem("accessToken", data.accessToken);
      if (data.userInfo) {
        dispatch({
          type: "UPDATE_USER",
          value: {
            accessToken: data.accessToken,
            birthdate: data.userInfo.birthdate,
            gender: data.userInfo.gender,
            mobile: data.userInfo.mobile,
            name: data.userInfo.name,
            termAgreed: data.userInfo.termAgreed,
            userId: data.userInfo.userId,
          },
        });
      } else {
        setCodeError(false);
        setShowName(true);
      }
    } catch (e) {
      setCodeError(true);
    }
  }, [mobile, code]);

  const isMobileOkay = useCallback(() => {
    return mobile.length > 9 && mobile.length < 12;
  }, [mobile]);
  const isCodeOkay = useCallback(() => {
    return code.length === 6 && seconds > 0;
  }, [code, seconds]);
  const isButtonEnabled = useCallback(() => {
    if (
      (!showCode && !showName && isMobileOkay()) ||
      (showCode && !showName && isMobileOkay() && isCodeOkay()) ||
      (showCode && showName && name.length > 0)
    ) {
      return true;
    }
    return false;
  }, [isMobileOkay, isCodeOkay, showCode, name, showName]);
  const onButtonClick = useCallback(async () => {
    if (!showCode && !showName && isMobileOkay()) {
      callMobileSigninRequest();
    } else if (showCode && !showName && isMobileOkay() && isCodeOkay()) {
      callMobileSigninConfirm();
    } else if (showCode && showName && name.length > 0) {
      try {
        const termAgreed = true;
        await updateMe({ name, termAgreed });
        callSignupTracking();
        dispatch({
          type: "UPDATE_USER",
          value: { name, mobile, termAgreed },
        });
      } catch (e) {}
    }
  }, [isMobileOkay, isCodeOkay, showCode, name, showName]);

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <div>
        <SurveyTopBar
          prevPath={`/survey/${surveyId}/step-6-1`}
          hideHome={true}
        />
      </div>
      <div className="mt-3 sm:mt-5">
        <Title title="회원가입/로그인을 해주세요" />
      </div>
      <div className="w-full text-left flex-1 flex flex-col">
        <div className="mt-1 sm:mt-2 mb-3 sm:mb-5 body-regular-14 text-left whitespace-pre break-words">
          {`여다는 휴대폰 인증으로 회원가입/로그인을 진행합니다.\n고객님의 번호는 안전하게 보호됩니다.`}
        </div>
        <div className="w-full relative">
          <div
            className={`${
              mobileError ? "text-yoda-error-red" : "text-yoda-primary"
            } body-medium-15`}
          >
            휴대폰 번호
          </div>
          <div
            className={`mt-1.5 sm:mt-2.5 w-full h-12 sm:h-18 border ${
              mobileError ? "border-yoda-error-red" : "border-yoda-gray-4"
            } rounded sm:rounded-md px-4 sm:px-6 flex items-center`}
          >
            <input
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              className="placeholder-yoda-gray-3 body-regular-16 flex-1 bg-white"
              placeholder="번호를 입력해주세요"
              value={mobile}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  (re.test(e.target.value) && e.target.value.length < 12)
                ) {
                  setMobile(e.target.value);
                }
              }}
              disabled={showName}
            ></input>
            {mobileError ? (
              <ErrorIcon className="h-5 w-5 sm:h-7 sm:w-7" />
            ) : isMobileOkay() ? (
              <CheckIcon className="h-5 w-5 sm:h-7 sm:w-7" />
            ) : null}
          </div>
          <div
            className={`mt-0.5 sm:mt-1.5 mb-3 sm:mb-5 inline-block ${
              mobileError ? "text-yoda-error-red" : "text-yoda-gray-4"
            } body-regular-12`}
          >
            {mobileError
              ? "번호를 다시 확인해주세요"
              : isMobileOkay()
              ? ""
              : "- 없이 숫자만 입력해주세요"}
          </div>
          {showCode && (
            <button
              className="mt-0.5 sm:mt-1.5 body-regular-12 text-yoda-primary underline absolute right-0"
              onClick={() => {
                if (showCode && !showName && isMobileOkay()) {
                  callMobileSigninRequest();
                }
              }}
            >
              인증번호 재발송
            </button>
          )}
        </div>
        {showCode && (
          <div>
            <div
              className={`mt-2 sm:mt-3 ${
                codeError ? "text-yoda-error-red" : "text-yoda-primary"
              } body-medium-15`}
            >
              인증번호
            </div>
            <div className="flex items-end">
              <div
                className={`mt-1.5 sm:mt-2.5 w-full h-12 sm:h-18 border ${
                  codeError ? "border-yoda-error-red" : "border-yoda-gray-4"
                } rounded sm:rounded-md px-4 sm:px-6 flex items-center`}
              >
                <input
                  ref={codeRef}
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="placeholder-yoda-gray-3 body-regular-16 flex-1 bg-white"
                  placeholder="인증번호를 입력해주세요"
                  value={code}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (
                      e.target.value === "" ||
                      (re.test(e.target.value) && e.target.value.length < 12)
                    ) {
                      setCode(e.target.value);
                    }
                  }}
                  disabled={showName}
                ></input>
                {codeError ? (
                  <ErrorIcon className="h-5 w-5 sm:h-7 sm:w-7" />
                ) : isCodeOkay() ? (
                  <CheckIcon className="h-5 w-5 sm:h-7 sm:w-7" />
                ) : null}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div
                className={`mt-0.5 sm:mt-1.5 ${
                  codeError ? "text-yoda-error-red" : "text-yoda-gray-4"
                } body-regular-12`}
              >
                {codeError
                  ? "인증번호를 확인해주세요."
                  : `인증번호 확인 가능시간 ${Math.floor(
                      seconds / 60
                    )}분 ${getTwoDigits(seconds % 60)}초`}
              </div>
            </div>
          </div>
        )}
        {showName && (
          <div className="mb-4 sm:mb-6">
            <div className="mt-2 sm:mt-3 text-yoda-primary body-medium-15">
              이름
            </div>
            <input
              className="mt-1.5 sm:mt-2.5 w-full h-12 sm:h-18 border border-yoda-gray-4 placeholder-yoda-gray-3 rounded sm:rounded-md px-4 sm:px-6 body-regular-16"
              placeholder="여다에서 사용할 이름을 입력해주세요"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        )}
        <div className="flex-1 w-full"></div>
        {showCode && (
          <div className="text-center mt-5 sm:mt-7">
            <a
              className="body-bold-12-wide"
              href="https://www.notion.so/a626542ecc9d4587aa831fc660549e3d"
              target="_blank"
            >
              이용약관
            </a>
            <span className="font-thin mx-2">ㅣ</span>
            <a
              className="body-bold-12-wide sm:body-bold-18"
              href="https://www.notion.so/a3c03a8acac74f7ea123954c8d1b55d5"
              target="_blank"
            >
              개인정보처리방침
            </a>
          </div>
        )}
        <button
          className={`w-full rounded sm:rounded-md h-12 sm:h-18 mt-2 sm:mt-3 mb-7 sm:mb-10 ${
            isButtonEnabled()
              ? "bg-yoda-primary text-white"
              : "bg-yoda-gray-2 text-yoda-gray-4"
          } btn-bold-16`}
          disabled={!isButtonEnabled()}
          onClick={onButtonClick}
        >
          {showName
            ? "동의하고 시작하기"
            : showCode
            ? "인증하고 시작하기"
            : "인증번호 받기"}
        </button>
      </div>
    </div>
  );
};

export default Survey_7_1;
