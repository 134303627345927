import Axios from "axios";

export type JusoType = {
  detBdNmList: string;
  engAddr: string;
  rn: string;
  emdNm: string;
  zipNo: string;
  roadAddrPart2: string;
  emdNo: string;
  sggNm: string;
  jibunAddr: string;
  siNm: string;
  roadAddrPart1: string;
  bdNm: string;
  admCd: string;
  udrtYn: string;
  lnbrMnnm: string;
  roadAddr: string;
  lnbrSlno: string;
  buldMnnm: string;
  bdKdcd: string;
  liNm: string;
  rnMgtSn: string;
  mtYn: string;
  bdMgtSn: string;
  buldSlno: string;
};

const getLocationOptions = (keyword: string) =>
  Axios.get<{
    results: {
      common: {
        errorMessage: string;
        countPerPage: string;
        totalCount: string;
        errorCode: string;
        currentPage: string;
      };
      juso: [JusoType] | null;
    };
  }>("https://www.juso.go.kr/addrlink/addrLinkApi.do", {
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
    },
    params: {
      confmKey: process.env.REACT_APP_JUSO_ADDR_API_KEY,
      currentPage: 1,
      countPerPage: 10,
      keyword,
      resultType: "json",
    },
  });

const getCoordinates = (
  admCd: string,
  rnMgtSn: string,
  udrtYn: string,
  buldMnnm: string,
  buldSlno: string
) =>
  Axios.get<{
    results: {
      common: {
        errorMessage: string;
        totalCount: string;
        errorCode: string;
      };
      juso:
        | [
            {
              buldMnnm: string;
              rnMgtSn: string;
              bdNm: string;
              entX: string;
              entY: string;
              admCd: string;
              bdMgtSn: string;
              buldSlno: string;
              udrtYn: string;
            }
          ]
        | null;
    };
  }>("https://www.juso.go.kr/addrlink/addrCoordApi.do", {
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
    },
    params: {
      confmKey: process.env.REACT_APP_JUSO_COORD_API_KEY,
      admCd,
      rnMgtSn,
      udrtYn,
      buldMnnm,
      buldSlno,
      resultType: "json",
    },
  });

export { getLocationOptions, getCoordinates };
