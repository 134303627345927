import { useCallback, useContext } from "react";

import { OptionContext } from "../contexts/OptionContext";
import { SurveyContext } from "../contexts/SurveyContext";

const usePayable = () => {
  const [ctx] = useContext(SurveyContext);
  const [{ interestOptions }] = useContext(OptionContext);

  const isPayable = useCallback(() => {
    // step-1-1
    if (
      ctx.dateFixed &&
      ctx.from === "" &&
      ctx.to === "" &&
      ctx.fromMonth === 0 &&
      ctx.length === 0
    ) {
      return false;
    }

    // step-1-2
    if (
      ctx.adults + ctx.kids === 0 ||
      ctx.adultsAge.length + ctx.kidsAge.length === 0 ||
      (ctx.kids === 1 && ctx.kidsMinAge === 0) ||
      (ctx.kids > 1 && (ctx.kidsMinAge === 0 || ctx.kidsMaxAge === 0))
    ) {
      return false;
    }

    // step-2-1
    if (ctx.departLocation === "" || ctx.departCoords === undefined) {
      return false;
    }

    // step-2-3
    if (
      ctx.transportTypes.length === 0 ||
      ctx.destVisitedDegree === undefined
    ) {
      return false;
    }

    // step-2-3
    if (
      ctx.destName === "" &&
      ctx.destCity === "" &&
      ctx.destType === undefined &&
      ctx.destFood === 0 &&
      ctx.destAct === 0
    ) {
      return false;
    }

    // step-3-1
    if (
      ((ctx.from && ctx.to && ctx.from !== ctx.to) ||
        (ctx.fromMonth > 0 && ctx.length > 1)) &&
      ctx.accommAddr.length === 0 &&
      ctx.accommCoords === undefined &&
      ctx.accommTypes.length === 0 &&
      ctx.accommBudget.length === 0
    ) {
      return false;
    }

    // step-3-2
    if (ctx.interestTypes.length === 0) {
      return false;
    }

    // step-3-3
    if (ctx.interestPriority.length === 0) {
      return false;
    }

    // step-4-1
    if (
      ctx.isPopular === undefined &&
      ctx.isBusy === undefined &&
      ctx.isInsta === undefined
    ) {
      return false;
    }

    // step-5-1
    if (ctx.gender === 0 && ctx.age === 0) {
      return false;
    }

    return true;
  }, [ctx, interestOptions]);

  return isPayable();
};

export default usePayable;
