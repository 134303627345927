import { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Clear } from "../../assets/Inputs/searchClean.svg";
import { ReactComponent as LeftArrow } from "../../assets/Survey/left.svg";
import { MainContext } from "../../contexts/MainContext";
import { SearchContext } from "../../contexts/SearchContext";
import { ReactComponent as SearchIcon } from "../../assets/Inputs/searchIcon.svg";
import { useHistory } from "react-router-dom";

const Search = () => {
  const [, dispatchMainContext] = useContext(MainContext);
  const [searchContext, dispatchSearchContext] = useContext(SearchContext);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  useEffect(() => {
    setInputValue(searchContext.option);
  }, [searchContext.option]);

  useEffect(() => {
    if (searchContext.onChange) {
      searchContext.onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className={`bg-white w-full survey-min-height absolute px-6 sm:px-9 `}>
      <div
        id="depart-nav-bar"
        className="w-full h-12 sm:h-20 flex items-center justify-start survey-nav-bar"
      >
        <button
          className="w-6 sm:w-9 mr-1.5 sm:mr-2.5"
          onClick={() => history.goBack()}
        >
          <LeftArrow className="w-full h-full" />
        </button>
        <div className="heading-bold-16">{searchContext.navTitle}</div>
      </div>
      <div className="relative w-full mt-1 sm:mt-1.5 h-full">
        <div className="w-full h-12 sm:h-18 flex border rounded sm:rounded-md border-yoda-primary">
          <input
            ref={inputRef}
            className="flex-1 ml-1 px-4 sm:px-6 truncate caret-yoda-primary body-regular-16 text-yoda-black-1"
            type="text"
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            value={inputValue}
            placeholder={searchContext.placeholder}
          />
          {inputValue !== "" && (
            <button
              className="w-6 sm:w-9 mr-3 sm:mr-4"
              onClick={() => setInputValue("")}
            >
              <Clear className="w-full h-full" />
            </button>
          )}
          <SearchIcon className="mr-3 sm:mr-4 w-8 sm:w-12" />
        </div>
        {searchContext.options.length > 0 && (
          <div className="absolute w-full search-max-height overflow-y-auto mt-5 sm:mt-7">
            {searchContext.options.map((option, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    if (option.onClick) {
                      option.onClick();
                    } else {
                      if (option.valid) {
                        dispatchSearchContext({
                          type: "UPDATE_OPTION",
                          value: option.value,
                        });
                        history.goBack();
                      } else {
                        dispatchMainContext({
                          type: "UPDATE_DIALOG",
                          value: {
                            type: "DialogError",
                            content: searchContext.errorMsg,
                          },
                        });
                      }
                    }
                  }}
                  className="border-b sm:border-b-2 border-yoda-gray-2"
                >
                  <div
                    className={`w-full px-4 sm:px-6 py-3.5 sm:py-5 cursor-pointer truncate body-regular-16 leading-loose ${
                      option.valid ? "text-yoda-black-1" : "text-yoda-gray-3"
                    }`}
                  >
                    {option.value}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
