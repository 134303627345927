import "react-nice-dates/build/style.css";

import { DateRangeFocus, DateRangePickerCalendar } from "react-nice-dates";
import { getTwoDigits, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as Notice } from "../../assets/Survey/notice.svg";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { getDay } from "date-fns";
import isHoliday from "../../util/holiday";
import { ko } from "date-fns/locale";
import moveBottomScroll from "../../util/moveBottomScroll";
import { v4 as uuidv4 } from "uuid";

const day = ["일", "월", "화", "수", "목", "금", "토"];
const getFormatDate = (d?: Date) => {
  if (d === undefined) {
    return "";
  }
  return (
    d?.getFullYear() +
    "-" +
    getTwoDigits(d?.getMonth() + 1) +
    "-" +
    getTwoDigits(d?.getDate())
  );
};
const uuid = uuidv4();
const modifiersClassNames = {
  highlight: "-highlight",
};

const Survey_1_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [from, setFrom] = useState<Date | undefined>();
  const [to, setTo] = useState<Date | undefined>();
  const [calendarFocus, setCalendarFocus] =
    useState<DateRangeFocus>("startDate");

  useEffect(() => {
    if (surveyContext.from) {
      setFrom(new Date(surveyContext.from));
    }
    if (surveyContext.to) {
      setTo(new Date(surveyContext.to));
    }
  }, [surveyContext.from, surveyContext.to]);

  const calculateDates = useCallback(() => {
    if (from && to) {
      return Math.ceil((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24));
    }
    return -1;
  }, [from, to]);

  const isDispatchable = useCallback(
    () => from !== undefined && to !== undefined,
    [from, to]
  );

  const dispatch = useCallback(() => {
    const value = {
      from: getFormatDate(from),
      to: getFormatDate(to),
      fromMonth: 0,
      length: 0,
    };
    dispatchSurveyContext({ type: "UPDATE_DATE", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [from, to, surveyId, surveyContext]);
  useEffect(() => {
    amplitude.getInstance().logEvent("1-1-1 when calendar opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude
        .getInstance()
        .logEvent("1-1-1 when calendar activated", properties);
    }
  }, [isDispatchable]);

  useEffect(() => {
    if (from || to) {
      moveBottomScroll();
    }
  }, [from, to]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-1-2`);
  };

  return (
    <div className="w-full survey-min-height">
      <div className="px-6 sm:px-9">
        <div>
          <SurveyTopBar prevPath="/survey/step-1-1" />
        </div>
        <div>
          <SurveyStep step={1} />
          <Title title="언제가세요?" />
          <div className="w-full flex items-center mt-1.5 sm:mt-2.5">
            <Notice className="notice-icon" />
            <div className="ml-1.5 sm:ml-2.5 body-regular-14 text-yoda-green-dark">
              현재 2박 3일까지 선택 가능
            </div>
          </div>
        </div>
        <div className="mt-2 sm:mt-3 w-full relative">
          <DateRangePickerCalendar
            startDate={from}
            endDate={to}
            onStartDateChange={(date) => {
              if (date) {
                setTo(undefined);
                setFrom(date);
              }
            }}
            onEndDateChange={(date) => {
              if (date) {
                setTo(date);
              }
            }}
            locale={ko}
            focus={calendarFocus}
            onFocusChange={(newFocus) =>
              setCalendarFocus(newFocus || "startDate")
            }
            maximumLength={2}
            minimumDate={new Date()}
            modifiers={{
              highlight: (date) =>
                getDay(date) === 0 ||
                isHoliday(
                  date.getFullYear(),
                  date.getMonth() + 1,
                  date.getDate()
                ),
            }}
            modifiersClassNames={modifiersClassNames}
          />
        </div>
      </div>
      <div className="my-2 sm:my-3 border-t border-yoda-gray-2"></div>
      <div className="px-6 sm:px-9 flex items-end">
        <div className="body-medium-15 text-yoda-black-2">
          <div>가는날</div>
          <div
            className={`btn-bold-21 mt-1 sm:mt-1.5 ${
              from ? "text-yoda-primary" : "text-yoda-gray-2"
            }`}
          >
            {from
              ? `${getTwoDigits(from.getMonth() + 1)}.${getTwoDigits(
                  from.getDate()
                )}`
              : ""}
            <span className="btn-bold-16">
              {from ? ` (${day[from.getDay()]})` : "선택해주세요"}
            </span>
          </div>
        </div>
        <div className="body-medium-15 text-yoda-black-2 ml-5 sm:ml-7">
          <div>오는날</div>
          <div
            className={`btn-bold-21 mt-1 sm:mt-1.5 ${
              to ? "text-yoda-primary" : "text-yoda-gray-2"
            }`}
          >
            {to
              ? `${getTwoDigits(to.getMonth() + 1)}.${getTwoDigits(
                  to.getDate()
                )}`
              : ""}
            <span className="btn-bold-16">
              {to ? ` (${day[to.getDay()]})` : "선택해주세요"}
            </span>
          </div>
        </div>
        <div className="flex-1 text-right">
          {calculateDates() > -1 && (
            <div>
              <div className="ml-5 btn-bold-16 text-yoda-primary">
                {calculateDates() > 0
                  ? `${calculateDates()}박 ${calculateDates() + 1}일`
                  : "당일치기"}
              </div>
            </div>
          )}
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath="/survey/step-1-1"
      />
    </div>
  );
};

export default Survey_1_1;
