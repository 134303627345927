import { HighlightsIndexPOIType, WeeklyTrip } from "./type";
import { Link, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";

import { ReactComponent as LocationWhite } from "../../assets/Weekly/location-mark-white.svg";
import { MainContext } from "../../contexts/MainContext";
import PoiPage from "../Trip/PoiPage";
import { ReactComponent as RatingStar } from "../../assets/Weekly/rating-star.svg";
import ReactGA from "react-ga";
import Title from "./Title";
import { getSimpleDays } from "../../util";
import { getWeeklyTrip } from "../../api/Weekly";
import useHideScrollTop from "../../hooks/useHideScrollTop";

const RatingBox = ({ name, rating }: { name: string; rating: string }) => (
  <div className="flex items-center justify-center">
    <div className="body-regular-12 text-yoda-black-2 text-right mr-1 sm:mr-2">
      {name}
    </div>
    <div className="mt-0.5">
      <RatingStar className="w-3 sm:w-4" />
    </div>
    <div className="pl-1 sm:pl-2 body-medium-12 text-yoda-black-2">
      <span className="font-bold text-yoda-black-2">{rating}</span>
      <span className="font-medium text-yoda-gray-5">/5</span>
    </div>
  </div>
);

const WeeklyTag = ({ name }: { name: string }) => (
  <div className="flex items-center h-7 sm:h-10 px-2 sm:px-3 mr-1.5 sm:mr-2.5 body-medium-12 rounded-full bg-white text-black">
    <div>{name}</div>
  </div>
);

const WeeklyTripDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [trip, setTrip] = useState<WeeklyTrip | undefined>();
  const [, dispatchMainContext] = useContext(MainContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [poi, setPoi] = useState<HighlightsIndexPOIType | undefined>();

  useEffect(() => {
    const callGetWeeklyTrip = async () => {
      try {
        const { data: poiData } = await getWeeklyTrip(id);
        setTrip(poiData);
        ReactGA.event({
          category: "주간여다일정방문",
          action: "weekly_trip_visit",
          label: `visit-${poiData.tripId}-${poiData.area}`,
        });
        if ([-1].includes(poiData.tripId)) {
          ReactGA.event({
            category: "주간여다일정",
            action: "weekly_poi_visit",
            label: "보령머드 주간여다 일정 방문",
          });
        }
      } catch {}
    };
    callGetWeeklyTrip();
  }, []);
  useEffect(() => {
    if (poi) {
      dispatchMainContext({
        type: "UPDATE_DIALOG",
        value: {
          type: "DialogConfirm",
          content: <PoiPage poi={poi} close={() => setPoi(undefined)} />,
          hideYes: true,
        },
      });
    } else {
      dispatchMainContext({
        type: "UPDATE_DIALOG",
        value: { content: undefined },
      });
    }
  }, [poi]);

  useEffect(() => {
    const eventListener = () =>
      setScrollPosition(document.documentElement.scrollTop);
    document.addEventListener("scroll", eventListener);
    return () => document.addEventListener("scroll", eventListener);
  });

  const scrollPos = useCallback(() => {
    const LIMIT = 500;
    if (scrollPosition > LIMIT) return 100;
    return (scrollPosition / LIMIT) * 100;
  }, [scrollPosition]);

  useHideScrollTop();

  useEffect(() => {
    const firstHeightFull = document.getElementById("first-h-full");
    const paddingTop = document.getElementById("padding-top");
    const paddingBottom = document.getElementById("padding-bottom");
    if (firstHeightFull) {
      firstHeightFull.classList.add("h-full");
    }
    if (paddingTop) {
      paddingTop.classList.remove("pt-14");
      paddingTop.classList.remove("sm:pt-20");
    }
    if (paddingBottom) {
      paddingBottom.classList.remove("overall-padding-bottom");
    }
    return () => {
      if (firstHeightFull) {
        firstHeightFull.classList.remove("h-full");
      }
      if (paddingTop) {
        paddingTop.classList.add("pt-14");
        paddingTop.classList.add("sm:pt-20");
      }
      if (paddingBottom) {
        paddingBottom.classList.add("overall-padding-bottom");
      }
    };
  }, []);
  if (trip === undefined) {
    return <div className="w-full h-screen"></div>;
  }
  return (
    <div className="w-full h-full relative">
      <Title title="일정의 발견" scrollPos={scrollPos()} />
      <div className="w-full h-full pb-20 sm:pb-32">
        <div id="contents-header-box">
          <div
            className="w-full h-90 sm:h-112 bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `url(${trip.mainImageUrl})`,
            }}
          >
            <div
              className="w-full h-full px-6 sm:px-9 py-9 sm:py-12 flex flex-col justify-end"
              style={{
                backgroundImage:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 37.03%, rgba(0, 0, 0, 0.5) 66.11%)",
              }}
            >
              <div
                className="text-white pb-3 sm:pb-5"
                style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 0.4)" }}
              >
                <div className="heading-bold-22">{trip.subTitle}</div>
                <div className="heading-bold-33">{`${trip.area} 여행`}</div>
              </div>
              <div className="w-full flex justify-start">
                <WeeklyTag name="일정의 발견" />
                <WeeklyTag name={getSimpleDays(trip.length)} />
              </div>
            </div>
          </div>
          <div className="bg-yoda-black-2 h-10 sm:h-15 flex items-center px-6 sm:px-9">
            <LocationWhite className="w-4 sm:w-6" />
            <div className="body-medium-15 text-white px-2 sm:px-3">{`${trip.location}`}</div>
          </div>
        </div>
        <div
          id="remaining-contents-box"
          className="px-6 sm:px-9 pt-10 sm:pt-15 bg-white"
        >
          <div id="contents-description">
            <div className="body-bold-18 text-yoda-green-dark">일정 소개</div>
            <div
              className="body-regular-16 text-yoda-black-1 pt-1 sm:pt-2 whitespace-pre-wrap"
              style={{ lineHeight: "1.875" }}
              dangerouslySetInnerHTML={{ __html: trip.description }}
            ></div>
          </div>
          <div id="contents-pois" className="pt-9 sm:pt-14">
            <div className="body-bold-18 text-yoda-green-dark pb-2 sm:pb-3">
              주요 장소
            </div>
            <div>
              {trip.highlights.map((poi, idx) => (
                <button
                  className="w-full h-full rounded-md sm:rounded-lg weekly-trip-detail-min-height grid grid-cols-3 mb-2 sm:mb-3"
                  style={{ boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.4)" }}
                  key={idx}
                  onClick={() => setPoi(poi)}
                >
                  <div
                    className="w-full rounded-l-md sm:rounded-l-lg h-full col-span-1"
                    style={{
                      backgroundImage: `url(${poi.detail.mainImageUrl})`,
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className="h-full col-span-2 px-3 sm:px-4 py-2 sm:py-3 flex flex-col justify-between">
                    <div className="text-left text-yoda-black-2">
                      <div className="btn-bold-16 truncate">{poi.name}</div>
                      <div className="text-sm sm:text-xl leading-5 pt-1 line-clamp-2">
                        {poi.detail.description}
                      </div>
                    </div>
                    <div className="flex justify-end pt-2">
                      {poi.detail.naverRating && (
                        <RatingBox
                          name="네이버"
                          rating={poi.detail.naverRating}
                        />
                      )}
                      <div className="pl-3 sm:pl-4">
                        {poi.detail.googleRating && (
                          <RatingBox
                            name="구글"
                            rating={poi.detail.googleRating}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="weekly-bottom w-full px-6 sm:px-9 py-4 sm:py-5 border-t border-yoda-gray-1">
        <Link
          to={`/weekly-trip/${trip.tripId}`}
          className="w-full h-12 sm:h-18 bg-yoda-black-1  rounded sm:rounded-md text-center flex justify-center items-center"
          onClick={() => {
            ReactGA.event({
              category: "주간여다일정상세방문",
              action: "weekly_trip_to_detail",
              label: `detail-visit-${trip.tripId}-${trip.area}`,
            });
            if ([-1].includes(trip.tripId)) {
              ReactGA.event({
                category: "주간여다일정",
                action: "weekly_poi_visit",
                label: "보령머드 일정표 방문",
              });
            }
          }}
        >
          <div className="btn-bold-16 text-white">이 일정 보러가기</div>
        </Link>
      </div>
    </div>
  );
};

export default WeeklyTripDetail;
