import { getSelectedInterests, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Busy from "../../assets/Survey/preference/busy.png";
import BusyHigh from "../../assets/Survey/preference/busy@3x.png";
import BusyMedium from "../../assets/Survey/preference/busy@2x.png";
import BusyN from "../../assets/Survey/preference/busyN.png";
import BusyNHigh from "../../assets/Survey/preference/busyN@3x.png";
import BusyNMedium from "../../assets/Survey/preference/busyN@2x.png";
import { ReactComponent as CheckGreen } from "../../assets/Survey/checkGreen.svg";
import Insta from "../../assets/Survey/preference/insta.png";
import InstaHigh from "../../assets/Survey/preference/insta@3x.png";
import InstaMedium from "../../assets/Survey/preference/insta@2x.png";
import InstaN from "../../assets/Survey/preference/instaN.png";
import InstaNHigh from "../../assets/Survey/preference/instaN@3x.png";
import InstaNMedium from "../../assets/Survey/preference/instaN@2x.png";
import { OptionContext } from "../../contexts/OptionContext";
import Picture from "../General/Picture";
import Popular from "../../assets/Survey/preference/popular.png";
import PopularHigh from "../../assets/Survey/preference/popular@3x.png";
import PopularMedium from "../../assets/Survey/preference/popular@2x.png";
import PopularN from "../../assets/Survey/preference/popularN.png";
import PopularNHigh from "../../assets/Survey/preference/popularN@3x.png";
import PopularNMedium from "../../assets/Survey/preference/popularN@2x.png";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { v4 as uuidv4 } from "uuid";

type Options = {
  name: string;
  value: boolean;
  image: string;
  imageM: string;
  imageH: string;
};

const popularOptions = [
  {
    name: "인기 많은 곳이 좋아요",
    value: true,
    image: Popular,
    imageM: PopularMedium,
    imageH: PopularHigh,
  },
  {
    name: "참신한 곳이 좋아요",
    value: false,
    image: PopularN,
    imageM: PopularNMedium,
    imageH: PopularNHigh,
  },
];
const busyOptions = [
  {
    name: "부지런히 다녀요",
    value: true,
    image: Busy,
    imageM: BusyMedium,
    imageH: BusyHigh,
  },
  {
    name: "느긋하게 다녀요",
    value: false,
    image: BusyN,
    imageM: BusyNMedium,
    imageH: BusyNHigh,
  },
];
const instaOptions = [
  {
    name: "선호해요",
    value: true,
    image: Insta,
    imageM: InstaMedium,
    imageH: InstaHigh,
  },
  {
    name: "선호하진 않아요",
    value: false,
    image: InstaN,
    imageM: InstaNMedium,
    imageH: InstaNHigh,
  },
];

const OptionComponents = ({
  options,
  option,
  setOption,
  scrollAction,
}: {
  options: Options[];
  option: boolean | undefined;
  setOption: (op: boolean) => void;
  scrollAction?: () => void;
}) => (
  <div className="w-full grid grid-cols-2 gap-x-2 mt-2 sm:mt-3">
    {options.map((dest) => (
      <button
        key={dest.name}
        className={`w-full relative rounded sm:rounded-md overflow-hidden ${
          option === dest.value ? "shadow-lg" : " opacity-80"
        }`}
        onClick={() => {
          setOption(dest.value);
          if (scrollAction) {
            scrollAction();
          }
        }}
      >
        {option === dest.value && (
          <div className="z-10 absolute top-1 right-0.5 sm:top-1.5 sm:right-1">
            <CheckGreen className="w-6 sm:w-9" />
          </div>
        )}
        <div
          className={`absolute w-full h-full top-0 left-0 rounded sm:rounded-md ${
            option === dest.value
              ? "border-2 border-yoda-primary"
              : "border border-yoda-gray-2"
          } z-10`}
        ></div>
        <div className="w-full text-yoda-black-2 flex flex-col">
          <div className="py-2 sm:py-3 px-1 sm:px-2 bg-white w-full flex items-center justify-center">
            <Picture
              className="bg-white w-36 sm:w-52"
              Low={dest.image}
              Medium={dest.imageM}
              High={dest.imageH}
            />
          </div>
          <div className="w-full py-3 sm:py-4 card-regular-14 text-yoda-black-3 bg-white border-t rounded-b sm:rounded-b-md border-yoda-gray-2 px-2 whitespace-pre-line sm:whitespace-normal flex items-center justify-center">
            {dest.name}
          </div>
        </div>
      </button>
    ))}
  </div>
);

const uuid = uuidv4();
const Survey_4_1 = () => {
  const busyRef = useRef<HTMLDivElement>(null);
  const instaRef = useRef<HTMLDivElement>(null);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [{ interestOptions }] = useContext(OptionContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [isPopular, setIsPopular] = useState<boolean | undefined>();
  const [isBusy, setIsBusy] = useState<boolean | undefined>();
  const [isInsta, setIsInsta] = useState<boolean | undefined>();

  useEffect(() => {
    setIsPopular(surveyContext.isPopular);
    setIsBusy(surveyContext.isBusy);
    setIsInsta(surveyContext.isInsta);
  }, [surveyContext]);

  const dispatch = useCallback(() => {
    if (
      isPopular !== undefined &&
      isBusy !== undefined &&
      isInsta !== undefined
    ) {
      dispatchSurveyContext({
        type: "UPDATE_IS_POPULAR",
        value: {
          isPopular,
        },
      });
      dispatchSurveyContext({
        type: "UPDATE_IS_BUSY",
        value: {
          isBusy,
        },
      });
      dispatchSurveyContext({
        type: "UPDATE_IS_INSTA",
        value: {
          isInsta,
        },
      });
      saveSurvey(surveyId, {
        ...surveyContext,
        isPopular,
        isBusy,
        isInsta,
      });
    }
  }, [isPopular, isBusy, isInsta, surveyId, surveyContext]);

  const prevPath = useCallback(() => {
    const interests = getSelectedInterests(
      surveyContext.interestTypes,
      interestOptions
    );

    return interests.length === 1 || surveyContext.destAct > 0 ? "3-2" : "3-3";
  }, [surveyContext, interestOptions]);

  const isDispatchable = useCallback(
    () =>
      isPopular !== undefined && isBusy !== undefined && isInsta !== undefined,
    [isPopular, isBusy, isInsta]
  );
  useEffect(() => {
    amplitude.getInstance().logEvent("4-1 pref opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("4-1 pref activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-4-2`);
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <div>
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-${prevPath()}`} />
        <div>
          <SurveyStep step={surveyContext.destType === "anything" ? 6 : 7} />
          <Title title="여행취향을 알려주세요" />
        </div>
      </div>
      <div className="mt-6 sm:mt-9">
        <div className="mb-8 sm:mb-12">
          <div className="body-medium-15 text-yoda-black-2">
            Q1.여행장소를 고를 때,
          </div>
          <OptionComponents
            options={popularOptions}
            option={isPopular}
            setOption={setIsPopular}
            scrollAction={() => {
              busyRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </div>
        <div className="mb-8 sm:mb-12">
          <div ref={busyRef}></div>
          <div className="body-medium-15 text-yoda-black-2">
            Q2.여행을 다닐 때,
          </div>
          <OptionComponents
            options={busyOptions}
            option={isBusy}
            setOption={setIsBusy}
            scrollAction={() => {
              instaRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </div>
        <div>
          <div ref={instaRef}></div>
          <div className="body-medium-15 text-yoda-black-2">
            Q3.인스타그램에서 유명한 장소들을,
          </div>
          <OptionComponents
            options={instaOptions}
            option={isInsta}
            setOption={setIsInsta}
          />
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-${prevPath()}`}
      />
    </div>
  );
};

export default Survey_4_1;
