import Picture from "../General/Picture";
import folder from "../../assets/MyPage/trip/empty.png";
import folderHigh from "../../assets/MyPage/trip/empty@3x.png";
import folderMedium from "../../assets/MyPage/trip/empty@2x.png";
import useAddSurvey from "../../hooks/useAddSurvey";

const EmptyTrip = () => {
  const onClickAddSurvey = useAddSurvey();

  return (
    <div className="overall-min-height w-full px-5">
      <div className="flex flex-col justify-center items-center">
        <Picture
          className="w-24 sm:w-40"
          Low={folder}
          Medium={folderMedium}
          High={folderHigh}
        />
        <div className="card-regular-14 mt-2 sm:mt-3">
          아직 주문하신 여행일정이 없네요!
        </div>
      </div>
      <button
        className="w-full h-12 sm:h-20 bg-yoda-black-1 btn-bold-16 text-white rounded sm:rounded-md mt-4 sm:mt-6"
        onClick={onClickAddSurvey}
      >
        여행일정 주문하기
      </button>
    </div>
  );
};

export default EmptyTrip;
