import { defaultOptionState, getDefaultOption, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { v4 as uuidv4 } from "uuid";

const genderOptions = [
  {
    name: "남자",
    value: 1,
  },
  {
    name: "여자",
    value: 2,
  },
];

const ageOptions = [
  {
    name: "10대",
    value: 1,
  },
  {
    name: "20대",
    value: 2,
  },
  {
    name: "30대",
    value: 3,
  },
  {
    name: "40대",
    value: 4,
  },
  {
    name: "50대",
    value: 5,
  },
  {
    name: "60대",
    value: 6,
  },
  {
    name: "70대",
    value: 7,
  },
  {
    name: "80대 이상",
    value: 8,
  },
];

const uuid = uuidv4();
const Survey_5_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();

  const [gender, setGender] = useState(defaultOptionState);
  const [age, setAge] = useState(defaultOptionState);

  useEffect(() => {
    setGender(getDefaultOption(genderOptions, surveyContext.gender));
    setAge(getDefaultOption(ageOptions, surveyContext.age));
  }, [surveyContext]);
  const isDispatchable = useCallback(
    () => gender.value !== 0 && age.value !== 0,
    [gender, age]
  );

  const dispatch = useCallback(() => {
    const value = { gender: gender.value as number, age: age.value as number };
    dispatchSurveyContext({ type: "UPDATE_PERSONAL", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [gender, age, surveyId, surveyContext]);
  useEffect(() => {
    amplitude.getInstance().logEvent("5-1 age opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("5-1 age activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-6-1`);
  };

  const prevPath = useCallback(
    () => (surveyContext.destType === "anything" ? "3-5" : "4-2"),
    [surveyContext]
  );

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-${prevPath()}`} />
      <div>
        <SurveyStep step={8} />
        <Title title={`마지막으로 주문자님의\n연령과 성별을 알려주세요`} />
      </div>
      <div>
        <div className="mt-6 sm:mt-9">
          <div className="font-medium text-base sm:text-2xl text-yoda-black-2 mb-2 sm:mb-3">
            성별
          </div>
          <div className="grid grid-cols-3 rounded sm:rounded-md gap-1 sm:gap-2">
            {genderOptions.map((option) => (
              <button
                key={option.value}
                className={`w-full h-10 sm:h-15 rounded sm:rounded-md font-medium text-sm sm:text-xl tracking-tighter ${
                  option.value === gender.value
                    ? "bg-yoda-primary text-white"
                    : "bg-yoda-gray-2 text-yoda-gray-4"
                }`}
                onClick={() =>
                  setGender((prevGender) =>
                    prevGender.value !== option.value
                      ? option
                      : { name: "", value: 0 }
                  )
                }
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="mt-6 sm:mt-9">
          <div className="font-medium text-base sm:text-2xl text-yoda-black-2 mb-2 sm:mb-3">
            연령
          </div>
          <div className="grid grid-cols-3 rounded sm:rounded-md gap-1 sm:gap-2">
            {ageOptions.map((option) => (
              <button
                key={option.value}
                className={`w-full h-10 sm:h-15 rounded sm:rounded-md font-medium text-sm sm:text-xl tracking-tighter ${
                  option.value === age.value
                    ? "bg-yoda-primary text-white"
                    : "bg-yoda-gray-2 text-yoda-gray-4"
                }`}
                onClick={() =>
                  setAge((prevAge) =>
                    prevAge.value !== option.value
                      ? option
                      : { name: "", value: 0 }
                  )
                }
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-${prevPath()}`}
      />
    </div>
  );
};

export default Survey_5_1;
