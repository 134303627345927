import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import leftArrow from "./left@3x.png";

const NavBar = () => {
  const [title, setTitle] = useState("");
  const location = useLocation();
  const history = useHistory();
  const bottomBorder = location.pathname.includes("/mytrip");
  useEffect(() => {
    if (location.pathname.includes("/mypage")) {
      setTitle("마이페이지");
    } else if (location.pathname.includes("/mytrip")) {
      setTitle("나의 여행");
    } else if (location.pathname.includes("/faq")) {
      setTitle("자주 묻는 질문");
    } else if (location.pathname.includes("/specialty")) {
      setTitle("여다의 일정은 무엇이 다른가요?");
    } else if (location.pathname.includes("/guide")) {
      setTitle("이용 방법");
    } else {
      setTitle("");
    }
  }, [location]);

  if (title !== "") {
    return (
      <div
        className={`fixed top-0 left-0 sm:left-44 flex items-center bg-white max-w-full ${
          bottomBorder ? "" : "border-b border-gray-200"
        } h-14 sm:h-20 z-40`}
        style={{
          width: "33.75rem",
          boxShadow: bottomBorder ? "" : "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="btn-bold-16 w-full text-center">{title}</div>

        <button
          className="absolute left-4 top-0 h-full w-6 sm:w-9 flex items-center"
          onClick={() => history.goBack()}
        >
          <img src={leftArrow} alt="left-arrow" />
        </button>
      </div>
    );
  }
  return <div></div>;
};

export default NavBar;
