import {
  getDefaultInterestPriority,
  getSelectedInterests,
  saveSurvey,
} from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import InterestButton from "./Util/InterestButton";
import { OptionContext } from "../../contexts/OptionContext";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();
const Survey_3_3 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [{ interestOptions, destActOptions }] = useContext(OptionContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [interestPriority, setInterestPriority] =
    useState<string | undefined>(undefined);
  const [defaultInterest, setDefaultInterest] = useState("");

  useEffect(() => {
    setInterestPriority(
      getDefaultInterestPriority(
        surveyContext.interestTypes,
        surveyContext.interestPriority,
        interestOptions
      )
    );
    if (surveyContext.destAct > 0) {
      const destAct = destActOptions.find(
        (act) => act.poiId === surveyContext.destAct
      );
      if (destAct) {
        setDefaultInterest(destAct.value);
      }
    }
  }, [surveyContext, interestOptions, destActOptions]);
  const isDispatchable = useCallback(
    () => interestPriority !== undefined,
    [interestPriority]
  );

  const dispatch = useCallback(() => {
    const iP = interestPriority ? [interestPriority] : [];

    dispatchSurveyContext({
      type: "UPDATE_INTEREST_PRIORITY",
      value: {
        interestPriority: iP,
      },
    });
    saveSurvey(surveyId, {
      ...surveyContext,
      interestPriority: iP,
    });
  }, [interestPriority, surveyId, surveyContext]);

  useEffect(() => {
    amplitude.getInstance().logEvent("3-3 onepick opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("3-3 onepick activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-4-1`);
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <div>
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-3-2`} />
      </div>
      <div className="mb-6 sm:mb-9">
        <SurveyStep step={5} />
        <Title title={`가장 하고 싶은 것을\n하나 골라주세요`} />
      </div>
      <div className="grid grid-cols-3 gap-2 sm:gap-3">
        {getSelectedInterests(surveyContext.interestTypes, interestOptions).map(
          (interest) => (
            <InterestButton
              interest={interest}
              selected={
                interestPriority === interest.value ||
                defaultInterest === interest.value
              }
              onClick={() => {
                setInterestPriority((prevIntr) => {
                  if (prevIntr === undefined) return interest.value;
                  if (prevIntr === interest.value) return;
                  return interest.value;
                });
              }}
              poi={interest.value === defaultInterest}
            />
          )
        )}
        <SurveyBottomBar
          onClickNext={onClickNext}
          isDispatchable={isDispatchable()}
          prevPath={`/survey/${surveyId}/step-3-2`}
        />
      </div>
    </div>
  );
};

export default Survey_3_3;
