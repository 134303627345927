import Dropdown, { Option } from "../Inputs/Dropdown";
import { adultsAgeOptions, kidsAgeOptions } from "../../util/surveyOptions";
import { defaultOptionState, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ActiveMinus } from "../../assets/Survey/minus-active.svg";
import { ReactComponent as InactiveMinus } from "../../assets/Survey/minus-inactive.svg";
import { ReactComponent as Plus } from "../../assets/Survey/plus.svg";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();
const Survey_1_2 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const { surveyId } = useParams<{ surveyId: string }>();
  const [adultsAge, setAdultsAge] = useState<Option[]>([]);
  const [kidsAge, setKidsAge] = useState<Option[]>([]);
  const history = useHistory();
  useEffect(() => {
    setAdultsAge(
      surveyContext.adultsAge.map((age) => {
        const option = adultsAgeOptions.find((op) => op.value === age);
        return option || defaultOptionState;
      })
    );
    setKidsAge(
      surveyContext.kidsAge.map((age) => {
        const option = kidsAgeOptions.find((op) => op.value === age);
        return option || defaultOptionState;
      })
    );
  }, [surveyContext]);
  const isDispatchable = useCallback(() => {
    if (adultsAge.length + kidsAge.length === 0) {
      return false;
    } else if (
      adultsAge.some((age) => age.value === 0) ||
      kidsAge.some((age) => age.value === 0)
    ) {
      return false;
    }
    return true;
  }, [adultsAge, kidsAge]);
  const dispatch = useCallback(() => {
    let kidsMaxAge: number | string = 0;
    kidsAge.forEach((age) => {
      if (age.value > kidsMaxAge) {
        kidsMaxAge = age.value;
      }
    });
    let kidsMinAge: number | string = kidsMaxAge;
    kidsAge.forEach((age) => {
      if (age.value < kidsMinAge) {
        kidsMinAge = age.value;
      }
    });
    const value = {
      adults: adultsAge.length,
      adultsAge: adultsAge.map((age) => age.value as number),
      kids: kidsAge.length,
      kidsAge: kidsAge.map((age) => age.value as number),
      kidsMinAge,
      kidsMaxAge,
    };
    dispatchSurveyContext({ type: "UPDATE_COMPANION", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [adultsAge, kidsAge, surveyId, surveyContext]);
  useEffect(() => {
    amplitude.getInstance().logEvent("1-2 who opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("1-2 whonext activated", properties);
    }
  }, [isDispatchable]);
  const setAdultsAgeDispatch = (index: number) => (option: Option) => {
    setAdultsAge((prevAge) => {
      const newAge = [...prevAge];
      newAge[index] = option;
      return newAge;
    });
  };
  const setKidsAgeDispatch = (index: number) => (option: Option) => {
    setKidsAge((prevAge) => {
      const newAge = [...prevAge];
      newAge[index] = option;
      return newAge;
    });
  };

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-2-1`);
  };

  const prevPath = useCallback(() => {
    if (surveyContext.from && surveyContext.to) {
      return `/survey/${surveyId}/step-1-1-1`;
    } else if (surveyContext.fromMonth) {
      return `/survey/${surveyId}/step-1-1-2`;
    }
    return "/survey/step-1-1";
  }, [surveyContext]);

  return (
    <div className="w-full survey-min-height">
      <div className="px-6 sm:px-9">
        <SurveyTopBar prevPath={prevPath()} />
      </div>
      <div className="px-6 sm:px-9">
        <SurveyStep step={2} />
        <Title title="누가 여행하시나요?" />
      </div>
      <div className="px-6 sm:px-9">
        <div className="body-medium-15 text-yoda-black-2 mt-6 sm:mt-9">
          어른
        </div>
        <div className="flex mt-1.5 sm:mt-2.5">
          <div className="grid grid-cols-3 items-center w-28 sm:w-40 h-10 sm:h-16 rounded sm:rounded-md border border-yoda-gray-4 mb-4 sm:mb-6">
            <button
              name="minus"
              onClick={() => {
                if (adultsAge.length > 0) {
                  setAdultsAge((prevAge) => {
                    const newAge = [...prevAge];
                    newAge.pop();
                    return newAge;
                  });
                }
              }}
              className="h-full flex justify-center items-center plus-minus-btn"
            >
              {adultsAge.length === 0 ? (
                <InactiveMinus className="minus-btn" />
              ) : (
                <ActiveMinus className="minus-btn" />
              )}
            </button>
            <div className="body-regular-16 w-full text-center text-yoda-black-1">
              {adultsAge.length}
            </div>
            <button
              name="plus"
              onClick={() => {
                setAdultsAge((prevAge) => {
                  const newAge = [...prevAge];
                  newAge.push(defaultOptionState);
                  return newAge;
                });
              }}
              className="plus-minus-btn w-full h-full flex justify-center items-center relative rounded-r sm:rounded-r-md"
            >
              <Plus className="minus-btn z-10 relative" />
            </button>
          </div>
          <div className="ml-2 sm:ml-3 w-44 sm:w-60">
            {adultsAge.length > 0 &&
              adultsAge.map((age, index) => (
                <Dropdown
                  key={index}
                  useOption={[age, setAdultsAgeDispatch(index)]}
                  options={adultsAgeOptions}
                  placeholder={{ name: "연령대", value: 0 }}
                  className="w-36 sm:w-56 mb-4 sm:mb-6"
                />
              ))}
          </div>
        </div>
      </div>
      <div className="border-b border-yoda-gray-2 mt-2 sm:mt-3"></div>
      <div className="px-6 sm:px-9">
        <div className="body-medium-15 text-yoda-black-2 mt-6 sm:mt-9">
          아이 (만 18세 미만)
        </div>
        <div className="flex mt-1.5 sm:mt-2.5">
          <div className="grid grid-cols-3 items-center w-28 sm:w-40 h-10 sm:h-16 rounded sm:rounded-md border border-yoda-gray-4 mb-4 sm:mb-6">
            <button
              name="minus"
              onClick={() => {
                if (kidsAge.length > 0) {
                  setKidsAge((prevAge) => {
                    const newAge = [...prevAge];
                    newAge.pop();
                    return newAge;
                  });
                }
              }}
              className="h-full flex justify-center items-center plus-minus-btn"
            >
              {kidsAge.length === 0 ? (
                <InactiveMinus className="minus-btn" />
              ) : (
                <ActiveMinus className="minus-btn" />
              )}
            </button>
            <div className="body-regular-16 w-full text-center">
              {kidsAge.length}
            </div>
            <button
              name="plus"
              onClick={() => {
                setKidsAge((prevAge) => {
                  const newAge = [...prevAge];
                  newAge.push(defaultOptionState);
                  return newAge;
                });
              }}
              className="plus-minus-btn w-full h-full flex justify-center items-center relative"
            >
              <Plus className="minus-btn z-10 relative w-full h-full" />
            </button>
          </div>
          <div className="ml-2 sm:ml-3 w-44 sm:w-60">
            {kidsAge.length > 0 &&
              kidsAge.map((age, index) => (
                <Dropdown
                  key={index}
                  useOption={[age, setKidsAgeDispatch(index)]}
                  options={kidsAgeOptions}
                  placeholder={{ name: "연령대", value: 0 }}
                  className="w-36 sm:w-56 mb-4 sm:mb-6"
                />
              ))}
          </div>
        </div>
      </div>
      <div className="border-b border-yoda-gray-2 mt-2 sm:mt-3"></div>
      <div className="flex mt-1.5 sm:mt-2.5 px-6 sm:px-9 mb-12 sm:mb-20">
        <div className="w-28 sm:w-40 h-10 sm:h-16 mb-4 sm:mb-6">&nbsp;</div>
        <div className="ml-2 sm:ml-3 mt-5 sm:mt-7 btn-bold-16 text-yoda-black-1 text-center">
          총
          <span className="text-yoda-primary mx-4 sm:mx-6">
            {adultsAge.length + kidsAge.length}
          </span>
          명
        </div>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={prevPath()}
      />
    </div>
  );
};
export default Survey_1_2;
