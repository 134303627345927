import { FAQList } from "./FAQList";
import Question from "./Question";

const Faq = () => (
  <div className="bg-white w-full sm:shadow-lg pt-10 pb-10 sm:pb-20 overall-min-height">
    <div className="mx-4">
      <div className="text-center heading-bold-22 mb-10">자주 묻는 질문</div>
      <div className="w-full h-full bg-white">
        {FAQList.map((faq, idx) => (
          <Question index={idx} title={faq.title} describe={faq.describe} />
        ))}
      </div>
    </div>
  </div>
);

export default Faq;
