import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import useDestination from "../../hooks/useDestination";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_2_5 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const destination = useDestination("location") || surveyContext.destLocation;

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [destVisitedDegree, setDestVistedDegree] = useState<
    1 | 2 | 3 | undefined
  >(undefined);

  useEffect(() => {
    setDestVistedDegree(surveyContext.destVisitedDegree);
  }, [surveyContext.destVisitedDegree]);

  const isDispatchable = useCallback(
    () => destVisitedDegree !== undefined,
    [destVisitedDegree]
  );

  const dispatch = useCallback(() => {
    dispatchSurveyContext({
      type: "UPDATE_DESTINATION_VISITED_DEGREE",
      value: { destVisitedDegree },
    });
    saveSurvey(surveyId, { ...surveyContext, destVisitedDegree });

    if (destination === undefined) {
      alert("잘못된 접근입니다.");
      history.push(`/survey/preview`);
    }
  }, [destVisitedDegree, surveyId, surveyContext]);

  const prevPath = useCallback(() => {
    if (surveyContext.destFromWeeklyContents) {
      return "2-1";
    } else if (surveyContext.subDests.length > 0) {
      return "jeju";
    } else if (surveyContext.destCity) {
      return "2-4-1";
    } else if (surveyContext.destFood) {
      return "2-4-2";
    } else if (surveyContext.destAct) {
      return "2-4-3";
    } else if (surveyContext.destDetermined) {
      return "2-4-5";
    }
    return "2-3";
  }, [surveyContext]);

  useEffect(() => {
    amplitude.getInstance().logEvent("2-5 visited opened");
  }, []);

  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("2-5 visited activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    if (
      surveyContext.length === 1 ||
      (surveyContext.from.length > 0 &&
        surveyContext.to.length > 0 &&
        surveyContext.from === surveyContext.to)
    ) {
      history.push(`/survey/${surveyId}/step-3-2`);
    } else {
      history.push(`/survey/${surveyId}/step-3-1-1`);
    }
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-${prevPath()}`} />
      <div>
        <SurveyStep step={4} />
        <Title
          title={`${
            destination === undefined ? "" : destination
          }에\n가보신 적이 있나요?`}
        />
      </div>
      <div className="mt-6 sm:mt-9 pb-3 sm:pb-0 w-full sm:h-20 text-yoda-gray-4">
        <button
          className={`w-full h-12 sm:h-18 rounded sm:rounded-md mb-1 sm:mb-2 btn-bold-16 leading-normal ${
            destVisitedDegree === 1
              ? "bg-yoda-primary text-yoda-gray-0"
              : "bg-gray-200"
          }`}
          onClick={() => {
            setDestVistedDegree((prev) => (prev === 1 ? undefined : 1));
          }}
        >
          처음이에요
        </button>
        <button
          className={`w-full h-12 sm:h-18 rounded sm:rounded-md mb-1 sm:mb-2 btn-bold-16 ${
            destVisitedDegree === 2
              ? "bg-yoda-primary text-yoda-gray-0"
              : "bg-gray-200"
          }`}
          onClick={() =>
            setDestVistedDegree((prev) => (prev === 2 ? undefined : 2))
          }
        >
          가봤지만 잘 몰라요
        </button>
        <button
          className={`w-full h-12 sm:h-18 rounded sm:rounded-md btn-bold-16 ${
            destVisitedDegree === 3
              ? "bg-yoda-primary text-yoda-gray-0"
              : "bg-gray-200"
          }`}
          onClick={() =>
            setDestVistedDegree((prev) => (prev === 3 ? undefined : 3))
          }
        >
          여러번 가봤어요
        </button>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-${prevPath()}`}
      />
    </div>
  );
};

export default Survey_2_5;
