import {
  WeeklyPOI,
  WeeklyPOIs,
  WeeklyTrip,
  WeeklyTrips,
} from "../components/Weekly/type";

import Axios from "axios";

export type SurveysType = {
  id: number;
  from: string | null;
  to: string | null;
  people: number | null;
  status: number;
  location: string | null;
  mainImageUrl: string | null;
  fromMonth: number | null;
  length: number | null;
  updatedAt: string;
};

export type CandidateType = {
  id: string;
  content: string;
};

const getWeeklyPois = () =>
  Axios.get<WeeklyPOIs>(
    `${process.env.REACT_APP_GNASH_ADDRESS}/v2/weekly/pois`
  );

const getWeeklyPoi = (id: string) =>
  Axios.get<WeeklyPOI>(
    `${process.env.REACT_APP_GNASH_ADDRESS}/v2/weekly/poi/${id}`
  );

const getWeeklyTrips = () =>
  Axios.get<WeeklyTrips>(
    `${process.env.REACT_APP_GNASH_ADDRESS}/v2/weekly/itins`
  );

const getWeeklyTrip = (id: string) =>
  Axios.get<WeeklyTrip>(
    `${process.env.REACT_APP_GNASH_ADDRESS}/v2/weekly/itin/${id}`
  );

export { getWeeklyPois, getWeeklyPoi, getWeeklyTrips, getWeeklyTrip };
