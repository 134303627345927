import { useEffect } from "react";
import Specialty1 from "../../assets/Main/Specialty/specialty_1.png";
import Specialty2 from "../../assets/Main/Specialty/specialty_2.png";
import Specialty3 from "../../assets/Main/Specialty/specialty_3.png";
import Specialty4 from "../../assets/Main/Specialty/specialty_4.png";

const Specialty = () => {
  useEffect(() => {
    const container = document.getElementById("padding-bottom");
    if (container) {
      container.classList.remove("overall-padding-bottom");
    }
    return () => {
      if (container) {
        container.classList.add("overall-padding-bottom");
      }
    };
  }, []);

  return (
    <div className="bg-white w-full sm:shadow-lg h-full">
      <div className="w-full pl-14 bg-yoda-light-yellow">
        <div className="pt-14 heading-bold-24">
          이제껏 없었던
          <br />
          나만의 여행큐레이터
        </div>
        <div className="mt-2 body-regular-14" style={{ lineHeight: 1.8 }}>
          내 마음에 쏙 드는 여행일정,
          <br />
          '여다'가 바로 만들어 드려요.
        </div>
        <img className="w-full" src={Specialty1} alt="" />
      </div>
      <div className="w-full pr-14 bg-yoda-gray-0 text-right">
        <div className="pt-14 heading-bold-24">
          숨겨진 보석같은
          <br />
          여행 장소들
        </div>
        <div className="mt-2 body-regular-14" style={{ lineHeight: 1.8 }}>
          수많은 데이터 속에서 엄선된
          <br />
          여행 스팟이 가득합니다.
        </div>
        <img className="w-full" src={Specialty2} alt="" />
      </div>
      <div className="w-full pl-14 bg-yoda-skyblue">
        <div className="pt-14 heading-bold-24">
          취향저격
          <br />
          여행일정
        </div>
        <div className="mt-2 body-regular-14" style={{ lineHeight: 1.8 }}>
          여다만의 노하우를 통해, 여행자의 취향에
          <br />
          가장 맞는 곳들만 골라드립니다.
        </div>
        <img className="w-full" src={Specialty3} alt="image" />
      </div>
      <div className="w-full pr-14 bg-yoda-gray-0 text-right">
        <div className="pt-14 heading-bold-24">
          이동시간을 줄여주는
          <br />
          최적의 여행 동선
        </div>
        <div className="mt-2 body-regular-14" style={{ lineHeight: 1.8 }}>
          AI로 가장 최적의
          <br />
          여행 동선을 제공합니다
        </div>
        <img className="w-full" src={Specialty4} alt="" />
      </div>
    </div>
  );
};

export default Specialty;
