import { SurveysType, getSurvey } from "../../api/Survey";
import { getTwoDigits, routingToSurvey } from "../../util";

import { ReactComponent as Delete } from "../../assets/MyPage/delete.svg";
import { ReactComponent as Share } from "../../assets/MyPage/share.svg";
import { SurveyContext } from "../../contexts/SurveyContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

export const getPeriod = (trip: SurveysType) => {
  let title = "";

  if (trip.fromMonth) {
    const now = new Date();
    const currentLastDay = new Date(
      now.getFullYear(),
      trip.fromMonth,
      0
    ).getDate();
    const currentDate = `${now.getFullYear()}-${getTwoDigits(
      trip.fromMonth
    )}-${currentLastDay}`;

    if (currentDate < trip.updatedAt) {
      const newYear = parseInt(trip.updatedAt.substring(0, 4)) + 1;
      title += `${newYear}년 ${getTwoDigits(trip.fromMonth)}월  -  `;
    } else {
      title += `${now.getFullYear()}년 ${getTwoDigits(trip.fromMonth)}월  -  `;
    }
  } else if (trip.from) {
    title += trip.from.substr(0, 7).replace("-", "년 ") + "월  -  ";
  } else if (trip.to) {
    title += trip.to.substr(0, 7).replace("-", "년 ") + "월  -  ";
  }
  if (trip.from && trip.to) {
    const fromD = new Date(trip.from);
    const toD = new Date(trip.to);
    const days = (toD.getTime() - fromD.getTime()) / (24 * 60 * 60 * 1000);
    title =
      title + `${days === 0 ? "당일치기" : `${days}박 ${days + 1}일`}  -  `;
  } else if (trip.length) {
    title =
      title +
      `${
        trip.length === 1 ? "당일치기" : `${trip.length - 1}박 ${trip.length}일`
      }  -  `;
  }
  return title;
};
const getTitle = (trip: SurveysType) => {
  let title = getPeriod(trip);
  if (trip.people) {
    title = title + `${trip.people}명`;
  }
  return title;
};

const Trip = ({
  trip,
  deleteMode,
  setShareSurvey,
  showDeleteDialog,
  callGetSurveys,
}: {
  trip: SurveysType;
  deleteMode: boolean;
  setShareSurvey: (value: SurveysType) => void;
  showDeleteDialog: (id: number) => void;
  callGetSurveys: () => Promise<void>;
}) => {
  const [, dispatchSurveyContext] = useContext(SurveyContext);
  const history = useHistory();

  const onMainButtonClick = async (status: number, id: number) => {
    if (status === 1 || status === 2) {
      try {
        const { data: survey } = await getSurvey(id);
        const route = routingToSurvey(survey);
        dispatchSurveyContext({ type: "UPDATE_SURVEY", value: survey });

        if (route === "preview" || route === "step-1-1") {
          history.push(`/survey/${route}`);
        } else {
          history.push(`/survey/${id}/${route}`);
        }
      } catch (e) {}
    } else if (status === 3) {
      callGetSurveys();
    } else if (status === 4) {
      history.push(`/trip/${id}`);
    }
  };

  return (
    <div className="w-full flex items-center">
      <button
        className="w-full h-24 sm:h-36 rounded-md sm:rounded-lg overflow-hidden mb-2 sm:mb-3 py-3 px-3.5 sm:py-4 sm:px-5 bg-center bg-cover text-white relative text-left"
        style={{
          backgroundImage: `url(${trip.mainImageUrl})`,
          boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.4)",
        }}
        onClick={() => onMainButtonClick(trip.status, trip.id)}
      >
        {trip.status === 4 && (
          <button
            className="absolute top-3.5 right-3.5 sm:top-5 sm:right-5 z-30 w-4 h-4 sm:w-6 sm:h-6"
            onClick={(e) => {
              e.stopPropagation();
              setShareSurvey(trip);
            }}
          >
            <Share className="h-full w-full" />
          </button>
        )}
        <div className="top-0 left-0 w-full h-full absolute bg-yoda-black-1 opacity-50 z-0"></div>
        <div
          className="top-1/2 left-0 w-full h-1/2 absolute z-10"
          style={{
            backgroundImage:
              "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
          }}
        ></div>
        <div className="w-full h-full z-20 relative flex flex-col justify-start">
          {(trip.status === 1 || trip.status === 2) && (
            <div className="body-bold-18">
              미완료 주문서
              <span className="ml-2 sm:ml-3 body-regular-14">
                {trip.location}
              </span>
            </div>
          )}
          {trip.status === 3 && (
            <div className="body-bold-18">
              일정 만드는 중
              <span className="ml-2 sm:ml-3 body-regular-14">
                {trip.location}
              </span>
            </div>
          )}
          {trip.status === 4 && (
            <div className="body-bold-18">{trip.location}</div>
          )}
          <div className="mt-1 sm:mt-1.5 body-bold-12 whitespace-pre">
            {getTitle(trip)}
          </div>
          <div className="flex-1"></div>
          <div className="flex justify-between items-end">
            {(trip.status === 3 || trip.status === 4) && (
              <div className="bg-white rounded sm:rounded-md px-2 sm:px-3 body-medium-12 text-yoda-black-1 whitespace-pre">
                {`주문일  ${trip.updatedAt.replace(/\-/g, ".")}`}
              </div>
            )}
            {trip.status !== 3 && (
              <div className="flex-1 text-right btn-bold-14 text-yoda-light-green">
                {trip.status === 4 ? "일정보기" : "주문 완료하기"}
              </div>
            )}
          </div>
        </div>
      </button>
      <div className="h-full w-12 sm:w-20 flex items-center justify-center">
        {deleteMode && (
          <button onClick={() => showDeleteDialog(trip.id)}>
            <Delete />
          </button>
        )}
      </div>
    </div>
  );
};

export default Trip;
