import { FavoriteType, HateCategoryType } from "../../api/Survey";
import {
  ParentInterestType,
  accommOptions,
  adultsAgeOptions,
  budgetOptions,
  kidsAgeOptions,
  transTimeOptions,
  transportOptions,
} from "../../util/surveyOptions";
import { useCallback, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as EditIcon } from "../../assets/Survey/overview/editIcon.svg";
import { MainContext } from "../../contexts/MainContext";
import { OptionContext } from "../../contexts/OptionContext";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import useDestination from "../../hooks/useDestination";
import usePurchaseConfirm from "../../hooks/usePurchaseConfirm";
import useQuery from "../../hooks/useQuery";

const getInterestChildren = (
  interests: ParentInterestType[],
  interest: string
) =>
  interests.filter((obj) => obj.name === interest).length > 0
    ? interests.filter((obj) => obj.name === interest)[0].value.split(",")
    : [""];

const SurveyValueBox = ({
  title,
  onClick,
  content,
  editable,
}: {
  title: string;
  onClick: () => void;
  content: string | JSX.Element;
  editable?: boolean;
}) => {
  return (
    <div className="mb-6 sm:mb-9">
      <div className="flex justify-between items-center mt-5 sm:mt-8">
        <span className="body-medium-15 text-yoda-black-2">{title}</span>
        {editable === false ? null : (
          <div className="flex items-center cursor-pointer" onClick={onClick}>
            <div className="w-8 sm:w-11">
              <EditIcon className="w-full h-full" />
            </div>
            <span className="body-medium-12 text-yoda-primary">수정</span>
          </div>
        )}
      </div>
      <div className="whitespace-pre-wrap body-regular-14 w-full mt-1.5 sm:mt-2.5 px-3.5 sm:px-5 py-2 sm:py-3 bg-yoda-gray-0 p-2.5 sm:p-4 break-words ">
        {content}
      </div>
    </div>
  );
};

const Survey_6_1 = () => {
  const [{ user }, dispatch] = useContext(MainContext);
  const [ctx, dispatchSurveyContext] = useContext(SurveyContext);
  const [
    {
      interestOptions,
      favoriteFoodOptions,
      favoriteDrinkOptions,
      hateFoodOptions,
    },
  ] = useContext(OptionContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const query = useQuery();
  const history = useHistory();

  const getInterestOptions = useCallback(
    () =>
      interestOptions
        .map((op) => ({
          name: op.name,
          value: op.value,
          children: op.children,
        }))
        .reduce((arr: { name: string; value: string }[], op) => {
          if (op.children.length > 0) {
            arr.push(...op.children);
          } else {
            arr.push(op);
          }
          return arr;
        }, []),
    [interestOptions]
  );
  const { isPurchasable, completePayment } = usePurchaseConfirm(surveyId);

  const getDates = useCallback(() => {
    if (
      ctx.from === "" &&
      ctx.to === "" &&
      ctx.fromMonth === 0 &&
      ctx.length === 0
    ) {
      return <div>&nbsp;</div>;
    }

    if (ctx.from !== "" && ctx.to !== "") {
      const newFrom = ctx.from.split("-");
      const newTo = ctx.to.split("-");
      const newFromMonthForm =
        newFrom[1][0] === "0" ? newFrom[1][1] : newFrom[1];
      const newToMonthForm = newTo[1][0] === "0" ? newTo[1][1] : newTo[1];

      const simplePeriod = Math.ceil(
        (new Date(ctx.to).getTime() - new Date(ctx.from).getTime()) /
          (1000 * 60 * 60 * 24)
      );
      const period =
        simplePeriod > 0
          ? `${simplePeriod}박 ${simplePeriod + 1}일`
          : "당일치기";

      return `${newFrom[0]}년 ${newFromMonthForm}월 ${newFrom[2]}일 ~ ${newTo[0]}년 ${newToMonthForm}월 ${newTo[2]}일  -  ${period}`;
    } else {
      const now = new Date();
      if (now.getMonth() + 1 > ctx.fromMonth) {
        return `아직 안 정했어요  -  ${now.getFullYear() + 1}년 ${
          ctx.fromMonth
        }월 - ${
          ctx.length === 1 ? "당일치기" : `${ctx.length - 1}박${ctx.length}일`
        }`;
      }

      return `아직 안 정했어요  -  ${now.getFullYear()}년 ${
        ctx.fromMonth
      }월  -  ${
        ctx.length === 1 ? "당일치기" : `${ctx.length - 1}박${ctx.length}일`
      }`;
    }
  }, [ctx.from, ctx.to, ctx.fromMonth, ctx.length]);

  const getPartyTypes = useCallback(() => {
    if (
      ctx.adults + ctx.kids === 0 ||
      (ctx.kids === 1 && ctx.kidsMinAge === 0) ||
      (ctx.kids > 1 && (ctx.kidsMinAge === 0 || ctx.kidsMaxAge === 0))
    ) {
      return <div>&nbsp;</div>;
    }

    const adultsForm = ctx.adults > 0 ? getAgeTypes(true) : "";
    const kidsForm = ctx.kids > 0 ? getAgeTypes(false) : "";
    return `${adultsForm}${adultsForm && kidsForm ? "\n" : ""}${kidsForm}`;
  }, [ctx.adults, ctx.kids, ctx.kidsMinAge, ctx.kidsMaxAge]);

  const getAgeTypes = useCallback(
    (isAdults: boolean) => {
      const newAgeList: { name: string; value: number; count: number }[] = [];

      (isAdults ? adultsAgeOptions : kidsAgeOptions).forEach((option) => {
        let obj = { count: 0 } as {
          name: string;
          value: number;
          count: number;
        };
        (isAdults ? ctx.adultsAge : ctx.kidsAge).forEach((adult) => {
          if (option.value === adult) {
            if (obj.name) {
              obj.count += 1;
            } else {
              obj = {
                name: option.name,
                value: option.value,
                count: obj.count + 1,
              };
              newAgeList.push(obj);
            }
          }
        });
      });
      const ageCount = newAgeList
        .map((age) => ` ${age.name}  -  ${age.count}명`)
        .join(", ");

      return `${isAdults ? "어른  - " : "아이  - "}${ageCount}`;
    },
    [ctx.adults, ctx.kids, ctx.kidsMinAge, ctx.kidsMaxAge]
  );

  const getDepartLocation = useCallback(() => {
    if (ctx.departLocation === "") {
      return <div>&nbsp;</div>;
    }
    return ctx.departLocation;
  }, [ctx.departLocation]);

  const getTransportTypes = useCallback(() => {
    if (
      ctx.transportTypes.length === 0 ||
      (ctx.transportTimes.length === 0 && ctx.destDetermined !== true)
    ) {
      return <div>&nbsp;</div>;
    }

    const transportForm =
      (transportOptions.find((op) => _.isEqual(op.value, ctx.transportTypes))
        ?.name || "") + (ctx.transportTimes.length > 0 ? "  -  " : "");

    let transTimeForm = "";
    if (
      ctx.transportTimes.length === 1 &&
      transTimeOptions.find((t) => t.value === ctx.transportTimes[0])
    ) {
      transTimeForm = transTimeOptions.find(
        (t) => t.value === ctx.transportTimes[0]
      )!.name;
    } else if (ctx.transportTimes.length === 3) {
      transTimeForm = "제한없음";
    } else if (ctx.transportTimes.includes(transTimeOptions[0].value)) {
      transTimeForm = "3시간 이내";
    } else {
      transTimeForm = "1.5시간 이상";
    }

    return `${transportForm} ${transTimeForm}`;
  }, [ctx.transportTypes, ctx.transportTimes]);

  const destination = useDestination("description") || ctx.destDescription;

  const getAccomm = useCallback(() => {
    if (ctx.accommAddr.length > 0 && ctx.accommCoords !== undefined) {
      return ctx.accommAddr;
    }
    const accommType = ctx.accommTypes
      .map((accomm) => accommOptions.find((op) => op.value === accomm)?.name)
      .filter((accomm): accomm is string => accomm !== undefined)
      .sort()
      .join(", ");

    const accommBudget = ctx.accommBudget
      .map((price) => budgetOptions.find((op) => op.value === price))
      .filter(
        (
          op: { name: string; order: number; value: string } | undefined
        ): op is { name: string; order: number; value: string } =>
          op !== undefined
      )
      .sort((op1, op2) => op1.order - op2.order);

    if (accommType === "" || accommBudget.length === 0) {
      return <div>&nbsp;</div>;
    }

    return accommBudget.length == 1 ? `${accommBudget[0].name}` : `${accommBudget[0].name} ~ ${
      accommBudget[accommBudget.length - 1].name
    }  -  ${accommType}`;
  }, [ctx.accommAddr, ctx.accommCoords, ctx.accommTypes, ctx.accommBudget]);

  const getInterestPriority = useCallback(() => {
    if (ctx.interestPriority.length < 1) {
      return <div>&nbsp;</div>;
    }

    const mountainWalkOptions = getInterestChildren(
      interestOptions,
      "등산/산책"
    );
    const drinkOptions = getInterestChildren(interestOptions, "밤에 술 한잔");
    const onlyMountain = ctx.interestPriority.every((ty) => {
      return mountainWalkOptions.indexOf(ty) > -1;
    });
    const onlyDrink = ctx.interestPriority.every((ty) => {
      return drinkOptions.indexOf(ty) > -1;
    });
    const option = getInterestOptions().find(
      (op) => op.value === ctx.interestPriority[0]
    )?.name;

    if (onlyMountain) {
      return "등산/산책";
    } else if (onlyDrink) {
      return "밤에 술 한잔";
    } else if (option) {
      return option.replace(/\/\n/g, "/");
    }
    return <div>&nbsp;</div>;
  }, [ctx.interestPriority, interestOptions, getInterestOptions]);

  const getInterests = useCallback(() => {
    const interests = ctx.interestTypes
      .map((ty) => getInterestOptions().find((op) => op.value === ty)?.name)
      .filter((ty): ty is string => ty !== undefined);
    if (interests.length === 0) {
      return <div>&nbsp;</div>;
    }
    return interests.join(", ").replace(/\/\n/g, "/");
  }, [ctx.interestTypes, getInterestOptions]);

  const getPref = useCallback(() => {
    if (
      ctx.isPopular === undefined ||
      ctx.isBusy === undefined ||
      ctx.isInsta === undefined
    ) {
      return <div>&nbsp;</div>;
    }

    return `${ctx.isPopular ? "인기 많은 곳" : "참신한 곳"}, ${
      ctx.isBusy ? "부지런히 다녀요" : "느긋하게 다녀요"
    }, ${ctx.isInsta ? "인스타 유명 장소 선호" : "인스타 유명 장소 비선호"}`;
  }, [ctx.isPopular, ctx.isBusy, ctx.isInsta]);

  const getFavoriteFoods = useCallback(
    (prefTypes: string[], options: FavoriteType[]) =>
      prefTypes
        .map((food) =>
          options
            .filter((option) => option.value === food)
            .map((option) => option.name)
        )
        .join(", "),
    [ctx.favoriteFoodTypes, ctx.favoriteDrinkTypes]
  );

  const getHateFoods = useCallback(
    (prefTypes: string[], options: HateCategoryType[]) =>
      prefTypes
        .map((food) =>
          options
            .flatMap((group) => group.children)
            .filter((option) => option.value === food)
            .map((option) => option.name)
        )
        .join(", "),
    [ctx.hateFoodTypes]
  );

  const getFoodPref = useCallback(() => {
    if (ctx.favoriteFoodTypes && ctx.favoriteDrinkTypes && ctx.hateFoodTypes) {
      const favoriteFoodQuestion = getFavoriteFoods(
        ctx.favoriteFoodTypes,
        favoriteFoodOptions
      );

      const favoriteDrinkQuestion =
        getFavoriteFoods(ctx.favoriteDrinkTypes, favoriteDrinkOptions) ||
        "술은 안마셔요";

      return `선호 음식  -  ${favoriteFoodQuestion}\n술  -  ${favoriteDrinkQuestion}\n`;
    }
    return "";
  }, [ctx.favoriteFoodTypes, ctx.favoriteDrinkTypes, ctx.hateFoodTypes]);

  const getPersonalInfo = useCallback(() => {
    if (ctx.gender === 0 || ctx.age === 0) {
      return <div>&nbsp;</div>;
    }

    return `${ctx.gender === 1 ? "남자" : "여자"}  -  ${
      ctx.age === 8
        ? ctx.age.toString().concat("0대 이상")
        : ctx.age.toString().concat("0대")
    } `;
  }, [ctx.gender, ctx.age]);

  useEffect(() => {
    if (
      query.get("paymentcomplete") === "true" &&
      isPurchasable &&
      user !== undefined
    ) {
      completePayment(ctx.destAct);
    }
  }, [isPurchasable, user, completePayment, ctx.destAct]);
  const onClickOrder = useCallback(async () => {
    try {
      if (isPurchasable) {
        if (user !== undefined) {
          if (user.mobile) {
            completePayment(ctx.destAct);
          } else {
            history.push(`/login?redirect_url=/survey/${surveyId}/step-6-1`);
          }
        } else {
          history.push(`/survey/${surveyId}/step-7-1`);
        }
      }
    } catch (e) {}
  }, [isPurchasable, user, surveyId, completePayment, ctx.destAct]);
  useEffect(() => {
    amplitude.getInstance().logEvent("6-1 order opened");
  }, []);
  const showEditAlert = (selection: string, url: string) => {
    dispatch({
      type: "UPDATE_DIALOG",
      value: {
        type: "DialogYesNo",
        content: (
          <div className="flex flex-col items-center rounded-t sm:rounded-t-md px-5 py-10 bg-white">
            <div className="mb-5 text-center text-xl font-bold">
              {selection}을 변경하면 선택하셨던 관심활동이 초기화됩니다. 계속
              진행하시겠습니까?
            </div>
          </div>
        ),
        onClickYes: () => {
          if (ctx.destFromWeeklyContents !== true) {
            dispatchSurveyContext({
              type: "UPDATE_INTEREST_TYPES",
              value: { interestTypes: [] },
            });
            dispatchSurveyContext({
              type: "UPDATE_INTEREST_PRIORITY",
              value: { interestPriority: [] },
            });
            saveSurvey(surveyId, {
              ...ctx,
              interestTypes: [],
              interestPriority: [],
            });
          }
          history.push(url);
        },
      },
    });
  };
  return (
    <div className="w-full survey-min-height">
      <div className="w-full h-full px-6 sm:px-9">
        <div>
          <SurveyTopBar prevPath={`/survey/${surveyId}/step-5-1`} />
        </div>
        <div>
          <div>
            <Title title={`‘주문하기’로 3분 안에\n여행일정을 받아보세요!`} />
            <div className="mt-1 sm:mt-2 body-regular-14 text-yoda-black-1 mb-6 sm:mb-9">
              답변 확인과 수정도 가능해요
            </div>
          </div>
          <button
            className={`h-12 sm:h-18 w-full rounded sm:rounded-md btn-bold-16 text-white ${
              isPurchasable ? "bg-yoda-primary" : "bg-gray-200"
            }`}
            onClick={onClickOrder}
          >
            주문하기
          </button>
        </div>
        <div>
          <div>
            {ctx.destFromWeeklyContents !== true && (
              <SurveyValueBox
                title="안심여행지 추천"
                onClick={() => history.push("/survey/preview")}
                content={
                  ctx.covidFree === undefined ? (
                    <div>&nbsp;</div>
                  ) : ctx.covidFree ? (
                    "안심여행지 우선 추천 받기"
                  ) : (
                    "해당 없음"
                  )
                }
              />
            )}
            <SurveyValueBox
              title="여행 날짜"
              onClick={() => showEditAlert("일정", "/survey/step-1-1")}
              content={getDates()}
            />
            <SurveyValueBox
              title="여행자 정보"
              onClick={() => history.push(`/survey/${surveyId}/step-1-2`)}
              content={getPartyTypes()}
            />
            <SurveyValueBox
              title="출발지"
              onClick={() => history.push(`/survey/${surveyId}/step-2-1`)}
              content={getDepartLocation()}
            />
            <SurveyValueBox
              title="교통 수단"
              onClick={() =>
                showEditAlert("교통 수단", `/survey/${surveyId}/step-2-3`)
              }
              content={getTransportTypes()}
            />
            {destination && (
              <SurveyValueBox
                title="선호하는 여행 지역"
                onClick={() =>
                  showEditAlert("지역", `/survey/${surveyId}/step-2-3`)
                }
                content={destination}
              />
            )}
            {((ctx.from && ctx.to && ctx.from !== ctx.to) ||
              (ctx.fromMonth > 0 && ctx.length > 1)) && (
              <SurveyValueBox
                title="숙소"
                onClick={() => history.push(`/survey/${surveyId}/step-3-1-1`)}
                content={getAccomm()}
              />
            )}
            <SurveyValueBox
              title="관심있는 활동"
              onClick={() => history.push(`/survey/${surveyId}/step-3-2`)}
              content={getInterests()}
            />
            <SurveyValueBox
              title="가장 관심있는 활동"
              onClick={() => history.push(`/survey/${surveyId}/step-3-3`)}
              content={getInterestPriority()}
              editable={ctx.destAct === 0}
            />
            <SurveyValueBox
              title="음식 취향"
              onClick={() => history.push(`/survey/${surveyId}/step-4-2`)}
              content={getFoodPref()}
            />
            <SurveyValueBox
              title="여행 취향"
              onClick={() => history.push(`/survey/${surveyId}/step-4-1`)}
              content={getPref()}
            />
            <SurveyValueBox
              title="주문자 정보"
              onClick={() => history.push(`/survey/${surveyId}/step-5-1`)}
              content={getPersonalInfo()}
            />
          </div>
        </div>
        <div>
          <button
            className={`h-12 sm:h-18 w-full rounded sm:rounded-md btn-bold-16 text-white ${
              isPurchasable ? "bg-yoda-primary" : "bg-gray-200"
            }`}
            onClick={onClickOrder}
          >
            주문하기
          </button>
        </div>
      </div>
    </div>
  );
};
export default Survey_6_1;
