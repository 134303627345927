import "rc-slider/assets/index.css";

import { isArraysSame, saveSurvey } from "../util";
import { transTimeOptions, transportOptions } from "../util/surveyOptions";
import { useCallback, useContext, useEffect, useState } from "react";

import { ReactComponent as Radio } from "../assets/Survey/radio.svg";
import { ReactComponent as RadioSelected } from "../assets/Survey/radioSelected.svg";
import { Range } from "rc-slider";
import { SurveyContext } from "../contexts/SurveyContext";
import Tooltip from "../assets/Survey/preview/tooltipSmall.png";
import _ from "lodash";

const isMobile = () => {
  let mobile = false;
  const userAgent = navigator.userAgent;
  (function (a) {
    if (
      //@ts-nocheck
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      //@ts-nocheck
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      mobile = true;
  })(userAgent || navigator.vendor);
  return mobile || userAgent === "YodaReactNative";
};

const useTransport = (surveyId: string, tooltipHide?: boolean) => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [isDriving, setIsDriving] = useState<boolean | undefined>();
  const [transTimes, setTransTimes] = useState<string[]>(
    transTimeOptions.map((option) => option.value)
  );
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    if (surveyContext.transportTypes.length > 0) {
      setIsDriving(
        _.isEqual(transportOptions[0].value, surveyContext.transportTypes)
      );
    } else if (tooltipHide !== true) {
      setShowTooltip(true);
    }
  }, [surveyContext.transportTypes]);
  const getSliderRangeValues = useCallback(() => {
    let min = 0;
    let max = 3;

    if (surveyContext.transportTimes[0] === transTimeOptions[1].value) {
      min = 1;
    } else if (surveyContext.transportTimes[0] === transTimeOptions[2].value) {
      min = 2;
    }
    if (
      surveyContext.transportTimes[surveyContext.transportTimes.length - 1] ===
      transTimeOptions[0].value
    ) {
      max = 1;
    } else if (
      surveyContext.transportTimes[surveyContext.transportTimes.length - 1] ===
      transTimeOptions[1].value
    ) {
      max = 2;
    }
    return [min, max];
  }, [surveyContext.transportTimes]);
  useEffect(() => {
    const [min, max] = getSliderRangeValues();
    setTransTimes(
      transTimeOptions.slice(min, max).map((option) => option.value)
    );
  }, [getSliderRangeValues]);
  const isDispatchable = useCallback(
    () => isDriving !== undefined && transTimes.length > 0,
    [isDriving, transTimes]
  );
  const dispatch = useCallback(() => {
    const value = {
      transportTypes: isDriving
        ? transportOptions[0].value
        : transportOptions[1].value,
      transportTimes: transTimes,
    };
    dispatchSurveyContext({ type: "UPDATE_TRANSPORT_TYPES", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [isDriving, transTimes, surveyId, surveyContext]);

  const renderComponent = (
    <div className="mt-6 sm:mt-9">
      <div
        className={
          surveyContext.destType === "anything" ? "" : "flex items-center"
        }
        onClick={() => setShowTooltip(false)}
      >
        <div className="heading-bold-16 text-yoda-black-2">교통수단</div>
        <div
          className={`flex items-center text-yoda-black-1 ${
            surveyContext.destType === "anything" ? "mt-2 sm:mt-3" : ""
          }`}
        >
          <button
            className={`flex items-center ${
              surveyContext.destType === "anything" ? "" : "ml-3.5 sm:ml-5"
            }`}
            onClick={() => setIsDriving(true)}
          >
            {isDriving ? (
              <RadioSelected className="w-5 h-5 sm:w-7 sm:h-7" />
            ) : (
              <Radio className="w-5 h-5 sm:w-7 sm:h-7" />
            )}
            <div className="ml-2.5 sm:ml-4 body-regular-14 text-left inline-block">
              자동차
            </div>
            <div className="body-regular-12 whitespace-pre inline-block">
              {" "}
              (렌터카 포함)
            </div>
          </button>
          <button
            className="flex items-center ml-8 sm:ml-12"
            onClick={() => setIsDriving(false)}
          >
            {isDriving === false ? (
              <RadioSelected className="w-5 h-5 sm:w-7 sm:h-7" />
            ) : (
              <Radio className="w-5 h-5 sm:w-7 sm:h-7" />
            )}
            <div className="ml-2.5 sm:ml-4 body-regular-14 text-yoda-black-1  text-left">
              대중교통
            </div>
          </button>
        </div>
      </div>
      <div
        className={`mt-4 sm:mt-6 pb-7 sm:pb-10 ${
          surveyContext.destType === "anything" ? "" : "flex items-center"
        } relative`}
        onClick={() => setShowTooltip(false)}
      >
        <div className="heading-bold-16 text-yoda-black-2">이동시간</div>
        <div
          className={`flex-1 slider-parent ${
            surveyContext.destType === "anything"
              ? "mt-3 sm:mt-4"
              : "ml-3.5 sm:ml-5"
          }`}
        >
          <Range
            onChange={(values) => {
              setTransTimes(
                transTimeOptions
                  .slice(values[0], values[1])
                  .map((option) => option.value)
              );
            }}
            pushable={true}
            marks={{
              0: {
                style: {
                  transform: "translateX(-0.25rem)",
                },
                label: "0시간",
              },
              1: {
                style: { transform: "translateX(-50%)" },
                label: "1.5시간",
              },
              2: {
                style: {
                  transform: `translateX(-${isMobile() ? "1.6" : "2.3"}rem)`,
                },
                label: "3시간",
              },
              3: {
                style: {
                  transform: `translateX(-${isMobile() ? "3" : "4"}rem)`,
                  whiteSpace: "nowrap",
                },
                label: "제한없음",
              },
            }}
            max={3}
            dots={true}
            allowCross={false}
            defaultValue={getSliderRangeValues()}
          />
        </div>
        {showTooltip && (
          <div className="absolute tooltip-parent-transport z-40">
            <img
              className="absolute top-0 left-0 w-full h-full object-fill"
              src={Tooltip}
            />
            <div className="z-10 relative">
              <div className="body-regular-12 text-yoda-black-1">
                먼저 교통수단과 이동시간을 선택해주세요.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return {
    saved:
      ((isDriving === true &&
        _.isEqual(transportOptions[0].value, surveyContext.transportTypes)) ||
        (isDriving === false &&
          _.isEqual(
            transportOptions[1].value,
            surveyContext.transportTypes
          ))) &&
      isArraysSame(transTimes, surveyContext.transportTimes),
    isDispatchable,
    dispatch,
    renderComponent,
    isDriving,
    transTimes,
  };
};

export default useTransport;
