import "./weekly.css";

import { Link, useHistory, useParams } from "react-router-dom";
import { SurveyContext, SurveyState } from "../../contexts/SurveyContext";
import { createSurvey, getSurvey, getSurveys } from "../../api/Survey";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Clock } from "../../assets/Weekly/clock.svg";
import { ReactComponent as LinkIcon } from "../../assets/Weekly/link.svg";
import { ReactComponent as Location } from "../../assets/Weekly/location.svg";
import { ReactComponent as LocationLight } from "../../assets/Weekly/locationLight.svg";
import { MainContext } from "../../contexts/MainContext";
import { ReactComponent as Phone } from "../../assets/Weekly/phone.svg";
import ReactGA from "react-ga";
import Slider from "react-slick";
import Title from "./Title";
import { WeeklyPOI } from "./type";
import { getWeeklyPoi } from "../../api/Weekly";
import { routingToSurvey } from "../../util";
import useHideScrollTop from "../../hooks/useHideScrollTop";
import useOnclickOutside from "react-cool-onclickoutside";

const Poi = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [{ user }, dispatchMainContext] = useContext(MainContext);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [weeklyPoi, setWeeklyPoi] = useState<WeeklyPOI>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [surveyMake, setSurveyMake] = useState(false);
  const [surveyId, setSurveyId] = useState<string | number | undefined>();
  const [blogIndex, setBlogIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  useHideScrollTop();
  useEffect(() => {
    const callGetWeeklyPoi = async () => {
      try {
        const { data: poiData } = await getWeeklyPoi(id);
        setWeeklyPoi(poiData);
        ReactGA.event({
          category: "주간여다POI방문",
          action: "weekly_poi_visit",
          label: `visit-${poiData.id}`,
        });
        if (poiData.id === 11788) {
          ReactGA.event({
            category: "주간여다POI",
            action: "weekly_poi_visit",
            label: "보령머드 주간여다 POI 방문",
          });
        }
      } catch {}
    };
    callGetWeeklyPoi();
  }, []);
  useEffect(() => {
    const paddingTop = document.getElementById("padding-top");
    const paddingBottom = document.getElementById("padding-bottom");
    if (paddingTop) {
      paddingTop.classList.remove("pt-14");
      paddingTop.classList.remove("sm:pt-20");
    }
    if (paddingBottom) {
      paddingBottom.classList.add("h-full");
      paddingBottom.classList.remove("overall-padding-bottom");
    }
    return () => {
      if (paddingTop) {
        paddingTop.classList.add("pt-14");
        paddingTop.classList.add("sm:pt-20");
      }
      if (paddingBottom) {
        paddingBottom.classList.remove("h-full");
        paddingBottom.classList.add("overall-padding-bottom");
      }
    };
  }, []);
  useEffect(() => {
    const eventListener = () =>
      setScrollPosition(document.documentElement.scrollTop);
    document.addEventListener("scroll", eventListener);
    return () => document.addEventListener("scroll", eventListener);
  });
  const scrollPos = useCallback(() => {
    const LIMIT = 500;
    if (scrollPosition > LIMIT) return 100;
    return (scrollPosition / LIMIT) * 100;
  }, [scrollPosition]);
  const closeModal = () => {
    dispatchMainContext({
      type: "UPDATE_DIALOG",
      value: {
        type: undefined,
        content: undefined,
      },
    });
  };
  const onClickYes = useCallback(async () => {
    if (weeklyPoi === undefined) {
      return;
    }
    try {
      if (user !== undefined) {
        const { data: surveys } = await getSurveys();
        const sv = surveys.find((survey) => survey.status < 3);
        if (sv) {
          const { data: survey } = await getSurvey(sv.id);
          dispatchSurveyContext({ type: "UPDATE_SURVEY", value: survey });
          setSurveyId(sv.id);
        } else {
          const { data: survey } = await getSurvey(surveys[0].id);
          dispatchSurveyContext({ type: "UPDATE_SURVEY", value: survey });
          const {
            data: { id },
          } = await createSurvey();
          setSurveyId(id);
        }
      } else {
        const sv = localStorage.getItem("survey");
        if (sv) {
          const survey: SurveyState = JSON.parse(sv);
          dispatchSurveyContext({ type: "UPDATE_SURVEY", value: survey });
        }
        setSurveyId("new");
      }

      dispatchSurveyContext({
        type: "UPDATE_SURVEY_FROM_WEEKLY",
        value: {
          destDescription: weeklyPoi.description,
          destLocation: weeklyPoi.location,
          destAct: weeklyPoi.id,
          interestPriority: [weeklyPoi.value],
        },
      });
      setSurveyMake(true);
      ReactGA.event({
        category: "주간여다POI일정표생성",
        action: "weekly_poi_to_survey",
        label: `survey-${weeklyPoi.id}`,
      });
      if (weeklyPoi.id === 11788) {
        ReactGA.event({
          category: "주간여다POI",
          action: "weekly_poi_visit",
          label: "보령머드 일정표 생성",
        });
      }
    } catch (e) {
      alert("알 수 없는 에러가 발생하였습니다.");
    }
  }, [user, weeklyPoi]);
  const ref = useOnclickOutside((e) => {
    if (e.type !== "mousedown") {
      return;
    }
    closeModal();
  });
  useEffect(() => {
    if (
      surveyContext.destFromWeeklyContents &&
      surveyMake &&
      surveyId !== undefined
    ) {
      const route = routingToSurvey(surveyContext);
      if (route === "preview" || route === "step-1-1") {
        history.push(`/survey/${route}`);
      } else {
        history.push(`/survey/${surveyId}/${route}`);
      }
      closeModal();
    }
  }, [surveyContext, surveyId, surveyMake]);
  const onClickSurvey = () =>
    dispatchMainContext({
      type: "UPDATE_DIALOG",
      value: {
        type: "Dialog",
        content: (
          <div className="h-full flex justify-center items-center z-10 relative">
            <div
              ref={ref}
              className="h-28 sm:h-40 bg-white pl-4 sm:pl-6 pt-3 sm:pt-5 pb-4 sm:pb-6 rounded sm:rounded-md w-62 sm:w-96 flex flex-col justify-between"
            >
              <div className="w-full body-regular-14 pr-4 sm:pr-6">
                지금 이 여행지를 포함하여 일정을 만드시겠습니까?
              </div>
              <div className="flex justify-end pr-5 sm:pr-8">
                <button onClick={closeModal}>
                  <div className="text-yoda-gray-4 btn-bold-14 mr-7 sm:mr-10">
                    나중에
                  </div>
                </button>
                <button onClick={onClickYes}>
                  <div className="text-yoda-primary btn-bold-14">네</div>
                </button>
              </div>
            </div>
          </div>
        ),
      },
    });

  if (weeklyPoi === undefined) {
    return <div className="w-full h-screen"></div>;
  }
  return (
    <div className="w-full h-full flex flex-col items-start">
      <Title title="추천 여행지" scrollPos={scrollPos()} />
      <Link
        className="w-full"
        to={`/weekly/photo?image_url=${weeklyPoi.mainImageUrl}`}
      >
        <div
          className="w-full h-56 sm:h-80 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${weeklyPoi.mainImageUrl})` }}
        />
      </Link>
      <div className="w-full px-6 sm:px-9">
        <div className="flex items-center pt-7 sm:pt-10">
          <Location />
          <div className="ml-1 sm:ml-1.5 btn-bold-14 text-yoda-gray-4">{`${weeklyPoi.areaLarge} ${weeklyPoi.areaSmall}`}</div>
        </div>
        <div className="mt-3 sm:mt-5 card-regular-14 text-yoda-black-3">
          {weeklyPoi.title}
        </div>
        <div className="mt-1 sm:mt-1.5 heading-bold-24 text-yoda-black-1 leading-9 sm:leading-normal">
          <div>{weeklyPoi.areaSmall}</div>
          <div>{weeklyPoi.showName}</div>
        </div>
        <div className="body-bold-18 text-yoda-green-dark mt-5 sm:mt-7">
          추천한 이유는?
        </div>
        <div
          className="mt-1 sm:mt-1.5 body-regular-16 w-full"
          style={{ lineHeight: "1.875" }}
          dangerouslySetInnerHTML={{ __html: weeklyPoi.description1 }}
        />
      </div>
      <div className="w-full mt-10 sm:mt-16 flex overflow-x-auto px-6 sm:px-9">
        {weeklyPoi.insta.map((insta, index) => (
          <a href={insta.link} target="_blank">
            <div
              className={`w-60 h-60 sm:w-96 sm:h-96 relative bg-center bg-no-repeat bg-cover flex flex-col p-3 sm:p-5 ${
                index === 0 ? "" : "ml-0.5 sm:ml-1"
              }`}
              style={{ backgroundImage: `url(${insta.thumbnailUrl})` }}
            >
              <div className="flex-grow"></div>
              {insta.authorName && (
                <div className="card-regular-13 text-white">{`@${insta.authorName}`}</div>
              )}
            </div>
          </a>
        ))}
      </div>
      <div className="w-full px-6 sm:px-9 mt-10 sm:mt-16">
        <div className="body-bold-18 text-yoda-green-dark mt-5 sm:mt-7">
          여기서 꼭 해봐야 할 것은?
        </div>
        <div
          className="mt-1 sm:mt-1.5 body-regular-16 w-full"
          style={{ lineHeight: "1.875" }}
          dangerouslySetInnerHTML={{ __html: weeklyPoi.description2 }}
        />
      </div>
      <div className="w-full mt-8 sm:mt-12 border-b border-yoda-gray-2"></div>
      <div className="w-full mt-8 sm:mt-12 px-6 sm:px-9">
        <div className="btn-bold-16 text-yoda-black-1">일반정보</div>
        {weeklyPoi.address && (
          <div className="flex items-center mt-2.5 sm:mt-4">
            <LocationLight className="w-5 h-5 sm:w-7 sm:h-7" />
            <div className="ml-3 sm:ml-5 body-regular-14">
              {weeklyPoi.address}
            </div>
          </div>
        )}
        {weeklyPoi.telephone && (
          <div className="flex items-center mt-2.5 sm:mt-4">
            <Phone className="w-5 h-5 sm:w-7 sm:h-7" />
            <div className="ml-3 sm:ml-5 body-regular-14">
              {weeklyPoi.telephone}
            </div>
          </div>
        )}
        {weeklyPoi.websiteUrl && (
          <div className="flex items-center mt-2.5 sm:mt-4">
            <LinkIcon className="w-5 h-5 sm:w-7 sm:h-7" />
            <a
              target="_blank"
              href={weeklyPoi.websiteUrl}
              className="ml-3 sm:ml-5 body-regular-14 underline"
            >
              링크
            </a>
          </div>
        )}
        {weeklyPoi.openHours && (
          <div className="flex items-center mt-2.5 sm:mt-4">
            <Clock className="w-5 h-5 sm:w-7 sm:h-7" />
            <div className="ml-3 sm:ml-5 body-regular-14">
              {weeklyPoi.openHours}
            </div>
          </div>
        )}
      </div>
      {weeklyPoi.menu.content.length > 0 && (
        <>
          <div className="w-full mt-8 sm:mt-12 border-b border-yoda-gray-2"></div>
          <div className="w-full mt-8 sm:mt-12 px-6 sm:px-9">
            <div className="btn-bold-16 text-yoda-black-1">메뉴</div>
            {weeklyPoi.menu.content.map((menu) => (
              <div className="text-yoda-black-2">
                <div className="btn-bold-14 mt-3 sm:mt-5">{menu.name}</div>
                <div className="body-regular-14 mt-1 sm:mt-1.5">
                  {menu.price}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="w-full mt-8 sm:mt-12 border-b border-yoda-gray-2"></div>
      <div className="w-full mt-8 sm:mt-12 pb-20 sm:pb-32">
        <div className="btn-bold-16 px-6 sm:px-9 text-yoda-black-1">후기</div>
        <Slider
          ref={sliderRef}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          accessibility={true}
          arrows={false}
          className="w-full h-full mt-1 sm:mt-1.5"
          rows={3}
          afterChange={(i) => setBlogIndex(i)}
        >
          {weeklyPoi.naverBlogs.map((blog, blogIdx) => (
            <div key={blogIdx} className="mt-2 sm:mt-3 px-6 sm:px-9">
              <a href={blog.url} target="_blank" className="w-full">
                <div className="flex w-full">
                  <img
                    className="w-16 h-16 sm:w-24 sm:h-24 object-cover rounded"
                    src={blog.imageUrl}
                    alt="thumbnail"
                  ></img>
                  <div className="ml-2 sm:ml-3 weekly-poi-blog-descriptino-width">
                    <div className="btn-bold-14 truncate text-yoda-black-2">
                      {blog.title}
                    </div>
                    <div className="mt-1 sm:mt-1.5 line-clamp-2 text-yoda-black-3 body-regular-12">
                      {blog.description}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
        <div className="w-full flex-shrink-0 flex flex-row justify-center items-center mt-4 sm:mt-6 h-1.5 sm:h-2.5">
          {Array.from(Array(Math.ceil(weeklyPoi.naverBlogs.length / 3))).map(
            (v, index) => (
              <button
                className={`mx-1 sm:mx-1.5 w-1.5 sm:w-2.5 h-full rounded-md sm:rounded-xl ${
                  index === blogIndex ? "bg-yoda-primary" : "bg-yoda-gray-3"
                }`}
                onClick={() => sliderRef.current?.slickGoTo(index)}
              ></button>
            )
          )}
        </div>
      </div>
      <div className="weekly-bottom w-full flex items-center px-6 sm:px-9 border-t border-yoda-gray-1">
        <button
          className="h-12 sm:h-16 w-full bg-yoda-primary rounded sm:rounded-md text-white btn-bold-16"
          onClick={onClickSurvey}
        >
          이 장소를 포함한 일정 만들기
        </button>
      </div>
    </div>
  );
};

export default Poi;
