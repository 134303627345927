import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import Fuse from "fuse.js";
import { MainContext } from "../../contexts/MainContext";
import { OptionContext } from "../../contexts/OptionContext";
import { SearchContext } from "../../contexts/SearchContext";
import { ReactComponent as SearchIcon } from "../../assets/Inputs/searchIcon.svg";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import _ from "lodash";
import amplitude from "amplitude-js";
import { saveSurvey } from "../../util";
import { transportOptions } from "../../util/surveyOptions";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();
const Survey_2_4_5 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [searchContext, dispatchSearchContext] = useContext(SearchContext);
  const [, dispatchMainContext] = useContext(MainContext);
  const [{ destCityOptions }] = useContext(OptionContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [isDriving, setIsDriving] = useState<boolean | undefined>();

  useEffect(() => {
    if (surveyContext.transportTypes.length > 0) {
      setIsDriving(
        _.isEqual(transportOptions[0].value, surveyContext.transportTypes)
      );
    }
  }, [surveyContext.transportTypes]);
  useEffect(() => {
    if (searchContext.option === "") {
      dispatchSearchContext({
        type: "UPDATE_OPTION",
        value: surveyContext.destName,
      });
    }
  }, [searchContext.option, surveyContext.destName]);

  const isDispatchable = useCallback(
    () => searchContext.option.length > 0 && isDriving !== undefined,
    [searchContext.option, isDriving]
  );
  const dispatch = useCallback(() => {
    const value = {
      destName:
        searchContext.option.indexOf(" (") > 0
          ? searchContext.option.substring(
              0,
              searchContext.option.indexOf(" (")
            )
          : searchContext.option,
      destDetermined: true,
      destType: undefined,
      destCity: "",
      destAct: 0,
      destFood: 0,
    };
    dispatchSurveyContext({ type: "UPDATE_DESTINATION", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [searchContext.option, surveyId, surveyContext]);
  const moveToSearch = useCallback(() => {
    if (isDriving == undefined) {
      dispatchMainContext({
        type: "UPDATE_DIALOG",
        value: {
          type: "DialogError",
          content: "먼저 교통수단을 골라주세요.",
        },
      });
      return;
    }
    const transValue = {
      transportTypes: isDriving
        ? transportOptions[0].value
        : transportOptions[1].value,
      transportTimes: [],
    };
    dispatchSurveyContext({
      type: "UPDATE_TRANSPORT_TYPES",
      value: transValue,
    });
    saveSurvey(surveyId, { ...surveyContext, ...transValue });
    history.push("/survey/search");
  }, [surveyId, surveyContext, isDriving]);

  useEffect(() => {
    if (isDriving === false) {
      const destName =
        searchContext.option.indexOf(" (") > 0
          ? searchContext.option.substring(
              0,
              searchContext.option.indexOf(" (")
            )
          : searchContext.option;

      const isTransitOkay = destCityOptions.find(
        (option) => option.name === destName
      )?.isTransitOkay;
      if (isTransitOkay === false) {
        dispatchMainContext({
          type: "UPDATE_DIALOG",
          value: {
            type: "DialogError",
            content: `대중교통으로 여행이 어려운 지역이에요.\n다른 곳을 골라주세요.`,
            onClickOuter: () =>
              dispatchSearchContext({
                type: "UPDATE_OPTION",
                value: "",
              }),
          },
        });
      }
    }
  }, [isDriving, destCityOptions]);
  const onChange = useCallback(
    (input: string) => {
      if (input.length === 0) {
        dispatchSearchContext({ type: "UPDATE_SEARCH_OPTIONS", value: [] });
      } else {
        const fuse = new Fuse(
          destCityOptions.map((city) => {
            if (isDriving === false && city.isTransitOkay === false) {
              return `${city.name} (대중교통 불가)`;
            } else if (surveyContext.covidFree && city.covidFree === false) {
              return `${city.name} (안심여행 불가)`;
            } else if (city.isActive === false) {
              return `${city.name} (추천장소 부족)`;
            }
            return city.name;
          })
        );
        const options = fuse.search(input).map((fu) => ({
          valid: !fu.item.includes("불가") && !fu.item.includes("부족"),
          value: fu.item,
          onClick: () => {
            if (fu.item.includes("대중교통 불가")) {
              dispatchMainContext({
                type: "UPDATE_DIALOG",
                value: {
                  type: "DialogError",
                  content: `대중교통으로 여행이 어려운 지역이에요.\n다른 곳을 골라주세요.`,
                },
              });
            } else if (fu.item.includes("안심여행 불가")) {
              dispatchMainContext({
                type: "UPDATE_DIALOG",
                value: {
                  type: "DialogError",
                  content: `안심여행이 불가능한 지역이에요.\n다른 곳을 골라주세요.`,
                },
              });
            } else if (fu.item.includes("추천장소 부족")) {
              dispatchMainContext({
                type: "UPDATE_DIALOG",
                value: {
                  type: "DialogError",
                  content: `아직 여다가 추천드릴 장소가\n충분치 않은 지역이에요.\n다른 곳을 골라주세요.`,
                },
              });
            } else {
              dispatchSearchContext({
                type: "UPDATE_OPTION",
                value: fu.item,
              });
              history.goBack();
            }
          },
        }));
        dispatchSearchContext({
          type: "UPDATE_SEARCH_OPTIONS",
          value: options,
        });
      }
    },
    [surveyContext.covidFree, isDriving, destCityOptions]
  );

  useEffect(() => {
    amplitude.getInstance().logEvent("2-4-5 to search opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("2-4-5 to search activated", properties);
    }
  }, [isDispatchable]);

  useEffect(() => {
    dispatchSearchContext({
      type: "UPDATE_SEARCH_FORM",
      value: {
        options: [],
        placeholder: "지역명 입력",
        navTitle: "어디로 어떻게 가세요?",
        onChange,
        errorMsg: `대중교통으로 여행이 어려운 지역이에요.\n다른 곳을 골라주세요.`,
      },
    });
  }, [onChange]);

  const onClickNext = () => {
    dispatch();
    if (searchContext.option === "제주특별자치도") {
      history.push(`/survey/${surveyId}/step-jeju`);
    } else {
      history.push(`/survey/${surveyId}/step-2-5`);
    }
  };

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath={`/survey/${surveyId}/step-2-3`} />
      <SurveyStep step={4} />
      <Title title="어디로 어떻게 가세요?" />
      <div className="w-full mt-6 sm:mt-9 flex items-center">
        <div className="body-medium-15 text-yoda-black-2">교통수단</div>
      </div>
      <div className="mt-1.5 sm:mt-2.5 w-full grid grid-cols-2 gap-2 sm:gap-3 h-12 sm:h-18">
        <button
          className={`w-full h-full rounded sm:rounded-md ${
            isDriving === true
              ? "bg-yoda-primary text-yoda-gray-0"
              : "bg-yoda-gray-2 text-yoda-gray-4"
          }`}
          onClick={() => setIsDriving(true)}
        >
          <div className="btn-bold-16 inline-block">자동차</div>
          <div className="body-medium-12 inline-block whitespace-pre">
            {" "}
            (렌터카 포함)
          </div>
        </button>
        <button
          className={`w-full h-full rounded sm:rounded-md btn-bold-16 ${
            isDriving === false
              ? "bg-yoda-primary text-yoda-gray-0"
              : "bg-yoda-gray-2 text-yoda-gray-4"
          }`}
          onClick={() => setIsDriving(false)}
        >
          대중교통
        </button>
      </div>
      <div className="w-full mt-6 sm:mt-9 body-medium-15 text-yoda-black-2">
        여행지
      </div>
      <div className="w-full h-full mt-1.5 sm:mt-2.5">
        <div className="w-full h-12 sm:h-18 flex items-center border rounded sm:rounded-md border-yoda-gray-4">
          <input
            className="flex-1 px-4 sm:px-6 py-2 sm:py-4 truncate caret-yoda-primary body-regular-16 text-yoda-black-1 rounded sm:rounded-md"
            type="text"
            placeholder="지역명 입력"
            value={searchContext.option}
            onClick={moveToSearch}
            readOnly
          />
          <button className="mr-3 sm:mr-4 w-8 sm:w-12" onClick={moveToSearch}>
            <SearchIcon className="w-full h-full" />
          </button>
        </div>
      </div>
      {searchContext.option && (
        <div className="mt-7">
          <div className="mr-10 text-right text-lg font-base font-medium text-yoda-green-dark">
            도착지
          </div>
          <div className="mt-1.5 sm:mt-2.5 flex justify-end items-center">
            <div className="heading-bold-24 text-yoda-black-1">
              {searchContext.option.length > 0 ? (
                <div>
                  {searchContext.option.indexOf(" (") > 0
                    ? searchContext.option.substring(
                        0,
                        searchContext.option.indexOf(" (")
                      )
                    : searchContext.option}
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
            {searchContext.option.length > 0 && (
              <button
                className="w-6 sm:w-9 ml-2.5"
                onClick={() =>
                  dispatchSearchContext({
                    type: "UPDATE_OPTION",
                    value: "",
                  })
                }
              >
                <ClearIcon className="w-full h-full" />
              </button>
            )}
          </div>
        </div>
      )}
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isDispatchable()}
        prevPath={`/survey/${surveyId}/step-2-3`}
      />
    </div>
  );
};

export default Survey_2_4_5;
