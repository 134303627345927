import React, { useCallback, useContext, useEffect, useState } from "react";

import { ReactComponent as LeftArrow } from "../../assets/Survey/left.svg";
import { Link } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import useQuery from "../../hooks/useQuery";

const SignupSuccess = () => {
  const query = useQuery();
  const [redirectUrl, setRedirectUrl] = useState("/mypage");
  const [{ user }] = useContext(MainContext);

  useEffect(() => {
    const redirect_url = query.get("redirect_url");
    if (redirect_url) {
      setRedirectUrl(redirect_url);
    }
  }, [query]);
  const getDashedMobile = useCallback(() => {
    if (user === undefined || user.mobile === undefined) return "";
    return `${user.mobile.substring(0, 3)}-${user.mobile.slice(
      3,
      -4
    )}-${user.mobile.substring(user.mobile.length - 4, user.mobile.length)}`;
  }, [user]);

  if (user === undefined) {
    return <div></div>;
  }
  return (
    <div
      className={`w-full bg-white survey-min-height px-6 sm:px-9 ${
        !redirectUrl.includes("step-6-1") && "pt-12 sm:pt-16"
      }`}
    >
      {redirectUrl.includes("step-6-1") && (
        <div className="w-full h-12 sm:h-20 relative flex items-center justify-between survey-nav-bar mb-3 sm:mb-5">
          <Link className="w-6 sm:w-9" to={redirectUrl}>
            <LeftArrow className="w-full h-full" />
          </Link>
        </div>
      )}
      <div className="heading-bold-24 leading-tight text-yoda-black-1 whitespace-pre-line break-words">
        {`${user.name}님,\n가입이 완료되었습니다!`}
      </div>
      <div className="mt-3 sm:mt-5 mb-6 sm:mb-9">
        <div className="text-yoda-primary body-medium-15 sm:mb-1">
          휴대폰 번호
        </div>
        <div className="text-yoda-black-1 body-regular-16">
          {getDashedMobile()}
        </div>
      </div>
      <Link to={redirectUrl}>
        <div className="w-full h-12 sm:h-18 bg-yoda-primary rounded sm:rounded-md flex items-center justify-center text-white btn-bold-16">
          확인
        </div>
      </Link>
    </div>
  );
};

export default SignupSuccess;
