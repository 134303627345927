import "./weekly.css";

import { WeeklyPOIs, WeeklyTrips } from "./type";
import { getWeeklyPois, getWeeklyTrips } from "../../api/Weekly";
import { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Home } from "../../assets/Survey/home.svg";
import { ReactComponent as LeftArrow } from "../../assets/Survey/left.svg";
import { Link } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import ReactGA from "react-ga";
import Slider from "react-slick";
import { ReactComponent as TimelapseWhite } from "../../assets/Weekly/timelapse_circle_white.svg";
import { WeeklyContext } from "../../contexts/WeeklyContext";
import { getSimpleDays } from "../../util";
import useHideScrollTop from "../../hooks/useHideScrollTop";

const isMobile = () => {
  let mobile = false;
  const userAgent = navigator.userAgent;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      mobile = true;
  })(userAgent || navigator.vendor);
  return mobile || userAgent === "YodaReactNative";
};

const Board = () => {
  const [{ user, getMeDone }] = useContext(MainContext);
  const [{ pois, trips, index }, dispatchWeeklyContext] =
    useContext(WeeklyContext);
  const [poiIndexing, setPoiIndexing] = useState(true);
  const [weeklyPois, setWeeklyPois] = useState<WeeklyPOIs>({
    date: "",
    title: "",
    pois: [],
  });
  const [weeklyTrips, setWeeklyTrips] = useState<WeeklyTrips>({
    title: "",
    trips: [],
  });
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderHeight, setSliderHeight] = useState(0);
  const [weeklyIndex, setWeeklyIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  useHideScrollTop();
  useEffect(() => {
    ReactGA.event({
      category: "주간여다방문",
      action: "weekly_visit",
      label: "주간여다 메인페이지 방문",
    });
  }, []);
  useEffect(() => {
    sliderRef.current?.slickGoTo(index, true);
    setWeeklyIndex(index);
  }, []);
  useEffect(() => {
    const callGetWeekly = async () => {
      try {
        const { data: poisData } = await getWeeklyPois();
        const { data: tripsData } = await getWeeklyTrips();
        setWeeklyPois(poisData);
        setWeeklyTrips(tripsData);
        dispatchWeeklyContext({ type: "UPDATE_POIS", value: poisData });
        dispatchWeeklyContext({ type: "UPDATE_TRIPS", value: tripsData });
      } catch {}
    };
    if (pois === undefined || trips === undefined) {
      if (getMeDone) {
        callGetWeekly();
      }
    } else {
      setWeeklyPois(pois);
      setWeeklyTrips(trips);
    }
  }, [pois, trips, getMeDone]);
  useEffect(() => {
    const slider = document.getElementById("contents-slider");
    if (slider && weeklyPois.pois.length > 0) {
      setSliderWidth(slider.clientWidth);
      setSliderHeight(slider.clientHeight);
    }
  }, [weeklyPois]);
  useEffect(() => {
    const firstHeightFull = document.getElementById("first-h-full");
    const paddingTop = document.getElementById("padding-top");
    const paddingBottom = document.getElementById("padding-bottom");
    if (firstHeightFull) {
      firstHeightFull.classList.add("h-full");
    }
    if (paddingTop) {
      paddingTop.classList.remove("pt-14");
      paddingTop.classList.remove("sm:pt-20");
    }
    if (paddingBottom) {
      paddingBottom.classList.add("h-full");
    }
    return () => {
      if (firstHeightFull) {
        firstHeightFull.classList.remove("h-full");
      }
      if (paddingTop) {
        paddingTop.classList.add("pt-14");
        paddingTop.classList.add("sm:pt-20");
      }
      if (paddingBottom) {
        paddingBottom.classList.remove("h-full");
      }
    };
  }, []);
  useEffect(() => {
    dispatchWeeklyContext({ type: "UPDATE_INDEX", value: weeklyIndex });
    setPoiIndexing(weeklyIndex === 0 || weeklyIndex < weeklyPois.pois.length);
  }, [weeklyIndex, weeklyPois]);

  return (
    <div className="w-full h-full flex flex-col items-start">
      <div className="w-full h-12 sm:h-20 flex-shrink-0 survey-nav-bar border-b border-yoda-gray-1">
        <div className="w-full h-full px-6 sm:px-9 flex items-center justify-between">
          <Link className="w-6 sm:w-9" to="/">
            <LeftArrow className="w-full h-full" />
          </Link>
          <div className="btn-bold-16">주간 여다</div>
          <Link className="w-6 sm:w-9" to="/">
            <Home className="w-full h-full" />
          </Link>
        </div>
      </div>
      <div className="w-full px-6 sm:px-9">
        <div className="w-full mt-3.5 sm:mt-5">
          <div className="flex justify-center items-start h-6 sm:h-9">
            <div
              className={`
              mr-2 sm:mr-3 ${
                poiIndexing ? "text-yoda-green-dark" : "text-yoda-gray-3"
              }
              `}
            >
              <button
                onClick={() => sliderRef.current?.slickGoTo(0)}
                className="btn-bold-16"
              >
                추천 여행지
              </button>
              {poiIndexing && (
                <div className="mt-0.5 sm:mt-1 h-0.5 sm:h-1 bg-yoda-green-dark"></div>
              )}
            </div>
            <div
              className={`ml-2 sm:ml-3 
              ${poiIndexing ? "text-yoda-gray-3" : "text-yoda-green-dark"}
            `}
            >
              <button
                className="btn-bold-16"
                onClick={() =>
                  sliderRef.current?.slickGoTo(weeklyPois.pois.length)
                }
              >
                일정의 발견
              </button>
              {!poiIndexing && (
                <div className="mt-0.5 sm:mt-1 h-0.5 sm:h-1 bg-yoda-green-dark"></div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4 sm:mt-6 pt-2 sm:pt-3 pb-2.5 sm:pb-3.5 weekly-title flex flex-col">
          <div className="btn-bold-14 text-yoda-gray-4 h-4 sm:h-6">
            {weeklyPois.date}
          </div>
          <div className="flex-1"></div>
          {poiIndexing ? (
            <div className="heading-bold-18 text-yoda-black-3 whitespace-pre">
              {`${weeklyPois.title}\n여다의 여행지 추천`}
            </div>
          ) : (
            <div className="text-yoda-black-3">
              <div className="btn-bold-16">
                {user ? user.name : "여행자"}님을 위해
              </div>
              <div className="body-bold-18">{weeklyTrips.title}</div>
            </div>
          )}
        </div>
      </div>
      <div id="contents-slider" className="w-full flex-grow">
        <Slider
          ref={sliderRef}
          className="w-full h-full"
          dots={false}
          arrows={false}
          slidesToShow={1}
          variableWidth={true}
          infinite={!isMobile()}
          afterChange={(index) => setWeeklyIndex(index)}
          autoplay={!isMobile()}
        >
          {weeklyPois.pois.map((poi, index) => (
            <Link key={index} className="h-full" to={`/weekly/poi/${poi.id}`}>
              <div className="max-h-full" style={{ height: sliderHeight }}>
                <div
                  className="h-full relative ml-6 sm:ml-9 rounded-xl sm:rounded-2xl bg-cover bg-no-repeat bg-center overflow-hidden"
                  style={{
                    backgroundImage: `url(${poi.mainImageUrl})`,
                    width: `calc(${sliderWidth}px - ${
                      isMobile() ? "3rem" : "4.5rem"
                    })`,
                  }}
                >
                  <div
                    className="h-full w-full pt-5 sm:pt-7 pl-5 sm:pl-7"
                    style={{ backgroundColor: "rgba(16, 16, 16, 0.4)" }}
                  >
                    <div className="text-white flex items-center">
                      <div className="body-medium-11 inline-block bg-yoda-gray-5 px-1.5 sm:px-2.5 rounded-full ">
                        <div className="h-5 sm:h-7 flex items-center">
                          {poi.poiType}
                        </div>
                      </div>
                      {poi.period && (
                        <div className="h-6 sm:h-9 ml-1 sm:ml-1.5 rounded-2xl sm:rounded-3xl bg-yoda-primary flex items-center px-1.5 sm:px-2.5">
                          <TimelapseWhite />
                          <div className="ml-0.5 sm:ml-1 body-medium-11 whitespace-pre">{`기간  |  ${poi.period}`}</div>
                        </div>
                      )}
                    </div>
                    <div
                      className="w-64 mt-2 sm:mt-3 card-regular-14 text-white whitespace-pre-line"
                      style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 0.4)" }}
                    >
                      {poi.title}
                    </div>
                    <div
                      className="w-60 heading-bold-27 text-white leading-normal whitespace-pre-line"
                      style={{ textShadow: "0px 0px 4px rgba(0, 0, 0, 0.4)" }}
                    >
                      <div>{poi.areaSmall}</div>
                      <div>{poi.showName}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
          {weeklyTrips.trips.map((trip, index) => (
            <Link
              to={`/weekly/trip/${trip.id}`}
              key={index + weeklyPois.pois.length}
              className="h-full"
            >
              <div
                className="max-h-full ml-6 sm:ml-9 pt-2 sm:pt-3 relative flex flex-col"
                style={{
                  width: `calc(${sliderWidth}px - ${
                    isMobile() ? "3rem" : "4.5rem"
                  })`,
                  height: sliderHeight,
                }}
              >
                <div className="px-2.5 sm:px-3.5 h-7 sm:h-10 absolute bg-yoda-yellow-gray top-0 left-4 body-bold-12 text-yoda-gray-4 z-10 flex items-center justify-center rounded-b-lg sm:rounded-b-xl border border-yoda-gray-2">
                  일정의 발견
                </div>
                <div className="w-full flex-grow flex flex-col rounded-xl sm:rounded-2xl overflow-hidden border border-yoda-gray-2">
                  <div className="w-full pt-10 sm:pt-16 pl-4 sm:pl-6 z-20 text-yoda-black-3">
                    <div className="card-regular-14">{trip.subTitle}</div>
                    <div className="flex items-center h-9 sm:h-14">
                      <div className="heading-bold-24 leading-none pb-0.5 sm:pb-1">
                        {trip.area}
                      </div>
                      <div className="ml-2.5 sm:ml-4 h-6 sm:h-9 px-2 sm:px-3 border border-yoda-black-3 flex items-center body-bold-12-wide rounded-2xl sm:rounded-3xl">
                        {getSimpleDays(trip.length)}
                      </div>
                      {trip.period && (
                        <div className="h-6 sm:h-9 ml-1 sm:ml-1.5 rounded-2xl sm:rounded-3xl bg-yoda-primary flex items-center px-1.5 sm:px-2.5">
                          <TimelapseWhite />
                          <div className="ml-0.5 sm:ml-1 text-white body-medium-11 whitespace-pre">{`기간  |  ${trip.period}`}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="mt-2.5 sm:mt-3.5 w-full flex-grow bg-cover bg-no-repeat bg-center"
                    style={{ backgroundImage: `url(${trip.mainImageUrl})` }}
                  />
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
      <div className="w-full flex-shrink-0 flex flex-row justify-center items-center mt-3 sm:mt-5 mb-9 sm:mb-14 h-1.5 sm:h-2.5">
        {Array.from(
          Array(weeklyPois.pois.length + weeklyTrips.trips.length)
        ).map((v, index) =>
          index === weeklyIndex ? (
            <button
              className="mx-1 sm:mx-1.5 w-5 sm:w-8 h-full rounded-md sm:rounded-xl bg-yoda-primary"
              onClick={() => sliderRef.current?.slickGoTo(index)}
            ></button>
          ) : (
            <button
              className="mx-1 sm:mx-1.5 w-1.5 sm:w-2.5 h-full rounded-md sm:rounded-xl bg-yoda-gray-3"
              onClick={() => sliderRef.current?.slickGoTo(index)}
            ></button>
          )
        )}
      </div>
    </div>
  );
};

export default Board;
