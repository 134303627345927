import { ReactComponent as Arrow } from "../../assets/Guide/prev.svg";

const LeftArrow = ({
  className,
  onClick,
}: {
  className: string;
  onClick: () => void;
}) => {
  return (
    <div className={className}>
      <button className="w-16 sm:w-24" onClick={onClick}>
        <Arrow />
      </button>
    </div>
  );
};

export default LeftArrow;
