import { SearchContext } from "../../contexts/SearchContext";
import { ReactComponent as SearchIcon } from "../../assets/Inputs/searchIcon.svg";
import { useContext } from "react";
import { useHistory } from "react-router";

const SearchFrame = ({ placeholder }: { placeholder: string }) => {
  const history = useHistory();
  const [searchContext] = useContext(SearchContext);
  return (
    <div className="w-full h-full mt-6 sm:mt-9">
      <div className="w-full h-12 sm:h-18 flex items-center border rounded sm:rounded-md border-yoda-gray-4">
        <input
          className="flex-1 px-4 sm:px-6 py-2 sm:py-4 truncate caret-yoda-primary body-regular-16 text-yoda-black-1 rounded sm:rounded-md"
          type="text"
          placeholder={placeholder}
          onClick={() => history.push("/survey/search")}
          value={searchContext.option}
          readOnly
        />
        <button
          className="mr-3 sm:mr-4 w-8 sm:w-12"
          onClick={() => history.push("/survey/search")}
        >
          <SearchIcon className="w-full h-full" />
        </button>
      </div>
    </div>
  );
};
export default SearchFrame;
