import {
  CandidateType,
  createDepartLocations,
  deleteDepartLocations,
  getDepartLocations,
} from "../../api/Survey";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import { ReactComponent as Close } from "../../assets/Survey/close.svg";
import Fuse from "fuse.js";
import { MainContext } from "../../contexts/MainContext";
import { SearchContext } from "../../contexts/SearchContext";
import SearchFrame from "../Inputs/SearchFrame";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import departLocations from "../../util/departLocations";
import { saveSurvey } from "../../util";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_2_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [searchContext, dispatchSearchContext] = useContext(SearchContext);

  const [{ user }] = useContext(MainContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [lat, setLat] = useState<number | undefined>();
  const [lng, setLng] = useState<number | undefined>();
  const [recentLocations, setRecentLocations] = useState<CandidateType[]>([]);

  useEffect(() => {
    setLat(surveyContext.departCoords?.lat);
    setLng(surveyContext.departCoords?.lng);
  }, [surveyContext.departCoords]);
  useEffect(() => {
    if (searchContext.option === "") {
      dispatchSearchContext({
        type: "UPDATE_OPTION",
        value: surveyContext.departLocation,
      });
    }
  }, [surveyContext.departLocation]);

  const isDispatchable = useCallback(
    () =>
      searchContext.option.length !== 0 &&
      lat !== undefined &&
      lng !== undefined,
    [searchContext.option, lat, lng]
  );

  const dispatch = useCallback(async () => {
    dispatchSurveyContext({
      type: "UPDATE_DEPART_LOCATION",
      value: {
        departLocation: searchContext.option,
        departCoords: { lat: lat!, lng: lng! },
      },
    });
    saveSurvey(surveyId, {
      ...surveyContext,
      departLocation: searchContext.option,
    });

    try {
      await createDepartLocations(searchContext.option);
    } catch (e) {}
  }, [searchContext, lat, lng, surveyId, surveyContext]);

  const onChange = (input: string) => {
    if (input.length === 0) {
      dispatchSearchContext({ type: "UPDATE_SEARCH_OPTIONS", value: [] });
    } else {
      const fuse = new Fuse(departLocations.map((loc) => loc.location));
      const options = fuse
        .search(input)
        .map((fu) => ({ valid: true, value: fu.item }));

      dispatchSearchContext({
        type: "UPDATE_SEARCH_OPTIONS",
        value: options,
      });
    }
  };

  useEffect(() => {
    dispatchSearchContext({
      type: "UPDATE_SEARCH_FORM",
      value: {
        options: [],
        placeholder: "시/구/군까지 입력",
        navTitle: "어디서 출발하시나요?",
        onChange,
        errorMsg: "",
      },
    });
  }, []);

  useEffect(() => {
    const departLocation = departLocations.find(
      (loc) => loc.location === searchContext.option
    );
    if (departLocation) {
      setLat(departLocation.lat);
      setLng(departLocation.lng);
    }
  }, [searchContext.option, surveyContext]);

  const callGetRecentLocations = async () => {
    try {
      const { data: locations } = await getDepartLocations();

      if (locations.length > 0) {
        setRecentLocations(locations);
      }
    } catch (e) {}
  };

  const callDeleteDepartLocations = async (id: string) => {
    try {
      await deleteDepartLocations(id);
      callGetRecentLocations();
    } catch (e) {}
  };

  useEffect(() => {
    if (user && user.accessToken && user.accessToken) {
      callGetRecentLocations();
    }
  }, [user]);

  useEffect(() => {
    amplitude.getInstance().logEvent("2-1 from opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("2-1 from activated", properties);
    }
  }, [isDispatchable]);

  const onClickNext = () => {
    dispatch();
    if (surveyContext.destFromWeeklyContents === true) {
      history.push(`/survey/${surveyId}/step-2-5`);
    } else {
      history.push(`/survey/${surveyId}/step-2-3`);
    }
  };

  return (
    <div className="w-full survey-min-height pb-8">
      <div className="w-full h-full px-6 sm:px-9">
        <SurveyTopBar prevPath={`/survey/${surveyId}/step-1-2`} />
        <div>
          <SurveyStep step={3} />
          <Title title="어디서 출발하시나요?" />
        </div>
        <SearchFrame placeholder={searchContext.placeholder} />
        {searchContext.option.length > 0 && (
          <div className="mt-7">
            <div className="mr-7 sm:mr-11 text-right body-medium-15 text-yoda-green-dark">
              출발지
            </div>
            <div className="mt-1 sm:mt-1.5 flex justify-end items-center">
              <div className="flex justify-center items-center">
                <div className="heading-bold-24 text-gray-900">
                  {searchContext.option.length > 0 ? (
                    <div>{searchContext.option}</div>
                  ) : (
                    <div>&nbsp;</div>
                  )}
                </div>
                {searchContext.option.length > 0 && (
                  <button
                    className="ml-1.5 sm:ml-2 w-6 sm:w-9"
                    onClick={() => {
                      dispatchSearchContext({
                        type: "UPDATE_OPTION",
                        value: "",
                      });
                    }}
                  >
                    <ClearIcon className="w-full h-full" />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {recentLocations.length > 0 && (
          <div id="recent-locations" className="mt-8 sm:mt-12">
            <div>
              <div className="body-medium-15 text-left">최근 출발지</div>
              <div className="border-b border-yoda-gray-2 my-3 sm:my-4"></div>
            </div>
            <div>
              {recentLocations.map((location, index) => (
                <button
                  key={index}
                  id="recent-depart-location"
                  className="bg-yoda-gray-0 h-12 sm:h-18 w-full flex items-center justify-between pr-3 pl-5 sm:pr-5 sm:pl-9 mb-1 sm:mb-2 border-l-4 sm:border-l-8 border-yoda-gray-3"
                  onClick={() =>
                    dispatchSearchContext({
                      type: "UPDATE_OPTION",
                      value: location.content,
                    })
                  }
                >
                  <div className="body-regular-16">{location.content}</div>
                  <button
                    className="w-6 sm:w-9 h-6 sm:h-9"
                    onClick={(e) => {
                      e.stopPropagation();
                      callDeleteDepartLocations(location.id);
                    }}
                  >
                    <Close className="w-full h-full" />
                  </button>
                </button>
              ))}
            </div>
          </div>
        )}
        <SurveyBottomBar
          onClickNext={onClickNext}
          isDispatchable={isDispatchable()}
          prevPath={`/survey/${surveyId}/step-1-2`}
        />
      </div>
    </div>
  );
};

export default Survey_2_1;
