import { ReactComponent as XIcon } from "../../assets/Weekly/x.svg";
import { useEffect } from "react";
import useHideScrollTop from "../../hooks/useHideScrollTop";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const PoiPhoto = () => {
  const history = useHistory();
  const query = useQuery();
  const imageUrl = query.get("image_url");
  useHideScrollTop();
  useEffect(() => {
    const firstHeightFull = document.getElementById("first-h-full");
    const paddingTop = document.getElementById("padding-top");
    const paddingBottom = document.getElementById("padding-bottom");
    if (firstHeightFull) {
      firstHeightFull.classList.add("h-full");
    }
    if (paddingTop) {
      paddingTop.classList.remove("pt-14");
      paddingTop.classList.remove("sm:pt-20");
    }
    if (paddingBottom) {
      paddingBottom.classList.add("h-full");
      paddingBottom.classList.remove("overall-padding-bottom");
    }
    return () => {
      if (firstHeightFull) {
        firstHeightFull.classList.remove("h-full");
      }
      if (paddingTop) {
        paddingTop.classList.add("pt-14");
        paddingTop.classList.add("sm:pt-20");
      }
      if (paddingBottom) {
        paddingBottom.classList.remove("h-full");
        paddingBottom.classList.add("overall-padding-bottom");
      }
    };
  }, []);

  if (imageUrl === null) {
    alert("잘못된 접근입니다");
    history.goBack();
    return null;
  }

  return (
    <div className="w-full h-full flex items-center">
      <div
        className="w-full h-12 sm:h-20 survey-nav-bar top-0 left-0 max-w-full flex items-center border-b border-yoda-gray-1"
        style={{
          position: "fixed",
          width: "33.75rem",
        }}
      >
        <button className="ml-6 sm:ml-9" onClick={() => history.goBack()}>
          <XIcon />
        </button>
      </div>
      <img className="w-full h-auto" src={imageUrl} />
    </div>
  );
};

export default PoiPhoto;
