const FAQList = [
  {
    title: "여다의 맞춤형 여행일정은 어떻게 신청하나요?",
    describe:
      "여다의 여행일정 주문은 다음과 같은 순서로 진행됩니다. 우선 10개의 간단한 질문을 통해 이번 여행에 원하시는 바에 대해서 알려주세요.  그러면 여다가 AI와 내부 전문가의 역량을 결합해 당신만의 맞춤형 여행일정을 3분 내로 카카오톡과 이메일로 보내드립니다.",
  },
  {
    title: "일정 제작에는 얼마나 걸리나요?",
    describe:
      "주문으로부터 3분 내로 소요됩니다. 이 시간 동안 여다는 수많은 여행지들에 대한 정보를 취합하고 분석한 후, 최적의 여행일정 조합을 생성하고 비교해 한땀한땀 한사람만을 위한 여행일정을 만들어냅니다.",
  },
  {
    title: "여다의 추천 장소들은 어떤 기준으로 선정되나요?",
    describe:
      "여다가 추천하는 여행장소들은 다음 중 하나 이상의 조건을 갖추고 있습니다. 블루리본 등 분야 별 전문기관으로부터 인증된 장소이거나, 여행잡지, 또는 가이드북들과 같이 신뢰할 수 있는 매체에서 추천하는 장소로 소개되었거나, 구글, 네이버맵, 카카오맵 등 플랫폼에서 많은 사용자들의 높은 별점을 얻은 장소들입니다. 여기에 여다만의 기준으로 또 한번 선별된 장소들만 여러분께 추천됩니다.",
  },
  {
    title: "다른 사람들과 일정표 공유가 가능한가요?",
    describe:
      "물론입니다. 받아보실 여행일정에는 '공유' 버튼이 있어서, 동행 및 지인들과 마음껏 공유하실 수 있습니다. 다만 개인정보의 보호를 위해, 공유하시는 일정표는 비밀번호 (주문자 분의 전화번호)로 보호되어 있습니다.",
  },
  {
    title: "어떻게 무료가 가능한가요?",
    describe:
      "여다는 여행에만 특화된 큐레이션 기술에 힘입어, 누구에게나 무료로 맞춤형 여행일정을 만들어드릴 수 있습니다. 여다는 '훌륭한 여행경험을 모두에게'라는 존재이유를 가지고 태어난 회사이며, 이 미션을 지키기 위해 최선을 다할 것입니다.",
  },
  {
    title: "일정은 어떤 부분들을 고려해 제작해주나요?",
    describe:
      "여다는 여러분의 동행을 고려합니다. 여행하시는 계절 별로 가기 좋은 곳들도 고려합니다. 또한 교통(자가용, 대중교통)과 원하는 이동시간, 예산을 제작에 반영합니다. 그리고 여러분이 하고 싶어하시는 액티비티, 여러분의 여행 취향도 고려해 일정에 반영합니다. 마지막으로 해당 여행스팟들의 영업시간도 생각합니다.",
  },
  {
    title: "받아본 일정표가 마음에 안 들면 어떻게 하나요?",
    describe:
      "여다가 만들어드린 일정표가 마음에 안 드시거나, 추가로 반영되어야 할 사항이 있으실 경우, 수정 요청을 하실 수 있습니다. 수정요청을 위해서는, 일정표 화면 우측 하단의 채널톡 아이콘을 누르시고, '수정요청' 버튼을 누르신 후 반영될 사항을 말씀해 주시면 됩니다. 접수 후 24시간 내 수정해 보내드립니다.",
  },
];

export { FAQList };
