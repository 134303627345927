import React, { useCallback, useEffect, useState } from "react";

import { ReactComponent as DropdownIcon } from "../../assets/Survey/dropdown.svg";
import { ReactComponent as DropdownReverse } from "../../assets/Survey/dropdownReverse.svg";
import useOnclickOutside from "react-cool-onclickoutside";

export type Option = { value: number | string; name: string };
const Dropdown = ({
  useOption,
  options,
  placeholder,
  className,
}: {
  useOption: [
    Option,
    React.Dispatch<React.SetStateAction<Option>> | React.Dispatch<Option>
  ];
  options: Option[];
  placeholder: Option;
  className?: string;
}) => {
  const [option, setOption] = useOption;
  const [hoverIndex, setHoverIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const keyDownHandler = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const code = e.keyCode;

    if (code === 38) {
      e.preventDefault();
      if (hoverIndex > 0) {
        setHoverIndex((index) => index - 1);
      }
    } else if (code === 40) {
      e.preventDefault();
      if (hoverIndex < options.length - 1) {
        setHoverIndex((index) => index + 1);
      }
    } else if (code === 13 && options.length > 0) {
      e.preventDefault();

      selectOption(options[hoverIndex]);
    }
  };

  const selectOption = (op: Option) => {
    setOption(op);
    setShowDropdown(false);
  };

  const ref = useOnclickOutside(() => {
    setShowDropdown(false);
  });

  const getColor = useCallback(() => {
    if (option.value === 0) {
      return "text-yoda-gray-3";
    }
    return "text-yoda-black-1";
  }, [option]);

  useEffect(() => {
    const parent = document.getElementById("parent");
    const dropdownParent = document.getElementById("dropdown-parent");
    if (parent && dropdownParent) {
      const index = options.findIndex((op) => op.value === option.value);
      if (index > -1) {
        dropdownParent.scrollTo(0, index * parent.clientHeight);
      }
    }
  }, [options, option.value]);

  return (
    <div
      id="parent"
      className={`w-full sm:w-44 h-10 sm:h-16 relative ${className}`}
      ref={ref}
    >
      <button
        className={`w-full h-full border border-yoda-gray-4 pl-3 pr-2 sm:pl-4 sm:pr-3 text-left ${
          showDropdown === true
            ? "rounded-t sm:rounded-t-md border-b-0 pb-px"
            : "rounded sm:rounded-md"
        } ${getColor()}`}
        type="button"
        onClick={() => setShowDropdown((show) => !show)}
        onKeyDown={keyDownHandler}
      >
        <span
          className={`flex justify-between items-center body-regular-14 ${
            option.value === 0 ? "text-yoda-gray-3" : "text-yoda-black-1"
          }`}
        >
          <div className="flex-grow">
            {option.value === 0 ? placeholder.name : option.name}
          </div>
          {showDropdown ? <DropdownReverse /> : <DropdownIcon />}
        </span>
      </button>
      {showDropdown && (
        <ul
          id="dropdown-parent"
          className="w-full max-h-46 sm:max-h-72 overflow-y-auto border-l border-r border-b border-yoda-gray-4 rounded-b sm:rounded-b-md absolute z-10 top-10 sm:top-16 bg-white"
        >
          {options.map((op, index) => (
            <li
              key={index}
              onClick={() => {
                setOption(op);
                setShowDropdown(false);
              }}
              onMouseOver={() => setHoverIndex(index)}
            >
              <div
                className="w-full body-regular-14 flex items-center h-10 sm:h-16 pl-3 sm:pl-4 cursor-pointer"
                style={{
                  backgroundColor:
                    hoverIndex === index ? "rgba(174,211,118,0.15)" : "white",
                }}
              >
                {op.name}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
