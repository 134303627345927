import { ReactComponent as AccommAIcon } from "../../assets/Trip/marker/accommActive.svg";
import { ReactComponent as AccommIcon } from "../../assets/Trip/marker/accomm.svg";
import { ReactComponent as ActivityAIcon } from "../../assets/Trip/marker/activityActive.svg";
import { ReactComponent as ActivityIcon } from "../../assets/Trip/marker/activity.svg";
import { ReactComponent as FoodAIcon } from "../../assets/Trip/marker/foodActive.svg";
import { ReactComponent as FoodIcon } from "../../assets/Trip/marker/food.svg";
import { ReactComponent as TourspotAIcon } from "../../assets/Trip/marker/tourspotActive.svg";
import { ReactComponent as TourspotIcon } from "../../assets/Trip/marker/tourspot.svg";
import { ReactComponent as AccommIndicatorIcon } from "../../assets/Trip/marker/accommIndicator.svg";
import { ReactComponent as ActivityIndicatorIcon } from "../../assets/Trip/marker/activityIndicator.svg";
import { ReactComponent as FoodIndicatorIcon } from "../../assets/Trip/marker/foodIndicator.svg";
import { ReactComponent as TourspotIndicatorIcon } from "../../assets/Trip/marker/tourspotIndicator.svg";
import { ReactComponent as GrayIndicatorIcon } from "../../assets/Trip/marker/grayIndicator.svg";

const getMarkerIcon = (type: string, alternative: boolean) => {
  if (type === "관광스팟") {
    if (alternative) {
      return TourspotIcon;
    }
    return TourspotAIcon;
  } else if (type === "액티비티") {
    if (alternative) {
      return ActivityIcon;
    }
    return ActivityAIcon;
  } else if (type === "숙소") {
    if (alternative) {
      return AccommIcon;
    }
    return AccommAIcon;
  } else {
    if (alternative) {
      return FoodIcon;
    }
    return FoodAIcon;
  }
};

const getIndicatorIcon = (type: string, alternative: boolean) => {
  if (alternative) {
    return GrayIndicatorIcon;
  } else if (type === "관광스팟") {
    return TourspotIndicatorIcon;
  } else if (type === "액티비티") {
    return ActivityIndicatorIcon;
  } else if (type === "숙소") {
    return AccommIndicatorIcon;
  } else {
    return FoodIndicatorIcon;
  }
};

const PoiMarker = ({
  type,
  alternative,
  className,
}: {
  type: string;
  alternative: boolean;
  className: string;
}): JSX.Element => {
  const Icon = getMarkerIcon(type, alternative);
  return <Icon className={className} />;
};

const PoiIndicator = ({
  type,
  alternative,
  className,
}: {
  type: string;
  alternative: boolean;
  className: string;
}): JSX.Element => {
  const Icon = getIndicatorIcon(type, alternative);
  return <Icon className={className} />;
};

export { PoiMarker, PoiIndicator };
