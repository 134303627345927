import { useCallback, useContext, useEffect, useState } from "react";

import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { getSurvey } from "../../api/Survey";
import { useHistory } from "react-router";
import useQuery from "../../hooks/useQuery";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const RecentSurvey = () => {
  const history = useHistory();
  const query = useQuery();
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [isNew, setIsNew] = useState<boolean | undefined>();

  const onClickNext = useCallback(async () => {
    try {
      const querySurveyId = query.get("survey_id");
      if (querySurveyId && !isNew) {
        const { data: survey } = await getSurvey(parseInt(querySurveyId));
        if (survey.id || survey.surveyId) {
          delete survey.id;
          delete survey.surveyId;
        }
        if (survey.destFromWeeklyContents) {
          survey.destFromWeeklyContents = undefined;
          survey.destType = undefined;
          survey.destAct = 0;
          survey.interestPriority = [];
        }
        dispatchSurveyContext({
          type: "UPDATE_SURVEY",
          value: { ...survey, ...{ covidFree: surveyContext.covidFree } },
        });
      }
    } catch (e) {
    } finally {
      history.push("/survey/step-1-1");
    }
  }, [isNew]);

  useEffect(() => {
    amplitude.getInstance().logEvent("recent opened");
  }, []);
  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isNew !== undefined) {
      amplitude.getInstance().logEvent("recent activated", properties);
    }
  }, [isNew]);

  return (
    <div className="w-full survey-min-height px-6 sm:px-9">
      <SurveyTopBar prevPath="/survey/preview" />
      <Title title={"가장 최근 여행일정\n주문 내용을 불러올까요?"} />
      <div className="grid grid-cols-2 gap-2 sm:gap-3 h-12 sm:h-18 mt-6 sm:mt-9">
        <button
          className={`w-full rounded sm:rounded-md btn-bold-16 ${
            isNew === true
              ? "bg-yoda-primary text-white"
              : "bg-yoda-gray-2 text-yoda-gray-4 "
          }`}
          onClick={() => setIsNew(true)}
        >
          새로 할게요
        </button>
        <button
          className={`w-full rounded sm:rounded-md btn-bold-16 ${
            isNew === false
              ? "bg-yoda-primary text-white"
              : "bg-yoda-gray-2 text-yoda-gray-4 "
          }`}
          onClick={() => setIsNew(false)}
        >
          불러 올게요
        </button>
      </div>
      <SurveyBottomBar
        onClickNext={onClickNext}
        isDispatchable={isNew !== undefined}
        prevPath="/survey/preview"
      />
    </div>
  );
};

export default RecentSurvey;
