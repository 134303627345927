import { MainContext } from "../../../contexts/MainContext";
import { ReactComponent as Next } from "../../../assets/Survey/arrow_right.svg";
import { ReactComponent as Prev } from "../../../assets/Survey/arrow_left.svg";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

const SurveyBottomBar = ({
  onClickNext,
  isDispatchable,
  prevPath,
}: {
  onClickNext: () => void;
  isDispatchable: boolean;
  prevPath: string;
}) => {
  const history = useHistory();
  const [, dispatchMainContext] = useContext(MainContext);

  return (
    <div
      id="bottom-bar"
      className="sm:left-44 left-0 max-w-full fixed bottom-0 grid grid-cols-2 gap-0.5 z-40 bg-white h-14 sm:h-20"
      style={{
        width: "33.75rem",
        boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.06)",
      }}
    >
      <button
        className="w-full text-center bg-yoda-gray-5 flex items-center justify-center"
        onClick={() => {
          history.push(prevPath);
        }}
      >
        <div className="bottom-contents">
          <Prev />
          <div className="btn-bold-16 text-white">이전</div>
        </div>
      </button>
      <button
        className={`w-full h-full flex items-center justify-center ${
          isDispatchable
            ? "bg-yoda-gray-5 text-white"
            : "bg-yoda-gray-2 text-yoda-gray-3"
        }`}
        onClick={() => {
          if (isDispatchable) {
            onClickNext();
          } else {
            dispatchMainContext({
              type: "UPDATE_DIALOG",
              value: {
                type: "DialogError",
                content: "아직 답변하지 않은 질문이 있어요",
              },
            });
          }
        }}
      >
        <div className="bottom-contents">
          <div className="btn-bold-16">다음</div>
          <Next />
        </div>
      </button>
    </div>
  );
};

export default SurveyBottomBar;
