import { accommOptions, budgetOptions } from "../../util/surveyOptions";
import { getDefaultOptions, saveSurvey } from "../../util";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as CheckGreen } from "../../assets/Survey/checkGreen.svg";
import { ReactComponent as CheckWhite } from "../../assets/Survey/checkWhite.svg";
import { ReactComponent as Circle } from "../../assets/Survey/info-circle.svg";
import { Option } from "../Inputs/Dropdown";
import Picture from "../General/Picture";
import { Range } from "rc-slider";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { v4 as uuidv4 } from "uuid";

const isMobile = () => {
  let mobile = false;
  const userAgent = navigator.userAgent;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      mobile = true;
  })(userAgent || navigator.vendor);
  return mobile || userAgent === "YodaReactNative";
};

const uuid = uuidv4();
const Survey_3_1 = () => {
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);

  const { surveyId } = useParams<{ surveyId: string }>();
  const history = useHistory();
  const [accommTypes, setAccommTypes] = useState<Option[]>([]);
  const [accommBudgets, setAccommBudgets] =
    useState<{ value: number | string; name: string; order: number }[]>(
      budgetOptions
    );
  const [isAccommFixed, setIsAccommFixed] = useState<boolean | undefined>();
  const [sliderValues, setSliderValues] = useState([
    0,
    budgetOptions.length - 1,
  ]);

  useEffect(() => {
    if (surveyContext.destType === "anything") {
      setIsAccommFixed(false);
    } else {
      if (
        surveyContext.accommAddr.length > 0 &&
        surveyContext.accommCoords !== undefined
      ) {
        setIsAccommFixed(true);
      } else if (
        surveyContext.accommTypes.length > 0 &&
        surveyContext.accommBudget.length > 0
      ) {
        setIsAccommFixed(false);
      }
    }

    if (surveyContext.accommBudget.length > 0) {
      setAccommBudgets(
        budgetOptions
          .filter(
            (op) =>
              surveyContext.accommBudget.findIndex((com) => com === op.value) >
              -1
          )
          .sort((a, b) => a.order - b.order)
      );
    }
    setAccommTypes(getDefaultOptions(accommOptions, surveyContext.accommTypes));
  }, [surveyContext]);
  useEffect(() => {
    let min = 0;
    let max = 3;

    if (surveyContext.accommBudget[0] === budgetOptions[1].value) {
      min = 1;
    } else if (surveyContext.accommBudget[0] === budgetOptions[2].value) {
      min = 2;
    }
    if (
      surveyContext.accommBudget[surveyContext.accommBudget.length - 1] ===
      budgetOptions[0].value
    ) {
      max = 1;
    } else if (
      surveyContext.accommBudget[surveyContext.accommBudget.length - 1] ===
      budgetOptions[1].value
    ) {
      max = 2;
    }
    setSliderValues([min, max]);
  }, [surveyContext.accommBudget]);

  const isDispatchable = useCallback(
    () =>
      isAccommFixed === true ||
      ((isAccommFixed === false || isAccommFixed === undefined) &&
        accommTypes.length > 0 &&
        accommBudgets.length > 0),
    [isAccommFixed, accommTypes, accommBudgets]
  );

  const dispatch = useCallback(() => {
    if (!isAccommFixed) {
      const value = {
        accommAddr: "",
        accommCoords: undefined,
        accommTypes: accommTypes.map((type) => type.value as string),
        accommBudget: accommBudgets.map((type) => type.value as string),
      };

      dispatchSurveyContext({ type: "UPDATE_ACCOMM", value });
      saveSurvey(surveyId, { ...surveyContext, ...value });
    }
  }, [isAccommFixed, accommTypes, accommBudgets, surveyId, surveyContext]);

  useEffect(() => {
    amplitude.getInstance().logEvent("3-1-1 stay opened");
  }, []);

  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude.getInstance().logEvent("3-1-1 stay activated", properties);
    }
  }, [isDispatchable]);

  const prevPath = useCallback(() => {
    if (surveyContext.destType === "anything") {
      return "2-4-4";
    } else {
      return "2-5";
    }
  }, [surveyContext]);

  useEffect(() => {
    setAccommBudgets(() =>
      sliderValues[1] === 3
        ? budgetOptions
            .sort((a, b) => a.order - b.order)
            .slice(sliderValues[0], sliderValues[1] + 1)
        : budgetOptions
            .sort((a, b) => a.order - b.order)
            .slice(sliderValues[0], sliderValues[1])
    );
  }, [sliderValues]);

  const onClickNext = () => {
    if (accommTypes.length > 0) {
      dispatch();
      history.push(`/survey/${surveyId}/step-3-2`);
    } else if (isAccommFixed) {
      const value = {
        accommAddr: surveyContext.accommAddr,
        accommCoords: surveyContext.accommCoords,
        accommTypes: [],
        accommBudget: [],
      };
      dispatchSurveyContext({ type: "UPDATE_ACCOMM", value });
      history.push(`/survey/${surveyId}/step-3-1-2`);
    }
  };

  return (
    <div className="w-full survey-min-height">
      <div className="w-full h-full px-6 sm:px-9">
        <div>
          <SurveyTopBar prevPath={`/survey/${surveyId}/step-${prevPath()}`} />
          <div>
            <SurveyStep step={surveyContext.destType === "anything" ? 4 : 5} />
            <Title title="어떤 숙소를 선호하세요?" />
          </div>
          {accommTypes.length === 0 ? (
            <div className="flex items-center body-regular-14 text-yoda-green-dark mt-1 sm:mt-2">
              <Circle className="w-4 sm:w-7" />
              <div className="ml-1 sm:ml-2">중복 선택 가능</div>
            </div>
          ) : (
            <div className="body-regular-14 text-yoda-black-2 mt-1 sm:mt-2">
              원하는 숙소 유형을 모두 골라주세요
            </div>
          )}
        </div>
        <div>
          <div className="pt-6 sm:pt-9 flex items-center">
            <div className="heading-bold-16 text-yoda-black-2">가격</div>
            <div className="body-regular-12 text-yoda-green-dark ml-3 sm:ml-5">
              1박, 2인 1실 기준
            </div>
          </div>
          <div className="w-full sm:px-3 px-2 pt-3.5 sm:pt-5 h-full card-regular-14">
            <Range
              onChange={(values) => setSliderValues([values[0], values[1]])}
              pushable={true}
              marks={{
                0: {
                  style: {
                    transform: "translateX(-0.25rem)",
                  },
                  label: "0원",
                },
                1: {
                  style: { transform: "translateX(-50%)" },
                  label: "10만원",
                },
                2: {
                  style: {
                    transform: `translateX(-${isMobile() ? "1.6" : "2.3"}rem)`,
                  },
                  label: "20만원",
                },
                3: {
                  style: {
                    transform: `translateX(-${isMobile() ? "3" : "4"}rem)`,
                    whiteSpace: "nowrap",
                  },
                  label: "제한없음",
                },
              }}
              max={3}
              dots={true}
              allowCross={false}
              value={sliderValues}
            />
          </div>
        </div>
        <div className="pt-11 sm:pt-16">
          <div className="heading-bold-16 text-yoda-black-2">숙소 유형</div>
          <div className="mt-2 sm:mt-3 grid grid-cols-3 sm:grid-cols-3 gap-x-1.5 gap-y-1 sm:gap-x-2 sm:gap-y-1.5">
            {accommOptions.map((trans) => (
              <button
                key={trans.name}
                className={`w-full relative rounded sm:rounded-md overflow-hidden ${
                  accommTypes.findIndex((co) => co.value === trans.value) > -1
                    ? "shadow-lg"
                    : "opacity-80"
                }`}
                onClick={() => {
                  setIsAccommFixed(false);
                  setAccommTypes((prevTrans) => {
                    const anythingIndex = prevTrans.findIndex(
                      (t) => t.value === "anything"
                    );

                    if (trans.value === "anything") {
                      return [{ name: trans.name, value: trans.value }];
                    }

                    if (anythingIndex > -1) {
                      return prevTrans
                        .filter((accomm) => accomm.value !== "anything")
                        .concat({
                          name: trans.name,
                          value: trans.value,
                        });
                    }

                    const index = prevTrans.findIndex(
                      (t) => t.value === trans.value
                    );

                    if (index > -1) {
                      return prevTrans
                        .slice(0, index)
                        .concat(prevTrans.slice(index + 1));
                    }
                    return prevTrans.concat({
                      name: trans.name,
                      value: trans.value,
                    });
                  });
                }}
              >
                {accommTypes.findIndex((co) => co.value === trans.value) >
                  -1 && (
                  <div className="absolute z-50 right-0.5 top-1 sm:top-1.5 sm:right-1">
                    <CheckGreen className="w-6 sm:w-9" />
                  </div>
                )}
                <div
                  className={`absolute h-full w-full top-0 left-0 rounded sm:rounded-md ${
                    accommTypes.findIndex((co) => co.value === trans.value) > -1
                      ? "border-2 border-yoda-primary"
                      : "border border-yoda-gray-2"
                  } z-10`}
                ></div>
                <div className="h-full w-full text-yoda-black-2 flex flex-col">
                  <div
                    className={`${
                      trans.name.includes(`\n`)
                        ? "h-12.5 sm:h-20"
                        : "h-14 sm:h-20"
                    } flex items-center justify-center`}
                  >
                    <Picture
                      Low={trans.image}
                      Medium={trans.imageM}
                      High={trans.imageH}
                      className={`h-11 w-11 sm:h-16 sm:w-16 ${
                        trans.name.includes(`\n`) ? "" : "mt-2.5 sm:mt-3.5"
                      }`}
                    />
                  </div>
                  <div
                    className={`w-full card-regular-13 whitespace-pre-line border-t border-yoda-gray-2 flex justify-center items-center ${
                      trans.name.includes(`\n`)
                        ? "h-10 sm:h-16"
                        : "h-7.5 sm:h-12"
                    }`}
                  >
                    {trans.name}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        {surveyContext.destType !== "anything" &&
          surveyContext.destFromWeeklyContents !== true && (
            <>
              <div className="flex w-full h-full items-center justify-center pt-2.5 sm:pt-4">
                <div className="w-full h-1 border-b sm:border-b-2 border-yoda-gray-2">
                  &nbsp;
                </div>
                <div className="body-regular-12 text-yoda-gray-4 flex-shrink-0 px-2 sm:px-3">
                  또는
                </div>
                <div className="w-full h-1 border-b sm:border-b-2 border-yoda-gray-2">
                  &nbsp;
                </div>
              </div>
              <button
                className={`w-full h-12 sm:h-18 body-regular-16 relative rounded sm:rounded-md mt-2 sm:mt-3 ${
                  isAccommFixed
                    ? "bg-yoda-primary text-white shadow-accomm-fixed-btn"
                    : "bg-yoda-gray-2 text-yoda-black-3 "
                }`}
                onClick={() => {
                  setIsAccommFixed((prev) => !prev);
                  setAccommTypes([]);
                }}
              >
                <div className="">이미 정한 숙소가 있어요</div>
                {isAccommFixed && (
                  <div className="absolute z-30 bottom-1/4 right-3">
                    <CheckWhite className="h-full w-6 sm:w-9" />
                  </div>
                )}
              </button>
            </>
          )}
        <SurveyBottomBar
          onClickNext={onClickNext}
          isDispatchable={isDispatchable()}
          prevPath={`/survey/${surveyId}/step-${prevPath()}`}
        />
      </div>
    </div>
  );
};

export default Survey_3_1;
