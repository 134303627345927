const isHoliday = (year: number, month: number, day: number) => {
  if (month === 1 && day === 1) {
    return true;
  } else if (month === 3 && day === 1) {
    return true;
  } else if (month === 5 && day === 5) {
    return true;
  } else if (month === 6 && day === 6) {
    return true;
  } else if (month === 8 && day === 15) {
    return true;
  } else if (month === 10 && (day === 3 || day === 9)) {
    return true;
  } else if (month === 12 && day === 25) {
    return true;
  }

  if (year === 2021) {
    if (month === 9 && (day === 20 || day === 21 || day === 22)) {
      return true;
    }
  } else if (year === 2022) {
    if (month === 1 && day === 31) {
      return true;
    } else if (month === 2 && (day === 1 || day === 2)) {
      return true;
    } else if (month === 3 && day === 9) {
      return true;
    } else if (month === 5 && day === 8) {
      return true;
    } else if (month === 6 && day === 1) {
      return true;
    } else if (
      month === 9 &&
      (day === 9 || day === 10 || day === 11 || day === 12)
    ) {
      return true;
    }
  }
  return false;
};

export default isHoliday;
