import { ReactComponent as CheckGreen } from "../../../assets/Survey/checkGreen.svg";
import { ReactComponent as CheckStar } from "../../../assets/Survey/checkStar.svg";
import { InterestType } from "../../../util/surveyOptions";
import Picture from "../../General/Picture";

const InterestButton = ({
  interest,
  selected,
  onClick,
  poi,
}: {
  interest: InterestType;
  selected: boolean;
  onClick: () => void;
  poi: boolean;
}) => (
  <button
    className={`w-full relative rounded sm:rounded-md overflow-hidden h-32 sm:h-44 ${
      selected ? "shadow-lg" : " opacity-80"
    }`}
    onClick={() => {
      if (!poi) onClick();
    }}
  >
    <div className="absolute z-30 right-0.5 sm:right-1 top-1 sm:top-1.5 w-6 sm:w-9">
      {poi && <CheckStar className="w-full h-full" />}
      {!poi && selected && <CheckGreen className="w-full h-full" />}
    </div>
    <div
      className={`absolute h-full w-full top-0 left-0 rounded sm:rounded-md ${
        selected ? "border-2 border-yoda-primary" : "border border-yoda-gray-2"
      } z-10`}
    ></div>
    <div className=" h-full w-full text-yoda-black-2 flex flex-col">
      <div className="w-full h-full flex items-center justify-center">
        <Picture
          Low={interest.imageUrl}
          Medium={interest.imageUrlMedium}
          High={interest.imageUrlHigh}
          className="w-16 sm:w-24"
        />
      </div>
      <div
        className={`${
          interest.name.includes(`\n`) ? "py-1" : "min-h-9 sm:min-h-13"
        } text-yoda-black-3 w-full card-regular-14 border-t whitespace-pre-line flex items-center justify-center`}
      >
        {interest.name}
      </div>
    </div>
  </button>
);

export default InterestButton;
