const reviewList = [
  {
    name: "박혜진님",
    detail: "(50대, 친구들과 남해 여행)",
    tripLink: "/sample-trip/2013",
    comment:
      "메인 일정뿐만 아니라 여러개의 인근 후보 맛집과 엑티비티까지 추천해주셔서 추가 검색 없이 친구들과 편리하게 다녀왔어요.",
  },
  {
    name: "김중호님",
    detail: "(40대, 가족과 홍천 여행)",
    tripLink: "/sample-trip/560",
    comment:
      "아이들, 반려견과 함께 가는 여행이라 제약조건이 많았는데 덕분에 강원도 글램핑 여행을 쉽게 다녀올 수 있었어요.",
  },
  {
    name: "전종민님",
    detail: "(30대, 애인과 제주 여행)",
    tripLink: "/sample-trip/3451",
    comment:
      "저나 여자친구가 미처 몰랐던 제주 서쪽의 보석 같은 곳들을 추천해주셔서 좋았어요.",
  },
];

export { reviewList };
