import { Option, SearchContext } from "../../contexts/SearchContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { ReactComponent as ClearIcon } from "../../assets/Survey/clear.svg";
import SearchFrame from "../Inputs/SearchFrame";
import SurveyBottomBar from "./Util/SurveyBottomBar";
import { SurveyContext } from "../../contexts/SurveyContext";
import SurveyStep from "./Util/SurveyStep";
import SurveyTopBar from "./Util/SurveyTopBar";
import { Title } from "../Inputs";
import amplitude from "amplitude-js";
import { getCoordinates } from "../../api/ThirdParty";
import { getLocationOptions } from "../../api/ThirdParty";
import proj4 from "proj4";
import { saveSurvey } from "../../util";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

const Survey_3_1_2 = () => {
  const { surveyId } = useParams<{ surveyId: string }>();
  const [searchContext, dispatchSearchContext] = useContext(SearchContext);
  const [surveyContext, dispatchSurveyContext] = useContext(SurveyContext);
  const [lat, setLat] = useState<number | undefined>();
  const [lng, setLng] = useState<number | undefined>();
  const history = useHistory();

  useEffect(() => {
    if (surveyContext.destType !== "anything") {
      setLat(surveyContext.accommCoords?.lat);
      setLng(surveyContext.accommCoords?.lng);
    }
  }, [surveyContext.accommCoords]);
  useEffect(() => {
    if (surveyContext.destType !== "anything" && searchContext.option === "") {
      dispatchSearchContext({
        type: "UPDATE_OPTION",
        value: surveyContext.accommAddr,
      });
    }
  }, [searchContext.option, surveyContext.accommAddr, surveyContext.destType]);

  useEffect(() => {
    const getCoords = async (juso: Option) => {
      try {
        if (
          juso.admCd &&
          juso.buldMnnm &&
          juso.buldSlno &&
          juso.rnMgtSn &&
          juso.udrtYn
        ) {
          const {
            data: { results },
          } = await getCoordinates(
            juso.admCd,
            juso.rnMgtSn,
            juso.udrtYn,
            juso.buldMnnm,
            juso.buldSlno
          );
          if (
            results.common.errorCode === "0" &&
            results.juso !== null &&
            results.juso.length > 0
          ) {
            const firstProjection =
              "+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs";
            const secondProjection =
              "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
            const result = proj4(firstProjection, secondProjection, [
              parseFloat(results.juso[0].entX),
              parseFloat(results.juso[0].entY),
            ]);
            setLat(result[1]);
            setLng(result[0]);
          }
        }
      } catch (e) {
        setLat(undefined);
        setLng(undefined);
      }
    };
    const index = searchContext.options.findIndex((option) =>
      searchContext.option.includes(option.value)
    );
    if (index > -1) {
      getCoords(searchContext.options[index]);
    }
  }, [searchContext.option, searchContext.options]);

  const isDispatchable = useCallback(
    () =>
      searchContext.option.length > 0 && lat !== undefined && lng !== undefined,
    [searchContext.option, lat, lng]
  );

  const dispatch = useCallback(() => {
    const value = {
      accommAddr: searchContext.option,
      accommCoords: { lat: lat!, lng: lng! },
      accommTypes: [],
      accommBudget: [],
    };

    dispatchSurveyContext({ type: "UPDATE_ACCOMM", value });
    saveSurvey(surveyId, { ...surveyContext, ...value });
  }, [surveyContext, surveyId, searchContext.option, lat, lng]);

  const onChange = async (input: string) => {
    try {
      if (input.length === 0) {
        dispatchSearchContext({ type: "UPDATE_SEARCH_OPTIONS", value: [] });
      } else {
        const {
          data: { results },
        } = await getLocationOptions(input);

        if (
          results.common.errorCode === "0" &&
          results.juso !== null &&
          results.juso.length > 0
        ) {
          const locationData = results.juso.map((j) => ({
            valid: true,
            value: j.roadAddr + " " + j.bdNm,
            admCd: j.admCd,
            rnMgtSn: j.rnMgtSn,
            udrtYn: j.udrtYn,
            buldMnnm: j.buldMnnm,
            buldSlno: j.buldSlno,
          }));

          dispatchSearchContext({
            type: "UPDATE_SEARCH_OPTIONS",
            value: locationData,
          });
        }
      }
    } catch (e) {
      dispatchSearchContext({ type: "UPDATE_SEARCH_OPTIONS", value: [] });
    }
  };

  useEffect(() => {
    dispatchSearchContext({
      type: "UPDATE_SEARCH_FORM",
      value: {
        options: [],
        placeholder: "이름 또는 주소 입력",
        navTitle: "이미 정하신 숙소는 어디인가요?",
        onChange,
        errorMsg: "",
      },
    });
  }, []);

  const onClickNext = () => {
    dispatch();
    history.push(`/survey/${surveyId}/step-3-2`);
  };

  useEffect(() => {
    amplitude.getInstance().logEvent("3-1-2 stay search opened");
  }, []);

  useEffect(() => {
    const properties = { insert_id: uuid };
    if (isDispatchable()) {
      amplitude
        .getInstance()
        .logEvent("3-1-2 stay search activated", properties);
    }
  }, [isDispatchable]);

  return (
    <div className="w-full survey-min-height pb-8">
      <div className="w-full h-full px-6 sm:px-9">
        <div>
          <SurveyTopBar prevPath={`/survey/${surveyId}/step-3-1-1`} />
          <div>
            <SurveyStep step={surveyContext.destType === "anything" ? 4 : 5} />
            <Title title="이미 정하신 숙소는 어디인가요?" />
          </div>
        </div>
        <SearchFrame placeholder={searchContext.placeholder} />
        {searchContext.option.length > 0 && (
          <div className="mt-7">
            <div className="mr-7 sm:mr-11 text-right body-medium-15 text-yoda-green-dark">
              숙소
            </div>
            <div className="mt-1 sm:mt-1.5 flex justify-end items-center">
              <div className="flex justify-start items-start">
                <div className="heading-bold-24 text-gray-900 w-72 sm:w-96 text-right">
                  {searchContext.option.length > 0 ? (
                    <div>{searchContext.option}</div>
                  ) : (
                    <div>&nbsp;</div>
                  )}
                </div>
                {searchContext.option.length > 0 && (
                  <button
                    className="ml-1.5 sm:ml-2 w-6 sm:w-9 mt-3 sm:mt-2"
                    onClick={() => {
                      dispatchSearchContext({
                        type: "UPDATE_OPTION",
                        value: "",
                      });
                    }}
                  >
                    <ClearIcon className="w-full h-full" />
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        <SurveyBottomBar
          onClickNext={onClickNext}
          isDispatchable={isDispatchable()}
          prevPath={`/survey/${surveyId}/step-3-1-1`}
        />
      </div>
    </div>
  );
};

export default Survey_3_1_2;
