import { LocalSurveyType, TripType } from "../components/Trip/types";

import { getSurveyTrip } from "../api/Survey";
import { getToday } from ".";

const today = getToday();

const getStringByte = (
  data: { survey: LocalSurveyType } | TripType | LocalSurveyType | null
) => {
  if (!data) return 0;

  return JSON.stringify(data).replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2")
    .length;
};

const saveLocalSurveys = async (surveys: string[]) => {
  const localSurveys: { survey: LocalSurveyType } | null = JSON.parse(
    localStorage.getItem("survey_trip")!
  );
  const remainingLocalSpace =
    1024 * 1024 * 5 -
    unescape(encodeURIComponent(JSON.stringify(localStorage))).length -
    getStringByte(localSurveys);

  let surveyObjectForm: LocalSurveyType = {};
  for await (const surveyId of surveys) {
    try {
      const { data: surveyTrip } = await getSurveyTrip(surveyId);

      if (
        getStringByte(surveyTrip) + getStringByte(surveyObjectForm) >
        remainingLocalSpace
      )
        break;

      surveyObjectForm[surveyId] = {
        ...surveyTrip,
        saveDate: today.dayString,
      };
    } catch (e) {}
  }

  let newSurvey = { survey: surveyObjectForm };
  if (localSurveys?.survey) {
    newSurvey = { survey: { ...newSurvey.survey, ...localSurveys.survey } };
  }
  if (Object.keys(newSurvey.survey).length > 0) {
    localStorage.setItem("survey_trip", JSON.stringify(newSurvey));
  }
};

export default saveLocalSurveys;
