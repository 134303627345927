import { useContext, useEffect } from "react";

import About500 from "../../assets/Main/about500.png";
import AboutKTO from "../../assets/Main/aboutKTO.png";
import AboutSTO from "../../assets/Main/aboutSTO.png";
import { ReactComponent as Facebook } from "../../assets/Main/facebook.svg";
import Faq from "../../assets/Main/faq.png";
import FooterStoricity from "../../assets/Main/footerStoricity.png";
import FooterVenture from "../../assets/Main/footerVenture.png";
import HowToUse from "../../assets/Main/howToUse.png";
import HowToUseHigh from "../../assets/Main/howToUse@3x.png";
import HowToUseMedium from "../../assets/Main/howToUse@2x.png";
import { ReactComponent as Instagram } from "../../assets/Main/instagram.svg";
import { Link } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import { ReactComponent as MobileLogo } from "../../assets/mobileLogo.svg";
import { ReactComponent as NaverBlog } from "../../assets/Main/naverBlog.svg";
import Picture from "../General/Picture";
import Question from "../../assets/Main/question.png";
import { ReactComponent as Review1 } from "../../assets/Main/review1.svg";
import { ReactComponent as Review2 } from "../../assets/Main/review2.svg";
import { ReactComponent as Review3 } from "../../assets/Main/review3.svg";
import Slider from "react-slick";
import SurveyBg from "../../assets/Main/surveyBg.jpg";
import SurveyBgHigh from "../../assets/Main/surveyBg@3x.jpg";
import SurveyBgMedium from "../../assets/Main/surveyBg@2x.jpg";
import amplitude from "amplitude-js";
import { callMainPageTracking } from "../../util/tracking";
import { reviewList } from "./reviewList";
import useAddSurvey from "../../hooks/useAddSurvey";

const Main = () => {
  const onClickAddSurvey = useAddSurvey();
  const [{ user }] = useContext(MainContext);
  useEffect(() => {
    callMainPageTracking();
    amplitude.getInstance().logEvent("mainpage opened");
  }, []);

  return (
    <div className="w-full bg-white sm:shadow-lg">
      <div className="w-full px-4 sm:px-6 pt-6 sm:pt-9">
        <MobileLogo className="w-14 sm:w-20" />
        <div className="mt-6 sm:mt-9 text-yoda-black-1 heading-bold-24 sm:text-4xl sm:font-bold sm:tracking-tighter sm:leading-normal">
          <div>{user ? `${user.name}님` : "안녕하세요"},</div>
          <div>여다와 여행을 떠나볼까요?</div>
        </div>
        <button
          className="w-full mt-5 sm:mt-8 relative overflow-hidden rounded sm:rounded-md"
          onClick={onClickAddSurvey}
        >
          <Picture
            className="w-full"
            Low={SurveyBg}
            Medium={SurveyBgMedium}
            High={SurveyBgHigh}
          />
          <div className="absolute top-0 left-0 z-10 w-full h-full flex flex-col items-center">
            <div className="heading-bold-30 text-white text-shadow-main z-10 mt-12 sm:mt-16">
              나만의 안심여행일정
              <br />
              3분만에 받기
            </div>
            <div className="flex-1"></div>
            <div className="px-6 sm:px-9 mb-6 sm:mb-9 z-10 h-12 sm:h-16 w-full ">
              <div
                className="shadow-main-btn heading-bold-16 h-full w-full bg-white text-yoda-green-dark rounded sm:rounded-md flex justify-center items-center"
                style={{ opacity: "0.92" }}
              >
                시작하기
              </div>
            </div>
          </div>
        </button>
        <Link to="/guide">
          <div className="h-24 sm:h-40 w-full rounded sm:rounded-md bg-yoda-yellow p-4 sm:p-6 pr-5 sm:pr-7 mt-4 sm:mt-6 relative">
            <div className="heading-bold-18 text-yoda-main">
              <div>이용 방법</div>
            </div>
            <Picture
              className="absolute bottom-0 right-4 sm:right-6 h-full"
              Low={HowToUse}
              Medium={HowToUseMedium}
              High={HowToUseHigh}
            />
          </div>
        </Link>
        <div className="mt-7 sm:mt-10 body-bold-18 text-yoda-main">
          이용 고객들 후기
        </div>
      </div>
      <div className="mt-4">
        <Slider
          infinite={true}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={4500}
          className="w-full"
        >
          {reviewList.map((review, idx) => (
            <div>
              <Link to={review.tripLink}>
                <div className="w-full px-4 sm:px-6">
                  <div className="w-full h-32 sm:h-48 px-4 sm:px-6 sm:py-6 pb-5 pt-3 flex flex-col bg-yoda-gray-1 text-yoda-black-1 rounded sm:rounded-md">
                    <div className="flex items-end">
                      {idx === 0 && (
                        <Review1 className="w-6 h-6 sm:w-9 sm:h-9" />
                      )}
                      {idx === 1 && (
                        <Review2 className="w-6 h-6 sm:w-9 sm:h-9" />
                      )}
                      {idx === 2 && (
                        <Review3 className="w-6 h-6 sm:w-9 sm:h-9" />
                      )}
                      <div className="w-full body-bold-12 ml-2 sm:ml-3 mb-1 sm:mb-0.5">
                        {review.name} {review.detail}
                      </div>
                    </div>
                    <div className="mt-2 body-regular-12">{review.comment}</div>
                    <div className="w-full flex-1"></div>
                    <div className="body-bold-12 text-yoda-green-dark w-full text-right">
                      {review.name}의 여행일정 보기
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
      <div className="w-full px-4 sm:px-6">
        <Link to="/specialty">
          <div className="h-22 sm:h-32 w-full rounded sm:rounded-md bg-yoda-light-yellow-2 p-4 sm:p-6 pr-5 sm:pr-7 mt-7 sm:mt-10">
            <div className="w-full h-full flex items-start">
              <div className="heading-bold-18 sm:heading-bold-27 text-yoda-main">
                <div>여다의 일정은</div>
                <div>무엇이 다른가요?</div>
              </div>
              <div className="flex-1"></div>
              <img
                className="h-14 sm:h-20"
                src={Question}
                alt="question-image"
              />
            </div>
          </div>
        </Link>
        <Link to="/faq">
          <div className="h-22 sm:h-32 w-full rounded sm:rounded-md bg-yoda-gray-1 p-4 sm:p-6 pr-5 sm:pr-7 mt-4 sm:mt-6">
            <div className="w-full h-full flex items-start">
              <div className="heading-bold-18 sm:heading-bold-27 text-yoda-main">
                <div>자주 묻는 질문</div>
              </div>
              <div className="flex-1"></div>
              <img className="h-14 sm:h-20" src={Faq} alt="faq-image" />
            </div>
          </div>
        </Link>
      </div>
      <div className="w-full mt-11 sm:mt-16 px-4 sm:px-6">
        <div className="body-bold-18 text-center w-full">About 여다</div>
        <div className="grid grid-cols-3 gap-x-3 mt-8 sm:mt-14">
          <div className="w-full text-center">
            <img className="h-12 mx-auto" src={AboutSTO} alt="company-logo" />
            <div className="body-medium-10 mt-3 sm:mt-4">
              <div>2020 서울-관광스타트업</div>
              <div>협력프로젝트 대상 수상</div>
            </div>
          </div>
          <div className="w-full text-center">
            <img className="h-12 mx-auto" src={AboutKTO} alt="company-logo" />
            <div className="body-medium-10 mt-3 sm:mt-4">
              <div>한국관광공사 선정</div>
              <div>초기관광벤처기업</div>
            </div>
          </div>
          <div className="w-full text-center">
            <img className="h-12 mx-auto" src={About500} alt="company-logo" />
            <div className="body-medium-10 mt-3 sm:mt-4">
              <div>미국 실리콘밸리 VC</div>
              <div>500 Startups 투자유치</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-yoda-gray-0 w-full mt-11 sm:mt-16 pt-6 sm:pt-9 sm:pb-8 pl-4 sm:pl-6 pb-6 pr-3">
        <div>
          <a
            className="body-bold-12-wide sm:body-bold-18"
            href="https://www.notion.so/a3c03a8acac74f7ea123954c8d1b55d5"
            target="_blank"
          >
            개인정보처리방침
          </a>
          <span className="font-thin mx-2">|</span>
          <a
            className="body-bold-12-wide"
            href="https://www.notion.so/a626542ecc9d4587aa831fc660549e3d"
            target="_blank"
          >
            이용약관
          </a>
          <span className="font-thin mx-2">|</span>
          <a className="body-bold-12-wide" href="mailto:support@stori.city">
            support@stori.city
          </a>
        </div>
        <div className="flex justify-start items-center mt-6 sm:mt-9">
          <img
            className="w-28 sm:w-42 mr-4"
            src={FooterStoricity}
            alt="footer-logo"
          />
          <img
            className="w-28 sm:w-42 mr-4"
            src={FooterVenture}
            alt="footer-logo"
          />
        </div>
        <div className="mt-4 body-medium-12 text-yoda-gray-5">
          <div>
            (주)스토리시티 | 대표 : 박상욱 | 개인정보책임관리자 : 최승훈
          </div>
          <div>사업자 등록번호 : 547-87-01606</div>
          <div>통신판매업 신고번호 : 2020-서울중구-1676</div>
          <div>서울시 중구 청계천로 85 삼일빌딩 10F | 070-7122-4017</div>
        </div>
        <div className="my-6 sm:mt-9 body-medium-12 text-yoda-gray-5">
          <div className="mb-1.5 lg:mb-3">SNS에서 여다를 만나보세요!</div>
          <div className="flex justify-start">
            <a href="https://www.facebook.com/yodatrip" target="_blank">
              <Facebook className="w-6 h-6 mr-3" />
            </a>
            <a href="https://www.instagram.com/yoda.trip/" target="_blank">
              <Instagram className="w-6 h-6 mr-3" />
            </a>
            <a href="https://blog.naver.com/yodatrip" target="_blank">
              <NaverBlog className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
